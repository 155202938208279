import { parse, format, setMonth } from 'date-fns/index.js';
import { nb } from 'date-fns/locale/index.js';
import { holidays } from './holidays.js';

export const dayNames = (t) => [t("common.days_short.1"), t("common.days_short.2"), t("common.days_short.3"), t("common.days_short.4"), t("common.days_short.5"), t("common.days_short.6"), t("common.days_short.0")];
export const getDateToday = () => format(new Date(), 'yyyy-MM-dd', { locale: nb });
export const formatCustomDate = (date, dateFormat) => format(date, dateFormat, { locale: nb }).normalize();
export const prettifyDate = (date, t) => `${date.getDate()}. ${t("common.months." + date.getMonth())} ${date.getFullYear()}`
export const formatDate = (date) => format(date, 'yyyy-MM-dd', { locale: nb }).normalize();
export const getDayName = (date) => date.toLocaleString('default', { weekday: 'long' });
export const getMonthName = (monthNumber, t) => {
  const date = setMonth(new Date(), monthNumber);
  return t("common.months." + date.getMonth());
}

export const getMonthNameShort = (monthNumber) => {
  const date = setMonth(new Date(), monthNumber);
  return date.toLocaleString('default', { month: 'short' });
}

export const isDateToday = (date) => {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}

//TODO: This is actually  correct ISO week number, just check with google calendar!!
export const getWeekNumber = (date) => {
  const newDate = new Date(date.getTime());
  // Set to nearest Thursday: current date + 4 - current day number
  newDate.setDate(newDate.getDate() + 4 - (newDate.getDay() || 7));
  // Get first day of year
  const yearStart = new Date(newDate.getFullYear(), 0, 1);
  // Calculate full weeks to the nearest Thursday
  const weekNumber = Math.ceil(((newDate - yearStart) / 86400000 + 1) / 7);
  return weekNumber;
}

export const getWeekDates = (currentDate) => {
  const currentDay = currentDate.getDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday

  const firstDayOfWeek = new Date(currentDate); // Create a new date object for the current date
  const weekStartDay = 1; // Monday (0: Sunday, 1: Monday, ..., 6: Saturday)

  const diff = currentDay >= weekStartDay ? currentDay - weekStartDay : 7 - (weekStartDay - currentDay);

  firstDayOfWeek.setDate(currentDate.getDate() - diff); // Subtract the difference from the current date
  const weekDates = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(firstDayOfWeek); // Create a new date object for each day of the week
    date.setDate(firstDayOfWeek.getDate() + i); // Add the index to the first day to get the respective day of the week
    weekDates.push(date);
  }

  return weekDates;
}

export const getMonthDates = (currentDate) => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const numDays = new Date(year, month + 1, 0).getDate(); // Number of days in the month

  const dates = [];

  for (let day = 1; day <= numDays; day++) {
    const date = new Date(year, month, day);
    dates.push(date);
  }

  return dates;
}

export const getAllDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const allDates = [];
  while (start <= end) {
    allDates.push(new Date(start));
    start.setDate(start.getDate() + 1);
  }
  return allDates;
}


const getWorkingDays = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  let workingDates = [];
  let currentDate = start;

  while (currentDate <= end) {
    // Exclude weekends (Saturday: 6, Sunday: 0)
    if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) {

      const isHoliday = holidays.some(holiday =>
        holiday.normalize() === format(currentDate, 'yyyy-MM-dd', { locale: nb }).normalize()
      );

      if (!isHoliday) {
        workingDates.push(format(currentDate, 'yyyy-MM-dd', { locale: nb }));
      }
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return workingDates;
}

export const getHolidaysDuringWeek = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  let holis = [];
  let currentDate = start;

  while (currentDate <= end) {
    // Exclude weekends (Saturday: 6, Sunday: 0)
    if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) {

      const isHoliday = holidays.some(holiday =>
        holiday.normalize() === format(currentDate, 'yyyy-MM-dd', { locale: nb }).normalize()
      );

      if (isHoliday) {
        holis.push(format(currentDate, 'yyyy-MM-dd', { locale: nb }));
      }
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return holis;
}


export const getLastDateOfMonth = (currentDate) => {
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Set the date to the next month's first day
  const nextMonthFirstDay = new Date(currentYear, currentMonth + 1, 1);

  // Subtract one day from the next month's first day to get the last day of the current month
  const lastDateOfMonth = new Date(nextMonthFirstDay.getTime() - 1);

  return format(lastDateOfMonth, 'yyyy-MM-dd', { locale: nb });
}

export const getWorkingDaysBetweenDates = (startDate, endDate) => {
  return getWorkingDays(parse(startDate, 'yyyy-MM-dd', new Date()), parse(endDate, 'yyyy-MM-dd', new Date()));
}

export const getWorkingDaysBetweenDatesFormat = (startDate, endDate) => {
  return getWorkingDays(parse(format(startDate, 'yyyy-MM-dd', { locale: nb }).normalize(), 'yyyy-MM-dd', new Date()), parse(format(endDate, 'yyyy-MM-dd', { locale: nb }).normalize(), 'yyyy-MM-dd', new Date()));
}

export const getFirstAndLastWeekdays = (date) => {
  const year = date.getFullYear();
  const month = date.getMonth();

  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  const firstWeekday = firstDayOfMonth.getDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday
  const lastWeekday = lastDayOfMonth.getDay(); // 0: Sunday, 1: Monday, ..., 6: Saturday

  return {
    firstWeekday,
    lastWeekday,
    firstDate: firstDayOfMonth,
    lastDate: lastDayOfMonth
  };
}
export const getFirstAndLastDatesYear = (date) => {
  const year = date.getFullYear();

  const firstDateOfYear = new Date(year, 0, 1);
  const lastDateOfYear = new Date(year, 11, 31); // December 31st of the current year

  return {
    firstDateOfYear,
    lastDateOfYear
  };
}



function getFirstAndLastDates(date) {
  const year = date.getFullYear();
  const month = date.getMonth();

  const firstDayOfMonth = new Date(year, month, 1);
  const lastDayOfMonth = new Date(year, month + 1, 0);

  return {
    firstDate: firstDayOfMonth,
    lastDate: lastDayOfMonth
  };
}

export const getDateForWeekStart = (year, weekNumber) => {
  return new Date(year, 0, 1 + (weekNumber - 1) * 7);
}

export const availableHoursYear = (date) => {
  const { firstDateOfYear, lastDateOfYear } = getFirstAndLastDatesYear(date);

  const workingDays = getWorkingDaysBetweenDates(format(firstDateOfYear, 'yyyy-MM-dd', { locale: nb }).normalize(), format(lastDateOfYear, 'yyyy-MM-dd', { locale: nb }).normalize());
  return workingDays.length * 7.5;
}

export const availableHoursMonth = (date) => {
  const { firstDate, lastDate } = getFirstAndLastDates(date);

  const workingDays = getWorkingDaysBetweenDates(format(firstDate, 'yyyy-MM-dd', { locale: nb }).normalize(), format(lastDate, 'yyyy-MM-dd', { locale: nb }).normalize());
  return workingDays.length * 7.5;
}

export const getDaysInMonth = (currentDate) => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const numDays = new Date(year, month + 1, 0).getDate(); // Number of days in the month

  const dates = [];

  for (let day = 1; day <= numDays; day++) {
    const date = new Date(year, month, day);
    dates.push(date);
  }

  return dates;
}

export function areDatesEqual(date1, date2) {
  if (!date1 || !date2) return false;
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export const getDurationMinutesInHoursMinutes = (durationMin) => {
  let hours = Math.floor(durationMin / 60);
  let minutes = durationMin % 60;
  return hours + ":" + (minutes < 10 ? '00' : minutes);
}

export const formatDateString = (dateString) => {
  return formatDate(new Date(dateString));
}

export const convertFromMinutesToHourDecimalString = (durationMinutes) => {
  let hours = durationMinutes / 60;
  let roundedHours = Math.round(hours * 2) / 2;
  return roundedHours;
}
