import React from 'react';
import { ResponsiveContainer, RadialBarChart, RadialBar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

export const RadialPlot = ({ data, palette, switches = {} }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <RadialBarChart
        innerRadius="10%"
        outerRadius="100%"
        data={data}
        startAngle={0}
        endAngle={360}
        margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        barGap={10}
      >
        <RadialBar minAngle={15} clockWise={true} dataKey='value' />
        <Tooltip />
      </RadialBarChart>
    </ResponsiveContainer>
  )
}