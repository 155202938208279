export const POTs = [
  "Anne Sofie Gjerland",
  "Christian Nernes",
  "Erik Andreas Larsen",
  "Erik Zweidorff",
  "Joakim Obstfelder",
  "Jocef Iversen",
  "Jonas Valen",
  "Kjetil Solberg",
  "Liam Kokab",
  "Mathias Iversen",
  "Mette Waagsbø",
  "Snorre Lærum",
]

export const Admins = [
  "Børre Zahl Jensen",
  "Nils Magne Lunde",
  "Sveinung Einan",
  "Turi Birkeland",

]

export const Consultants = [
  "Annette Lervik",
  "Bård Soleim",
  "Bonn Aarsheim",
  "Camilla Strandenes",
  "Daniel Øverli Storeng",
  "Eivind Kolbeinshavn",
  "Endre Nordang",
  "Frode Aarstad",
  "Håvard Røssland",
  "Henriette Stokholm",
  "Isabel Maldonado",
  "Ivan Kolesar",
  "Jasper Demulder",
  " Kathinka Neteland",
  "Keith Mellingen",
  "Knut Valen",
  "Kristoffer Høisæther",

  "Lars Frøyland",
  "Lisa Hope",
  "Lisbeth Næss",
  "Mikail Orlov Andreassen",
  "Morten Tangen Zahlquis",
  "Morten Tronrud",
  "Mustafa Al-Obaidi",
  "Natalia Machuca",
  "Oliver Schoning",
  "Ovin Teige",
  "Roy Nesbø",
  "Søren Nielsen",
  "Solvor Syse Bleie",
  "Sondre Vestad",
  "Thor Teigenes",
  "Torbjørn Aase",
  "Tore Saue",
  "Torstein Thune",
  "Yacine Lakel",
]
