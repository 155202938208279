import { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { formatDate } from '@views/time/aux/tools'
import { useJikanokeAPI } from '@services';

export const useTimeentries = (Data, onDelete, onUpdate, updateTimeEntry) => {

  const { timeentries } = useJikanokeAPI();
  const createTimeEntry = timeentries.useCreate();
  const deleteTimeEntry = timeentries.useDelete();

  const [isReady, setIsReady] = useState(false);
  const [dateItems, updateDateItems] = useState(new Map());
  const [searchQuery, setSearchQuery] = useState('');

  const addItemForDate = async (date, item) => {
    const dateKey = date instanceof Date ? formatDate(date) : date;
    // Create a new Map with updated values
    //TODO: Can remove the projectName and activityName when we get this from server?
    let newItem = {
      ...item,
      id: uuidv4(), // Internal FE housekeeping only
      isNew: false, // Internal FE housekeeping only
      date: dateKey,
      projectName: Data.projectSelection.find(project => project.value === item.projectId).name,
      activityName: Data.projectSelection.find(project => project.value === item.projectId).activities.find(activity => activity.value === item.activityId).name,

    };
    try {
      const persisted = await createTimeEntry.mutateAsync(newItem);
      newItem.id = persisted[persisted.length - 1].ok?.data || 'something went wrong';
      console.log("Persisted", persisted);
      // Update the state with the new Map
      updateDateItems(dateItems => {
        const newDateItems = new Map(dateItems);

        if (!newDateItems.has(dateKey)) {
          newDateItems.set(dateKey, []);
        }

        // Create a new array for the date entry
        const updatedArray = [...newDateItems.get(dateKey), newItem];
        newDateItems.set(dateKey, updatedArray);

        return newDateItems;
      });
      onUpdate(newItem.id);
    } catch (error) {
      console.error("Error creating timeentry", error);
    } finally {

    }
  }

  const updateItemForDate = async (date, updatedObject) => {

    const dateKey = date instanceof Date ? formatDate(date) : date;
    // Create a new Map with updated values
    const newDateItems = new Map(dateItems);

    if (newDateItems.has(dateKey)) {
      // Update the array of objects for the specified date
      const updatedArray = newDateItems.get(dateKey).map((object) => {
        // Update the specific object, or keep it the same if not found
        if (object.id === updatedObject.id) {
          //TODO: Can remove the projectName and activityName when we get this from server?
          return { ...updatedObject, projectName: Data.projectSelection.find(project => project.value === updatedObject.projectId).name, activityName: Data.projectSelection.find(project => project.value === updatedObject.projectId).activities.find(activity => activity.value === updatedObject.activityId).name };
        }
        return object;
      });
      newDateItems.set(dateKey, updatedArray);
    }
    try {
      await updateTimeEntry.mutateAsync(updatedObject);
      // Update the state with the new Map
      updateDateItems(newDateItems);
      onUpdate(updatedObject.id);
      setTimeout(() => {
        onUpdate(null);
      }, 200);
    } catch (error) {
      console.error("Error updating timeentry", error);
    }
  }

  const removeEntryForDate = async (date, entryToRemove) => {

    const dateKey = date instanceof Date ? formatDate(date) : date;

    // Create a new Map with updated values
    const newDateItems = new Map(dateItems);

    if (newDateItems.has(dateKey)) {
      // Get the array of objects for the specified date
      const currentArray = newDateItems.get(dateKey);

      // Remove the specific entry by filtering the array
      const updatedArray = currentArray.filter((entry) => entry !== entryToRemove);

      // Update the Map with the filtered array
      newDateItems.set(dateKey, updatedArray);

      // If the filtered array is empty, remove the date entry from the Map
      if (updatedArray.length === 0) {
        newDateItems.delete(dateKey);
      }
    }

    try {
      console.log("Deleting", entryToRemove);
      await deleteTimeEntry.mutateAsync(entryToRemove);
      // Update the state with the new Map
      updateDateItems(newDateItems);
      //TODO: this is just as the layout is not, change this to a proper solution
      onDelete(date);
    } catch (error) {
      console.error("Error deleting timeentry", error);
    }
  }

  const getItemsForDate = date => {
    // Create a new Map with updated values
    const newDateItems = new Map(dateItems);

    if (newDateItems.has(date)) {
      // Get the array of objects for the specified date
      const currentArray = newDateItems.get(date);
      console.log(currentArray);
      return currentArray;
    }
    return [];
  }

  const getDateItemsBetween = (startDate, endDate, flatten) => {
    const startDateKey = startDate instanceof Date ? formatDate(startDate) : startDate;
    const endDateKey = startDate instanceof Date ? formatDate(endDate) : endDate;
    // Initialize an empty Map to store the filtered entries
    const filteredMap = new Map();
    const flattenList = [];
    // Iterate through the original Map and filter entries based on the date range
    for (const [dateKey, value] of dateItems.entries()) {
      if (dateKey >= startDateKey && dateKey <= endDateKey) {
        if (flatten) {
          flattenList.push(...value)
        } else {
          filteredMap.set(dateKey, value);
        }
      }
    }
    return flatten ? flattenList : filteredMap;
  }

  useEffect(() => {
    if (Data.timeentriesData && !isReady && Data.projectSelection && Data.projectSelection.length > 0) {
      Data.timeentriesData?.forEach(element => {
        const mapped = {
          ...element,
          date: formatDate(new Date(element.date)),
          isNew: false,
          projectName: Data.projectSelection.find(project => project.value === element.projectId).name,
          activityName: Data.projectSelection.find(project => project.value === element.projectId).activities.find(activity => activity.value === element.activityId)?.name,
        };
        updateDateItems(dateItems => {
          const newDateItems = new Map(dateItems);
          const dateKey = formatDate(new Date(element.date));

          if (!newDateItems.has(dateKey)) {
            newDateItems.set(dateKey, []);
          }
          const updatedArray = [...newDateItems.get(dateKey), mapped];
          newDateItems.set(dateKey, updatedArray);

          return newDateItems;
        });
      });
      setIsReady(true);
    }
  }, [Data.timeentriesData, isReady, Data.projectSelection]);

  //TODO: This renders as a motherfucker in ManagementProvider
  useEffect(() => {
    console.log("Data is loading", Data.isLoading, "isReady is", isReady);
    if (isReady && Data.isLoading) {
      setIsReady(false);
    }
  }, [Data.isLoading, isReady]);

  return {
    isReady,
    dateItems,
    addItemForDate,
    updateItemForDate,
    removeEntryForDate,
    getItemsForDate,
    getDateItemsBetween,
    searchQuery, setSearchQuery
  }
}