import React, { useState } from 'react';

export const TextToggle = ({ id, initialText, toggleText, onToggle }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
    if (onToggle) {
      onToggle(id);
    }
  };

  return (
    <div className="is-link" onClick={toggleVisibility}>  {isVisible ? toggleText : initialText}</div>
  );
};