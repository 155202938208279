import React from 'react';
import { H1, Table, Columns, Column, Search, Stack } from '@components';
import { useTranslation } from "react-i18next";
import { useManage } from '../ManageProvider';
import { tableHeaders } from './template';

export const TimeEntries = () => {

    const { TimeEntryManage, flags, isLoading, isError, searchQuery, setSearchQuery } = useManage();

    const { t } = useTranslation();

    const show = !((isLoading || isError || !TimeEntryManage.Data.timeentriesData)) && flags.view;

    const onItemClick = (event, data) => {
        console.log(data);
        setTimeout(() => TimeEntryManage.TimeentriesForm.editItem(data), 100);
    }

    const onDateFromChange = (e) => {
        TimeEntryManage.Selection.setFrom(new Date(e.target.value));
    };
    const onDateToChange = (e) => {
        TimeEntryManage.Selection.setTo(new Date(e.target.value));
    };

    return (
        <Stack.Begin>
            <Stack.Bar>
                <Columns>
                    <Column className="is-narrow">  <H1>{t('manage.time.title')}</H1></Column>
                    <Column className="auto">
                    </Column>
                    <Column className="centercenter is-narrow">
                        <div className='mr-4'>{t('manage.time.from_date')}</div>
                        <div className="control">
                            <input className="input" type="date" placeholder="Fra dato" value={TimeEntryManage.Selection.from.toISOString().split('T')[0]}
                                onChange={onDateFromChange} />
                        </div>
                    </Column>
                    <Column className="centercenter is-narrow">
                        <div className='mr-4'>{t('manage.time.to_date')}</div>

                        <div className="control">
                            <input className="input" type="date" placeholder="Til dato" value={TimeEntryManage.Selection.to.toISOString().split('T')[0]} onChange={onDateToChange} />
                        </div>
                    </Column>

                    <Column>
                        <Search searchPlaceholder={t(`common.search`)} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                    </Column>
                </Columns>
            </Stack.Bar>
            <Stack.Content>
                <>
                    {isLoading && <div>{t("common.loading")}</div>}
                    {show &&
                        <Table list={TimeEntryManage.Data.timeentriesData} keys={tableHeaders(t)} searchPlaceholder={t("manage.time.search_placeholder")} onItemClick={onItemClick} selectedId={TimeEntryManage.TimeentriesForm.currentItem?.id} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
                </>
            </Stack.Content>
            {/* TODO; Need to move pagnation from table and buttons away from the form and into the layout. This is a workaround */}
            <Stack.Bar />
        </Stack.Begin>

    );
};
