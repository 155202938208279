import React, { useState, useEffect } from 'react';
import { useStore } from '@providers';
import { useClickOutside } from '@hooks';
import './ElementPicker.scss';
import { IconPicker } from './iconpicker';

export const ElementPicker = ({ event, close, icon, onIconChange }) => {

  const [xy] = useState(event);
  const handleOutsdeBoxClick = () => {
    close();
  };
  let { left, width, height, top } = xy.target.getBoundingClientRect();

  console.log(event.target.dataset.type);
  if (event.target.dataset.type === 'ignore') {
    left = event.clientX;
    top = event.clientY;
    width = 0;
    height = 0;
  }
  const { ref } = useClickOutside(handleOutsdeBoxClick);
  const { palette } = useStore().colors;
  const colorKeys = Object.keys(palette).filter((key) => key.includes('cont')).map((key) => palette[key]);
  const [animationComplete, setAnimationComplete] = useState(true);

  const calculateIconPosition = (angle, radius) => {
    const centerX = 0; // Adjust as needed
    const centerY = 0; // Adjust as needed
    const iconSize = 2;

    const radianAngle = (angle * Math.PI) / 180;

    const iconX = centerX + radius * Math.cos(radianAngle) - iconSize / 2;
    const iconY = centerY + radius * Math.sin(radianAngle) - iconSize / 2;

    return { left: `calc(50% + ${iconX}rem)`, top: `calc(50% + ${iconY}rem)` };
  };

  const isSelection = event.target.dataset.type;

  const elements = [
    {
      icon: 'fas fa-font',
      type: 'text',
    },
    {
      icon: 'fa-solid fa-icons',
      type: 'icon',
    },
    {
      icon: 'fa-solid fa-image',
      type: 'image',
    },
    {
      icon: 'fa-solid fa-chart-simple',
      type: 'plot',
    },
    {
      icon: 'fa-solid fa-film',
      type: 'video',
    },
    {
      icon: 'fa-solid fa-bolt',
      type: 'animation',
    },
    {
      icon: 'fa-solid fa-expand',
      type: 'resize',
    },
  ]

  const icons = [300, 325, 350, 375, 150, 175, 200].map((angle, index) => {
    const position = calculateIconPosition(angle, 7.5);
    return (
      <div
        key={angle}
        className={`iconer ${isSelection ? 'dim' : ''} ${elements[index].type === event.target.dataset.type ? 'active' : ''}`}
        datatype={elements[index].type}
        style={{ ...position, boxShadow: `inset 0 0 1.5rem 0.1rem ${colorKeys[index]}, 0 0 0rem 0.1rem ${colorKeys[index]}` }}
      >
        <i className={elements[index].icon} />
      </div>
    );
  });
  useEffect(() => {
    const onAnimationEnd = () => {
      setAnimationComplete(true);
    };

    const circle = document.getElementById('animatedCircle');
    circle.addEventListener('animationend', onAnimationEnd);

    return () => {

      circle.removeEventListener('animationend', onAnimationEnd);
    };
  }, []);

  return (
    <>
      <div ref={ref} className={`circle-container ${animationComplete ? 'animation-complete' : 'animation-start'}`} style={{ left: left, top: top, width, height }}>
        <div id="animatedCircle" className="animated-circle" style={{ width, height }}></div>
        {animationComplete && <div className="icons-container">{icons}</div>}
      </div>

      {event.target.dataset.type === 'icon' && <div className="edit-container has-secondary-bg" style={{ position: 'fixed', overflowY: 'scroll', top: '3.35rem', bottom: '0rem', right: '1rem', width: '500px' }}><IconPicker current={icon?.type} onChange={onIconChange} /></div>}
    </>
  );
};
