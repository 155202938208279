import React, { createContext, useContext, useState, useTransition } from 'react';
import { useStore } from '@providers';
import { useParams, useNavigate } from 'react-router-dom';
import { demoData } from './data';

export const SlidesProviderContext = createContext();

export const SlidesProvider = ({ children }) => {
  const { user } = useStore().states;
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();
  const [modes, setModes] = useState({ edit: false, menu: true, menuHeadings: false, pageTitle: false, animation: true, confetti: false });
  let { presentation: presentationId, page: pageId, slide: slideId } = useParams();

  presentationId = parseInt(presentationId);
  pageId = parseInt(pageId);
  slideId = parseInt(slideId);

  const presentation = demoData(user.given_name || 'kamerat!');
  if (pageId >= presentation.pages.length) pageId = 0;
  const page = presentation.pages[pageId || 0];
  if (slideId >= page.slides.length) slideId = 0;
  const slide = page.slides[slideId || 0];
  const totalSlides = presentation.pages.reduce((total, page) => total + page.slides.length, 0);
  const currentSlide = presentation.pages.slice(0, pageId).reduce((total, page) => total + page.slides.length, 1) + slideId;

  const updateMode = (newMode) => {
    setModes(oldModes => ({ ...oldModes, ...newMode }));
  }

  const addPage = () => {
    presentation.pages.push({
      title: `Page ${presentation.pages.length + 1}`,
      label: `Page ${presentation.pages.length + 1}`,
      icon: "pizza-slice",
      slides: [
        {
          title: 'New Slide',
          content: 'New Slide Content',
        }
      ]
    });
    goto(presentation.pages.length - 1, 0);
  }

  const addSlide = () => {
    presentation.pages[pageId].slides.push({
      title: 'New Slide',
      content: 'New Slide Content',
    });
    goto(pageId, presentation.pages[pageId].slides.length - 1);
  }

  const goto = (pageId, slideId) => {
    startTransition(() => {
      navigate(`/presents/${presentationId}/${pageId}/${slideId}`);
    });
  }

  return (
    <SlidesProviderContext.Provider value={{ presentationId, presentation, page, slide, pageId, slideId, modes, updateMode, goto, status: { currentSlide, totalSlides }, edit: { addPage, addSlide } }}>
      {children}
    </SlidesProviderContext.Provider>
  );
};

export const useSlides = () => useContext(SlidesProviderContext);
