import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select'

import { FormFieldInput } from './FormFieldInput';
import { TabMenu } from './TabMenu';
import { validators, notEmpty } from './validators';
import { toast } from 'react-toastify';
import { useStore } from '@providers';
import { useTranslation } from "react-i18next";
import { Stack, Container } from '@components';

// Custom styles for dark mode
const darkModeStyles = {
  container: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? '#101733' : '#101733',
    color: '#fff',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#101733',
    borderColor: 'transparent',
    padding: '0.1rem',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#101733' : '#fff',
    color: state.isSelected ? '#fff' : '#666',
    ':hover': {
      backgroundColor: '#101733',
      color: '#fff',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#664bfb',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#fff',
    ':hover': {
      backgroundColor: '#777',
      color: '#fff',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
  }),
};

const darkModeTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#101733',
  },
});

export const TabForm = ({ data, onSubmit, reverse, saveBtnText = 'Save and continue', cancelBtn, width }) => {
  const { palette, theme } = useStore().colors;
  const { t } = useTranslation();
  const validator = validators(t);
  const transformedData = useMemo(() => {
    return data
      .map((tab) =>
        tab.fields.reduce((acc, field) => ({ ...acc, [field.name]: field.type === 'checkbox' ? Boolean(field.value) : field.value ? field.value : null }), {})
      )
      .reduce((acc, obj) => ({ ...acc, ...obj }), {});
  }, [data]);

  const transformedTabData = useMemo(() => {
    return data.reduce((acc, tab) => ({ ...acc, [tab.name]: false }), {})
  }, [data]);

  const activeTabRef = useMemo(() => data[0].name, [data]);

  const [activeTab, setActiveTab] = useState(activeTabRef);
  const [doValidation, setDoValidation] = useState(false);
  const [hasTabError, sethasTabError] = useState(transformedTabData);
  const [formData, setFormData] = useState(transformedData);


  const hasError = useCallback((tab, error) => {
    sethasTabError((prevError) => ({ ...prevError, [tab]: error }));
  }, [sethasTabError]);

  const validateFormData = useCallback(() => {
    data.forEach((tab) => {
      hasError(tab.name, false);
      tab.fields.forEach((field) => {
        if (field.required && !formData[field.name]) {
          hasError(tab.name, true);
          return
        }
        if (validator[field.validator] && formData[field.name] && notEmpty(formData[field.name])) {
          if (!validator[field.validator].test(formData[field.name])) {
            hasError(tab.name, true);
            return
          }
        }
      });
    });
    setDoValidation(true);
  }, [data, formData, hasError]);

  useEffect(() => {
    validateFormData();
  }, [formData, validateFormData]);

  const onSwitchTab = (tab) => {
    setActiveTab(tab);
    validateFormData();
  }

  const preSubmit = (event) => {
    event.preventDefault();
    let hasError = false;
    data.forEach((tab) => {
      tab.fields.forEach((field) => {
        if (field.required && !formData[field.name]) {
          hasError = true;
          return
        }
        if (validator[field.validator] && formData[field.name] && formData[field.name].length > 0) {
          if (!validator[field.validator].test(formData[field.name])) {
            hasError = true;
            return
          }
        }
      });
    });
    if (hasError) {
      setDoValidation(true);
      toast.warn(t("common.fix_fields"));
      return;
    }
    if (Object.values(hasTabError).some((tab) => tab)) {
      return;
    }
    const nestedObject = Object.keys(formData).reduce((acc, key) => {
      const value = formData[key];
      const keys = key.split('.');
      const lastKey = keys.pop();

      keys.reduce((nestedObj, nestedKey) => {
        if (!nestedObj[nestedKey]) {
          nestedObj[nestedKey] = {};
        }
        return nestedObj[nestedKey];
      }, acc)[lastKey] = value;

      return acc;
    }, {});

    onSubmit(nestedObject);
  }

  const onChange = (event, fieldName, fieldType) => {
    let { name, value, checked } = event.target;
    if (!name) {
      name = fieldName;
    }
    if (value && !isNaN(value) && name !== 'phone') {
      value = parseInt(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: fieldType === 'checkbox' ? Boolean(checked) : value
    }));
  };

  const onSelectChange = (items, fieldName, fieldType) => {
    const mapped = fieldType === 'multiselect' ? items.map(item => item?.value || item) : items?.value || items;
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: mapped
    }));
  };

  return (
    <div className='is-overlay'>
      <Container className={`container is-fullheight ${width || ""}`}>
        <Stack.Begin>
          <Stack.Content>
            <div className="columns">
              {(!reverse && data.length > 1) &&
                <div className="column is-one-quarter has-border-right">
                  <TabMenu tabs={data} setActiveTab={onSwitchTab} activeTab={activeTab} hasTabError={hasTabError} />
                </div>
              }
              <div className="column is-scrollable">
                <form onSubmit={(event) => event.preventDefault()} noValidate>
                  {data.map((tab) => (
                    <div
                      key={tab.name}
                      className={`tab ${tab.name === activeTab ? 'active-tab' : 'is-hidden'}`}
                    >
                      {tab.fields.map((field) =>
                        field.options ? (
                          <div className='mb-2' key={`comp_${field.name}`} >
                            <label key={`label_${field.name}`} className="label">{field.label}</label>
                            <div key={`field_${field.name}`} className={`select is-fullwidth is-multiple`}>
                              <Select
                                closeMenuOnSelect={!field.multiple}
                                styles={theme === "dark" ? darkModeStyles : undefined}
                                theme={theme === "dark" ? darkModeTheme : undefined}
                                isSearchable options={field.options} value={field.options.filter(item => Array.isArray(formData[field.name]) ? formData[field.name].includes(item.value) : item.value === formData[field.name])} isMulti={field.multiple} onChange={(event) => onSelectChange(event, field.name, field.multiple ? 'multiselect' : 'singleselect')} />

                              {/*  <select multiple={field.multiple} value={formData[field.name]} onChange={(event) => onChange(event, field.name, field.multiple ? 'multiselect' : 'singleselect')}>
                          {field.options.map((option) => (
                            <option key={option.value} value={option.value}>{option.label}</option>
                          ))}
                        </select> */}
                            </div>
                          </div>
                        ) : (
                          <FormFieldInput key={field.name} {...field} doValidation={doValidation} value={formData[field.name]} onChange={onChange} />
                        )
                      )}
                      {tab.component && tab.component}
                    </div>
                  ))}
                </form>
              </div >
              {(reverse && data.length > 1) &&
                <div className="column is-one-quarter has-border-left">
                  <TabMenu tabs={data} setActiveTab={onSwitchTab} activeTab={activeTab} hasTabError={hasTabError} />
                </div>
              }
            </div >
          </Stack.Content>
          <Stack.Bar>
            <div className='centercenter'>
              <div className="buttons">
                <button onClick={(event) => preSubmit(event)} className="button is-success is-outlined">
                  <span className="icon is-small "><i class="fa-solid fa-check"></i></span>
                  <span>{saveBtnText}</span>
                </button>
                {cancelBtn && <button className="button mr-2 is-danger is-outlined" onClick={cancelBtn}>
                  <span>{`${t("common.cancel")}`}</span>
                  <span class="icon is-small">
                    <i class="fas fa-times"></i>
                  </span>
                </button>}
              </div>
            </div>
          </Stack.Bar>
        </Stack.Begin>
      </Container>
    </div>
  )
}
