import React from 'react';
import { Link } from 'react-router-dom';
import { Stack, Levels } from '@components';
import { useTranslation } from "react-i18next";
/* 
export const Reports = () => {
    const { t } = useTranslation();
    return (
        <div className='section is-paddingless'>
            <div className="container is-max-desktop">
                <div className="notification is-warning has-text-centered">
                    {t("analytics.myreports.message")}
                    <blockquote>
                        {t("manage.presentations.info")}<Link to="/analytics/presenter-mode">{` ${t("common.here")}`}</Link>!
                    </blockquote>
                </div>
            </div>
        </div>
    );
}
 */


export const Reports = ({ label, quote }) => {
    const { t } = useTranslation();
    const levels = [
        { heading: t("analytics.tba"), title: label },
    ]
    return (
        <Stack.Fullscreen>
            <Stack.Begin center>
                <Stack.Bar className="has-text-centered">
                    <Levels levels={levels} className="is-justify-content-center" />
                </Stack.Bar>
                <Stack.Bar>
                    <div style={{ maxWidth: '400px' }}>{t("manage.presentations.info")}</div>
                </Stack.Bar>
                <Stack.Bar className="is-size-2">
                    <Link to="/dashboard/presenter-mode">Demo</Link>
                </Stack.Bar>
            </Stack.Begin>
        </Stack.Fullscreen>
    );
}
