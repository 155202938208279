
import React, { useEffect } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import { Air, Container, Section, H1, Levels, PlotContainer, AreaPlot, RadialPlot } from '@components';

import { useStore } from '@providers';
import { useInterval } from '@hooks';

import 'animate.css';
import { ElementPicker } from './ElementPicker';
import { useSlides } from './SlidesProvider';
import { large } from './data';

export const Slide = () => {
  const { palette } = useStore().colors;
  const { presentation, page, slide, modes, updateMode } = useSlides();

  const title = presentation.showTiles ? page.title : null;

  const colorKeys = Object.keys(palette).filter((key) => key.includes('cont'));

  const [show, setShow] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [itemsRadial, setItemsRadial] = React.useState([]);
  const [color, setColor] = React.useState('yellow');
  const [isExploding, setIsExploding] = React.useState(false);

  const generateItems = () => {
    const items = Array.from({ length: 30 }).map((item, index) => ({
      name: `Item ${index + 1}`,
      value: Math.random() * 75 + 1,
    }));
    const itemsRadial = Array.from({ length: 8 }).map((item, index) => ({
      name: `Item ${index + 1}`,
      value: Math.random() * 75 + 1,
      fill: palette[colorKeys[index]],
    }));

    setItemsRadial(itemsRadial)
    setItems(items);
    setColor(colorKeys[Math.floor(Math.random() * colorKeys.length)]);
  }

  const onChange = (event, icon) => {
    event.stopPropagation();
    slide.icon.type = icon;
  }

  useInterval(generateItems, 3000, true);

  useEffect(() => {
    if (slide.confetti) {
      setTimeout(() => {
        setIsExploding(true);
      }, 1500);
    }
  }, [slide]);

  useEffect(() => {
    if (modes.confetti) {
      setIsExploding(true);
      setTimeout(() => {
        setIsExploding(false);
        updateMode({ confetti: false })
      }, 4000);
    }
  }, [modes.confetti, updateMode]);

  return (
    <div>
      <Section className={`is-paddingless zero`} style={{ margin: 0, padding: 0, overflowY: 'none' }}>
        <Container className="is-max-desktop" data-type='ignore' onClick={event => { setShow(event); }}>
          <div data-type='ignore' className="is-full-vheight-and-vwidth is-flex is-flex-direction-column is-align-content-center is-justify-content-center zero slides">
            {title && <H1 className="has-text-centered has-text-primary">{title}</H1>}
            <Air size="4" data-type='ignore' />
            {/*  <Levels levels={data} /> */}
            <div className='has-text-centered subtitle is-size-4'>
              {slide.content}
            </div>
            {/* <Air size="4" data-type='ignore' /> */}
            {slide.particles && <div className="particle-fullsize">{Array.from({ length: 100 }).map((item, index) => <div key={`particle_${index + 1}`} className={`particle-container`}><div className="particle"></div></div>)}</div>}
            {slide.radial && <PlotContainer data={itemsRadial} height={28} title="" toolbox={false} element={RadialPlot} color={color} />}
            {slide.levels && <Levels levels={slide.levels} />}
            {slide.img && <div className={`has-text-centered ${modes.animation ? 'animate__animated animate__slow animate__delay-2s animate__heartBeat animate__infinite' : ''}`}><img width={'250px'} src={slide.img} alt="" /></div>}
            {slide.svg && <div className={`has-text-centered is-flex is-justify-content-center ${modes.animation ? 'animate__animated animate__delay-2s animate__tada' : ''} svg-primary`}>{slide.svg}</div>}
            {slide.plot && <PlotContainer data={items} height={28} title="" toolbox={false} element={AreaPlot} color={color} />}
            {slide.icon && <div data-type='icon' className={`is-clickable has-text-centered ${slide.icon.animation && modes.animation ? `animate__animated animate__infinite ${slide.icon.animation.type} ${slide.icon.animation.speed || ''}` : ''} transition`} style={{ fontSize: slide.icon.size || '5rem', color: palette[color] }}><i data-type='icon' className={`${slide.icon.type}`}></i></div>}
            {isExploding && <div style={{ position: 'absolute', top: '30%', left: '50%' }}> <ConfettiExplosion {...large(palette)} /></div>}
          </div>
        </Container>
      </Section >
      {(show && modes.edit) && <ElementPicker event={show} close={() => setShow(null)} onIconChange={onChange} icon={slide.icon} />}
    </div>
  )
}