import React from 'react';

import { LogoutAnchor } from '@providers'
import { TextFader, Stack, H1, H2 } from '@components';
import money from '@assets/money-with-wings.gif';
import { useTranslation } from "react-i18next";

//TODO: Do some diagnostic about if connected to internet or not
export const ErrorScreen = () => {
    const { t } = useTranslation();
    return (
        <Stack.Begin center>
            <Stack.Bar className='has-text-centered'>
                <H1>{t("screens.this_just_in")}</H1>
                <H2>{t("screens.cant_communicate")}</H2>
            </Stack.Bar>
            <Stack.Bar>
                <img src={money} alt={t("screens.alt_money")} style={{ height: '30vh' }} />
            </Stack.Bar>
            <TextFader textList={[t("screens.soothing_message_1"), t("screens.soothing_message_2"), t("screens.soothing_message_3"), t("screens.soothing_message_4"), t("screens.soothing_message_5"), t("screens.soothing_message_6")]} />
            <Stack.Bar><LogoutAnchor></LogoutAnchor></Stack.Bar>
        </Stack.Begin>
    );
}
