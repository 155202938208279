
import React from 'react';

import { H2, Columns, Column, LinePlot, Levels, PlotContainer, BarPlot, AreaPlot, Stack } from '@components';
import { useStore } from '@providers';
import { useCalendar } from '@views/time/provider';
import { useTranslation } from "react-i18next";

export const Dashboard = () => {
  const { palette } = useStore().colors;
  const { selectedDate, Statistics } = useCalendar();
  const { t } = useTranslation();
  console.log("Statistics", Statistics);
  if (!Statistics.isReady) {

    return (
      <Stack.Fullscreen>
        <Stack.Begin center>
          {!Statistics.hasData ?
            <>
              <Stack.Bar>
                <H2>{t('common.no_data')}</H2>
              </Stack.Bar>
              <Stack.Bar>
                <div style={{ width: "300px", height: "250px" }} >
                  <dotlottie-player src="https://lottie.host/88e4c96c-25bb-4438-ab2b-85fabd4905c4/1d7uN0QVSf.json" background="transparent" speed="0.7" loop autoplay></dotlottie-player>
                </div>
              </Stack.Bar>

            </>
            :
            <Stack.Bar noborder>
              <H2>{t('common.loading_data')}</H2>
            </Stack.Bar>
          }
        </Stack.Begin>
      </Stack.Fullscreen>
    )
  }

  const dataHourKeys = [
    { name: `${t('analytics.dashboard.hours_available')}`, key: "available", fill: palette.comp1 },
    { name: `${t('analytics.dashboard.hours_registered')}`, key: "logged", fill: palette.comp4 }];

  const billingRateKeysPlots = [
    { name: `${t('analytics.dashboard.your_billing_rate')}`, key: "billableRate", color: 'yellow' },
    { name: `${t('analytics.dashboard.company_billing_rate')}`, key: "billingRateCompany", color: 'purple' }];

  const levels = [
    { heading: `${t('analytics.dashboard.hours_in')} ${t('common.months.' + selectedDate.getMonth())}`, title: Statistics.financialYear.months[selectedDate.getMonth()].available, tooltip: `${t('analytics.dashboard.hours_in')} ${t('common.months.' + selectedDate.getMonth())}` },
    { heading: `${t('analytics.dashboard.hours_registered')}`, title: Statistics.financialYear.months[selectedDate.getMonth()].logged, tooltip: `${t('analytics.dashboard.hours_registered')} ${t('common.months.' + selectedDate.getMonth())}` },
    { heading: `${t('analytics.dashboard.hours_remaining')}`, title: Statistics.financialYear.months[selectedDate.getMonth()].remaining, tooltip: `${t('analytics.dashboard.hours_remaining')}` },
    { heading: `${t('analytics.dashboard.holidays_remaining')}`, title: Statistics.financialYear.vacationRemaining, tooltip: `${t('analytics.dashboard.holidays_remaining_tooltip')} ${Statistics.rules.vacationAllowed}` },
    { heading: `${t('analytics.dashboard.sick_days_taken')}`, title: Statistics.financialYear.sickdays, tooltip: `${Statistics.financialYear.sickdays} ${t('analytics.dashboard.sick_days_taken_tooltip')}` },
    { heading: `${t('analytics.dashboard.hour_balance')}`, title: Statistics.soFar.hourBalance, tooltip: `${t(Statistics.soFar.hourBalance < 0 ? 'analytics.dashboard.hour_balance_tooltip_negative' : 'analytics.dashboard.hour_balance_tooltip_positive')}: ${t('common.logged')}; ${Statistics.soFar.logged} / ${Statistics.soFar.available}` },
  ]
  return (
    <Stack.Begin>
      <Stack.Bar>
        <Levels levels={levels} />
      </Stack.Bar>
      <Stack.Bar>
        <PlotContainer data={Statistics.financialYear.months} title={`${t('common.hours')}`} dataKeys={dataHourKeys} toolbox={false} element={BarPlot} />
      </Stack.Bar>
      <Stack.Bar>
        <Columns>
          <Column>
            <PlotContainer data={Statistics.financialYear.months} dataKey="billableRate" height={28} title={`${t('analytics.dashboard.your_billing_rate')}`} color="yellow" toolbox={false} element={AreaPlot} />
          </Column>
          <Column>
            <PlotContainer data={Statistics.financialYear.months} dataKey="billingRateCompany" height={28} title={`${t('analytics.dashboard.company_billing_rate')}`} color="green" toolbox={false} element={AreaPlot} />
          </Column>
        </Columns>
      </Stack.Bar>
      <Stack.Bar noborder>
        <Columns>
          <Column>
            <PlotContainer data={Statistics.financialYear.months} dataKeys={dataHourKeys} title={`${t('common.hours')}`} toolbox={false} element={LinePlot} />
          </Column>
          <Column>
            <PlotContainer data={Statistics.financialYear.months} dataKeys={billingRateKeysPlots} title={`${t('common.billing')}`} toolbox={false} element={LinePlot} />
          </Column>
        </Columns>
      </Stack.Bar>
    </Stack.Begin>
  )
}
