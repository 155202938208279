/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Table, Stack, H1, Search, Columns, Column } from '@components';
import { useCalendar } from './provider';
import { useTranslation } from "react-i18next";
import { tableHeaders } from '../manage/time/template';
import { CalendarHeader } from './CalendarHeader';

export const List = ({ view }) => {

    const { t } = useTranslation();
    const { TimeentriesForm, Timeentries, Selection } = useCalendar();
    const days = Timeentries.getDateItemsBetween(Selection.from, Selection.to, true);

    const onItemClick = (event, data) => {
        Selection.setSelectedDate(new Date(data.date));
        setTimeout(() => TimeentriesForm.editItem(data), 100);
    }

    useEffect(() => {
        Selection.auto.apply(new Date(), view);
    }, [view]);

    return (
        <Stack.Begin>
            <Stack.Bar>
                <Columns>
                    <Column>
                        <H1>{t('common.hour_registration')}</H1>
                    </Column>
                    <Column className="is-3">
                        <Search searchPlaceholder={t(`common.search`)} searchQuery={Timeentries.searchQuery} setSearchQuery={Timeentries.setSearchQuery} />
                    </Column>
                </Columns>
            </Stack.Bar>
            <Stack.Content>
                <Table list={days} keys={tableHeaders(t).slice(1)} filterableKeys={['project', 'activity']} perPage="100" onItemClick={onItemClick} selectedId={TimeentriesForm.currentItem?.id} searchQuery={Timeentries.searchQuery} />
            </Stack.Content>
            <Stack.Bar>
                <CalendarHeader />
            </Stack.Bar>
        </Stack.Begin>
    );

}
