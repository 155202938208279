import React from 'react';

export const Fullscreen = ({ children }) => (
  <div className='stack-fullscreen is-relative'>
    {children}
  </div>
)

export const Begin = ({ children, className, center = false }) => (
  <div className={`stack-begin is-overlay is-flex is-flex-direction-column ${center ? 'is-align-items-center is-justify-content-center' : 'is-justify-content-space-between'} ${className || ''}`}>
    {children}
  </div>
)

export const Bar = ({ children, className, compact = false, noborder = false }) => (
  <div className={`stack-bar ${children && !compact ? 'py-5' : ''} ${className || ''} ${noborder ? 'noborder' : ''}`}>
    {children}
  </div>
)

export const Content = ({ children }) => (
  <div className='stack-content is-flex-grow-1 is-relative is-scrollable'>
    {children}
  </div>
)

export const SpaceBetween = ({ children }) => (
  <div className='stack-helper is-flex is-justify-content-space-between is-align-items-center'>
    {children}
  </div>
)