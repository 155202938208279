import React from 'react';
import { Table, TabForm } from '@components';
import { useTranslation } from "react-i18next";
import { useManage, ManageHeader } from '../ManageProvider';
import { template, tableHeaders } from './template';

import { Stack } from '@components';

export const Positions = () => {
    const { flags, reset, item: user, edit, positionsData, positions, departmentOptions, roleOptions, isLoading, isError, searchQuery, setSearchQuery } = useManage();
    const { t } = useTranslation();
    const create = positions.useCreate();
    const update = positions.useUpdate();

    if (isLoading || isError || !departmentOptions) return null;

    const data = template(departmentOptions, roleOptions, user, t);

    const onAdd = (data) => {
        const createData = { ...data, data: data };
        create.mutate(createData, { onSuccess: reset });
    }

    const onEdit = (data) => {
        const updateData = { ...data, data: data, id: user.id };
        update.mutate(updateData, { onSuccess: reset });
    }

    return (
        <Stack.Begin>
            <Stack.Bar>
                <ManageHeader plural={t("manage.positions.positions")} singular={t("manage.positions.position")} new_text={t("common.new")} />
            </Stack.Bar>
            <Stack.Content>
                {flags.add && <TabForm data={data} onSubmit={onAdd} cancelBtn={reset} reverse saveBtnText={t("common.save")} width="is-max-desktop" />}
                {flags.edit && <TabForm data={data} onSubmit={onEdit} cancelBtn={reset} reverse saveBtnText={t("common.save")} width="is-max-desktop" />}
                {flags.view && <Table list={positionsData} keys={tableHeaders(t)} searchPlaceholder={t("manage.positions.search_for")} onItemClick={edit} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
            </Stack.Content>
        </Stack.Begin>
    );
};
