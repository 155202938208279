import { tr } from "date-fns/locale";

export const template = (departmentOptions, roleOptions, user, t) => {
  if (!departmentOptions || !roleOptions) {
    console.error('Missing required parameters');
    return [];
  }

  return [{
    name: t("common.user"), fields: [
      { name: "firstName", label: t("common.first_name"), value: user?.first_name || undefined, required: true },
      { name: "surName", label: t("common.last_name"), value: user?.sur_name || undefined, required: true },
      { name: 'email', label: t("common.email"), validator: 'email', value: user?.email || undefined, required: true, disabled: user ? true : false, description: user ? t("manage.users.email_message") : undefined },
    ]
  },
  {
    name: t("manage.users.access"), fields:
      [
        { name: "role", label: t("common.role"), options: roleOptions, value: user?.role || 2, multiple: false, type: 'select', required: true },
        { name: 'isEnabled', label: t("manage.users.is_active"), type: 'checkbox', value: user?.enabled, hidden: user ? false : true },
        { name: 'isEmployee', label: t("manage.users.is_employee"), type: 'checkbox', value: user?.is_employee, hidden: user ? true : false },
        { name: 'isCustomer', label: t("manage.users.is_customer"), type: 'checkbox', value: user?.is_customer, hidden: user ? true : false },
      ]
  },
  {
    name: t("manage.users.note"), fields:
      [
        // TODO: Enable textarea
        { name: 'note', label: t("manage.users.note"), type: 'textarea', value: user?.data?.note || undefined },
      ]
  }];
}

export const tableHeaders = (t) => [
  { key: 'name', label: t("common.user") },
  { key: 'status', label: t("common.status") },
  { key: 'roleName', label: t("common.role") },
  { key: 'email', label: t("common.email") }
];

