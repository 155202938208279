import React from 'react';
import {
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

export const AreaPlot = ({ data, dataKey, palette, switches, color }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart data={data}>
        <defs>
          <linearGradient id={`gradient_${color}`} x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={palette[color]} stopOpacity={0.6} />
            <stop offset="95%" stopColor={palette[color]} stopOpacity={0} />
          </linearGradient>
        </defs>
        <Tooltip />
        <XAxis dataKey="name" hide />
        <Area
          type="monotone"
          dataKey={dataKey || 'value'}
          name="Value"
          strokeWidth={2}
          stroke={palette[color]}
          fill={`url(#gradient_${color}`}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};
