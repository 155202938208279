
import React from 'react';
import { TextFader, Stack } from '@components';
import { useTranslation } from "react-i18next";

export const LoadingAppScreen = () => {
    const { t } = useTranslation();
    return (
        <Stack.Begin center>
            <Stack.Bar>
                {/* https://lottie.host/13d8227a-e0f9-464e-a870-fa8d4a91bbb8/1f60uCyLcO.json */}
                {/* https://lottie.host/d073f26c-533d-4907-92eb-621f306377aa/pnCt3chT0z.json */}
                {/* https://lottie.host/13d8227a-e0f9-464e-a870-fa8d4a91bbb8/1f60uCyLcO.json */}
                {/* https://lottie.host/c51571b3-e2d7-4ced-8d1c-8e7ab856112b/zJcg4lnPxb.json */}
                {/* https://lottie.host/b5ca2dd5-1afc-444d-8e3e-44cc58bd650e/wTepQFs1B0.json */}
                {/* https://lottie.host/9e6517e6-194d-47c2-a767-534ec721a78f/g5zDq50DhR.json */}
                <div style={{ width: "200px", height: "150px" }}>
                    <dotlottie-player src="https://lottie.host/b5ca2dd5-1afc-444d-8e3e-44cc58bd650e/wTepQFs1B0.json" background="transparent" speed="0.7" loop autoplay></dotlottie-player>
                </div>
            </Stack.Bar>
            <TextFader textList={[t("screens.loading_message_1"), t("screens.loading_message_2"), t("screens.loading_message_3"), t("screens.loading_message_4")]} />
        </Stack.Begin>
    );
}