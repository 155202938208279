import React from 'react'

export const Search = ({ searchPlaceholder, searchQuery, setSearchQuery }) => {
  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const handleClearSearch = () => setSearchQuery('');
  return (
    <>
      <div className="field has-addons">
        <div className="control is-expanded">
          <input
            className="input"
            type="text"
            placeholder={searchPlaceholder || "Search"}
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        {searchQuery && (
          <div className="control">
            <button className="button" onClick={handleClearSearch}>
              <span className="icon">
                <i className="fa-solid fa-circle-xmark"></i>
              </span>
            </button>
          </div>
        )}
      </div>
    </>
  )
};