import React, { useState } from 'react';
import { FadeInOut } from '@components/animations';

export const TextFader = ({ textList, className }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  let text = textList[currentIndex];
  const animationEnd = (e) => {
    setCurrentIndex((currentIndex + 1) % textList.length);
  }

  return (
    <div className={`${className || ''}`}>
      <div className="columns is-vcentered is-multiline has-text-centered">
        <div className='column'>
          <FadeInOut onAnimationEnd={animationEnd}>
            {text}
          </FadeInOut>
        </div>
      </div>
    </div>
  );
};
