export const template = (activity, t) => {
  console.log('activity', activity);
  return [{
    name: 'Data', fields: [
      { name: "name", label: t("common.name"), value: activity?.data?.name || '', required: true },
      { name: 'active', label: t("manage.activities.is_active"), type: 'checkbox', value: activity?.data?.active || true },
      { name: 'description', label: t("common.description"), type: 'text', value: activity?.data?.description || null, required: true },
      { name: "type", label: "Type", options: types(t), value: activity?.data?.type, multiple: false, type: 'select', required: true },
      { name: 'isBillable', label: t("manage.activities.is_billable"), type: 'checkbox', value: activity?.data?.isBillable },
      { name: 'hourRate', label: t("manage.activities.default_hour_rate"), type: 'number', value: activity?.data?.hourRate || 1000, required: true },
    ]
  }];
}

//TODO: Get from backend. What about translations?
const types = (t) => [
  { label: t("manage.activities.hours"), value: "hours" },
  { label: t("manage.activities.leave_with_pay"), value: "timeoffpay" },
  { label: t("manage.activities.leave"), value: "timeoff" },
  { label: t("manage.activities.overtime"), value: "overtime" },
  { label: t("manage.activities.vacation"), value: "vacation" },
  { label: t("manage.activities.illness"), value: "illness" },
];

export const tableHeaders = (t) => [
  { key: 'data.name', label: t("common.name") },
  { key: 'data.description', label: t("common.description") },
  { key: 'data.type', label: t("common.type") },
  { key: 'data.active', label: t("manage.activities.is_active") },
  { key: 'data.isBillable', label: t("manage.activities.is_billable") },
  { key: 'data.hourRate', label: t("manage.activities.hour_rate") }
]

