
import React, { useEffect, useState, createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useJikanokeAPI } from '@services';
import { useFlags } from '@hooks';
import { H1 } from '@components';
import { useTranslation } from "react-i18next";
import { useSelection, useTimeentries, useTimeentryForm } from "../time/provider";
import { formatDate } from "../time/aux/tools";

const ManageContext = createContext();

const countryOptions = [
  {
    value: 'no', label: 'Norway'
  }
];

const useTimeEntryManage = () => {
  const onDateChangeEvent = () => { };
  const onDeleteEvent = () => { };
  const onUpdateEvent = () => { };
  const Selection = useSelection(onDateChangeEvent);

  useEffect(() => {
    Selection.month.apply();
  }, []);

  const { activities, projects, timeentries } = useJikanokeAPI();
  const { data: projectsData, isLoading: isProjectDataLoading, isError: projectsError } = projects.useList(true);
  const { data: activitiesData, isLoading: isActivitiesLoading, isError: activitiesError } = activities.useList(true);
  const { data: timeentriesDataRaw, isLoading: isTimeentriesLoading, isError: timeentriesError } = timeentries.organization.useList(true, Selection.from.toISOString().split('T')[0], Selection.to.toISOString().split('T')[0], localStorage.getItem('organization'))

  const projectSelection = projectsData && activitiesData ? projectsData.map(project => ({
    value: project.id,
    name: project.name,
    activities: project.data.activities?.map(activityId => {
      var activity = activitiesData?.find(activity => activity.id === activityId);
      return { value: activity.id, name: activity.data.name }
    })
  }))
    : [];

  const timeentriesData = timeentriesDataRaw ? timeentriesDataRaw.map((timeentry) => ({ ...timeentry, date: formatDate(new Date(timeentry.date)) })) : [];


  const Data = {
    isLoading: isProjectDataLoading || isActivitiesLoading || isTimeentriesLoading,
    isError: projectsError || activitiesError || timeentriesError,
    activitiesData,
    timeentriesData,
    projectsData,
    projectSelection
  };


  const Timeentries = useTimeentries(Data, onDeleteEvent, onUpdateEvent, timeentries.organization.useUpdate());
  const TimeentriesForm = useTimeentryForm(Data, Selection, Timeentries);

  return {
    Selection,
    Data,
    Timeentries,
    TimeentriesForm
  }

}

//TODO: Clean up the firing!! Too many requests to server
export const ManageProvider = ({ children }) => {
  const { t } = useTranslation();
  const { flags, flagXOn } = useFlags({ add: false, edit: false, view: true });
  const [searchQuery, setSearchQuery] = useState('');
  const [item, setItem] = useState(null);
  const onDateChangeEvent = () => { };

  const { organizations, users, roles, departments, activities, projects, customers, positions, employees } = useJikanokeAPI();
  const SelectionFrom = useSelection(onDateChangeEvent);
  const SelectionTo = useSelection(onDateChangeEvent);
  const { data: organizationData, isLoading: isOrganizationLoading, isError: isOrganizationError } = organizations.useId(true, localStorage.getItem('organization'));
  const { data: usersDataRaw, isLoading: isUsersLoading, isError: usersError } = users.useList(true);
  const { data: rolesData, isLoading: isRolesLoading, isError: rolesError } = roles.useList(true);
  const { data: departmentsData, isLoading: isDepartmensLoading, isError: departmentsError } = departments.useList(true);
  const { data: activitiesData, isLoading: isActivitiesLoading } = activities.useList(true);
  const { data: projectsData, isLoading: isProjectDataLoading, isError: projectsError } = projects.useList(true);
  const { data: customersData, isLoading: isCustomersLoading, isError: customersError } = customers.useList(true);
  const { data: positionsData, isLoading: isPositionsLoading, isError: positionsError } = positions.useList(true);
  const { data: employeesData, isLoading: isEmployeesLoading, isError: employeeErrror } = employees.useList(true);

  const TimeEntryManage = useTimeEntryManage();

  const isLoading = isRolesLoading || isUsersLoading || isDepartmensLoading || isActivitiesLoading || isProjectDataLoading || isCustomersLoading || isPositionsLoading || isEmployeesLoading || isOrganizationLoading || TimeEntryManage.Data.isLoading;
  const isError = rolesError || usersError || departmentsError || projectsError || customersError || positionsError || employeeErrror || isOrganizationError || TimeEntryManage.Data.isError;

  const usersData = usersDataRaw && rolesData ? usersDataRaw.map((user) => ({ name: user.first_name + ' ' + user.sur_name, ...user, roleName: rolesData.find((role) => role.code === user.role)?.name, status: t(`manage.users.status.${user.data?.status || 'ok'}`) })) : [];
  const roleOptions = rolesData ? rolesData.map((role) => ({ value: role.code, label: role.name })) : [];
  const departmentOptions = departmentsData ? [
    { value: '', label: t("common.no_selection") },
    ...departmentsData.map((department) => ({ value: department.id, label: department.data.name }))
  ] : [];
  const activitiesOptions = activitiesData ? activitiesData.map((activity) => ({ value: activity.id, label: activity.data.name })) : [];
  const projectsOptions = projectsData ? projectsData.map((project) => ({ value: project.id, label: project.data.name })) : [];
  const customersOptions = customersData ? [
    { value: '', label: t("common.no_selection") }, // Default object for no selection
    ...customersData.map((customer) => ({ value: customer.id, label: customer.data.name })),
  ] : [];
  const usersOptions = usersData ? usersData.map((user) => ({ value: user.id, label: user.first_name + ' ' + user.sur_name })) : [];
  const positionsOptions = positionsData ? positionsData.map((position) => ({ value: position.code, label: position.name })) : [];
  const employeesOptions = employeesData ? employeesData.map((employee) => ({ value: employee.id, label: employee.data.first_name + ' ' + employee.data.sur_name })) : [];
  const rolesDataMapped = rolesData ? rolesData.map(role => ({ ...role, data: { ...role.data, access: t(`manage.roles.access_levels.${role.data.access}`), permissions: t(`manage.roles.access_levels.${role.data.access}`) } })) : [];




  const reset = () => {
    setItem(null);
    flagXOn('view');
  }
  const create = () => {
    setItem(null);
    flagXOn('add');
  }

  // The TabForm is sending both event and item on click, so reusing that here
  const edit = (event, item) => {
    setItem(item);
    flagXOn('edit');
  }

  const location = useLocation();

  useEffect(() => {
    // Your navigation event handling logic here
    console.log(`Navigated to ${location.pathname}`);
    reset();
    // Cleanup function
    return () => {
      // Additional cleanup if needed
    };
  }, [location]);

  return (
    <ManageContext.Provider value={{
      setSelectedFromDate: SelectionFrom.setSelectedDate,
      setSelectedToDate: SelectionTo.setSelectedDate,
      flags,
      flagXOn,
      item,
      setItem,
      isLoading, isError,
      users, usersData, usersOptions,
      roles, rolesData: rolesDataMapped, roleOptions,
      departments, departmentsData, departmentOptions,
      activities, activitiesData, activitiesOptions,
      projectsOptions, projects, projectsData,
      customersOptions, customers, customersData,
      positions, positionsData, positionsOptions,
      employees, employeesData, employeesOptions,
      organizations, organizationData,
      TimeEntryManage,
      reset,
      create,
      edit,
      countryOptions,
      searchQuery,
      setSearchQuery
    }}>
      {children}
    </ManageContext.Provider>
  );
};

export const useManage = () => useContext(ManageContext);

export const ManageHeader = ({ plural, singular, new_text }) => {
  const { t } = useTranslation();
  const { flags, reset, create: addNew, searchQuery, setSearchQuery } = useManage();

  const handleSearchChange = (e) => setSearchQuery(e.target.value);
  const handleClearSearch = () => setSearchQuery('');
  const searchPlaceholder = t(`common.search`);
  return (
    <div className=''>
      <div className="columns is-fullwidth is-gapless is-vcentered">
        <div className="column is-narrow">  <H1>{flags.view ? plural : `${flags.edit ? t("common.edit") : new_text || t("common.new")} ${singular}`}</H1></div>
        <div className='column auto'>

        </div>
        {flags.view && <>
          <div className="column is-narrow">
            <button className="button mr-2 is-outlined" onClick={addNew}>
              <span className="icon" >
                <i className="fas fa-pen"></i>
              </span>
              <span>{new_text} {singular}</span>
            </button>
          </div>
          <div className='column is-3'>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  placeholder={searchPlaceholder || "Search"}
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
              </div>
              {searchQuery && (
                <div className="control">
                  <button className="button" onClick={handleClearSearch}>
                    <span className="icon">
                      <i className="fa-solid fa-circle-xmark"></i>
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
        }
        {
          !flags.view && <div className="column is-narrow">
            <button className="button is-danger is-outlined" onClick={reset}>
              <span className="icon" >
                <i className="fas fa-times"></i>
              </span>
            </button>
          </div>
        }
      </div>
    </div>
  );
};
