import React from 'react';
import { Table, TabForm, Stack } from '@components';
import { useTranslation } from "react-i18next";
import { useManage, ManageHeader } from '../ManageProvider';
import { template, tableHeaders } from './template';

export const Employees = () => {
    const { flags, reset, item: employee, edit, employeesData, employees, departmentOptions, positionsOptions, countryOptions, isLoading, isError, searchQuery, setSearchQuery } = useManage();
    const { t } = useTranslation();
    const create = employees.useCreate();
    const update = employees.useUpdate();

    if (isLoading || isError || !departmentOptions) return null;

    const data = template(departmentOptions, positionsOptions, countryOptions, employee, t);

    const onAdd = (data) => {
        const createData = { ...data, data: data };
        create.mutate(createData, { onSuccess: reset });
    }

    const onEdit = (data) => {
        const updateData = { ...data, data: data, id: employee.id, version: employee.version };
        update.mutate(updateData, { onSuccess: reset });
    }

    return (
        <Stack.Begin>
            <Stack.Bar>
                <ManageHeader plural={t("manage.employees.employees")} singular={t("manage.employees.employee")} new_text={t("common.new")} />
            </Stack.Bar>
            <Stack.Content>
                {flags.add && <TabForm data={data} onSubmit={onAdd} cancelBtn={reset} reverse saveBtnText={t("common.save")} />}
                {flags.edit && <TabForm data={data} onSubmit={onEdit} cancelBtn={reset} reverse saveBtnText={t("common.save")} />}
                {flags.view && <Table list={employeesData} keys={tableHeaders(t)} searchPlaceholder={t("manage.employees.search_for")} onItemClick={edit} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
            </Stack.Content>
            {/* TODO; Need to move pagination away from the table and into the layout. This is a workaround */}
            <Stack.Bar />
        </Stack.Begin>
    );
};
