export const template = (departmentOptions, activitiesOptions, usersOptions, customersOptions, project, t) => {
  if (!departmentOptions || !activitiesOptions || !usersOptions || !customersOptions) {
    console.error('Missing required parameters');
    return [];
  }
  return [{
    name: t("manage.projects.details"), fields: [
      { name: "name", label: t("manage.projects.project_name"), value: project?.name || '', required: true },
      { name: "startDate", label: t("common.start_date"), type: "date", value: project?.data?.startDate || null, required: true },
      { name: "endDate", label: t("common.end_date"), type: "date", value: project?.data?.endDate || null, description: t("manage.projects.leave_blank_if") },
      { name: "customer", label: t("manage.projects.customer"), options: [...customersOptions], value: project?.data?.customer, multiple: false, type: 'select' },
      { name: "departments", label: t("manage.projects.department"), options: [...departmentOptions], value: project?.data?.departments, multiple: true, type: 'select' }
    ]
  },
  {
    name: 'Status', fields: [
      { name: "comment", label: t("manage.projects.comment_status"), value: project?.data?.comment || '' },
      { name: 'active', label: t("manage.projects.is_active"), type: 'checkbox', value: project?.data?.active },
      { name: "status", label: "Status", options: statusOptions, value: project?.status || 'ongoing', multiple: false, type: 'select', required: true },
      { name: 'isBillable', label: t("manage.projects.billable"), type: 'checkbox', value: project?.data?.isBillable },
    ]
  },
  {
    name: t("manage.projects.activities"), badge: { icon: 'fa-solid fa-list-check', text: `${project?.data?.activities?.length || 0}` }, fields: [
      { name: "activities", label: t("manage.projects.activities_multi"), options: activitiesOptions, value: project?.data?.activities || [], type: 'select', multiple: true },
      { name: "note", label: t("manage.projects.comment_activities"), type: 'textarea', value: project?.data?.note || '' },]
  },
  {
    name: t("manage.projects.members"), badge: { icon: 'fa-solid fa-people-group', text: `${project?.data?.members?.length || 0}` }, fields: [
      { name: "members", label: t("manage.projects.members"), options: usersOptions, value: project?.data?.members || [], type: 'select', multiple: true }
    ]
  }
  ];
}

const statusOptions = [
  { label: "Ongoing", value: "ongoing" },
  { label: "Bid", value: "bit" },
  { label: "Lost", value: "lost" },
  { label: "Discontinued", value: "discontinued" },
];


export const tableHeaders = (t) => [
  { key: 'name', label: t("common.name") },
  { key: 'data.startDate', label: t("common.start_date") },
  { key: 'data.endDate', label: t("common.end_date") },
  { key: 'status', label: 'Status' },
  { key: 'data.isBillable', label: t("manage.projects.billable") }
];

