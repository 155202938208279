import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { useJikanokeAPI } from '@services';

const States = {
    AUTENTICATING: 'authenticating',
    INITIAL_FETCH: 'initial_fetch',
    NOT_AUTHENTICATED: 'not_authenticated',
    ONBOARDED: 'onboarded',
    NOT_ONBOARDED: 'not_onboarded',
    ERROR: 'error',
};

/* App start flow and states  */
export const useAppStates = () => {
    const { organizations, users } = useJikanokeAPI();
    const { isAuthenticated, isLoading: isAuth0Loading, user, error: auth0Error } = useAuth0();
    const { data: userOrganizations, isLoading: isOrganizationsLoading, error: serverError } = organizations.useList(user);
    const navigate = useNavigate();
    const location = useLocation();
    const updateUser = users.useAccepted();

    const [currentState, setCurrentState] = useState(States.AUTENTICATING);

    useEffect(() => {
        /*  
        Start flow at root if not authenticated and for some reason url is not root
        then we don't have to worry about rendering something we dont have the data for anyawys 
        */
        if (!isAuthenticated && !isAuth0Loading && location.pathname !== '/') {
            navigate('/');
        }

        //  Calulate the current state
        if (auth0Error || serverError) {
            return setCurrentState(States.ERROR);
        }
        if (!isAuthenticated && !isAuth0Loading) {
            return setCurrentState(States.NOT_AUTHENTICATED);
        }
        if (isAuthenticated && !isAuth0Loading && isOrganizationsLoading) {
            return setCurrentState(States.INITIAL_FETCH);
        }
        if (isAuthenticated && !isAuth0Loading && !isOrganizationsLoading && user && userOrganizations && userOrganizations.length > 0) {
            return setCurrentState(States.ONBOARDED);
        }
        if (isAuthenticated && !isAuth0Loading && !isOrganizationsLoading && user && userOrganizations && userOrganizations.length === 0) {
            return setCurrentState(States.NOT_ONBOARDED);
        }
    }, [isAuthenticated, location.pathname, navigate, isAuth0Loading, auth0Error, serverError, isOrganizationsLoading, user, userOrganizations]);


    useEffect(() => {
        // TODO: Do this more elegantlly. Join with useJikanokeAPI
        // This can not be here actually, it should be in the useJikanokeAPI
        // No, on second thoughts, I will fix this on the server!! Server should set the default organization
        if (localStorage.getItem('organization') === null && userOrganizations && userOrganizations.length > 0) {
            localStorage.setItem('organization', userOrganizations[0].id);
        }
        if (localStorage.getItem('organization') !== null && userOrganizations && userOrganizations.length > 0 && userOrganizations[0]?.data?.firstLogin) {
            // TODO: This is a hack. Need to store that user logged in first time. This only works for the first organization, which is fine for now.
            updateUser.mutate({ id: userOrganizations[0].user_id, version: userOrganizations[0].version });
        }
    }, [userOrganizations])
    return {
        States,
        currentState,
        user: { ...user, organizations: userOrganizations, isOnboarded: !isOrganizationsLoading && user && userOrganizations && userOrganizations.length > 0 },
        error: serverError || auth0Error
    }
}