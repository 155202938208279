import React, { useState, useEffect } from 'react';
import './tooltip.scss';

export const Tooltip = ({ text, icon, children, className, up, delay, ...props }) => {
  const delayInMilliseconds = delay || 800;
  const [isHovered, setIsHovered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    let timeout;

    if (isHovered) {
      if (delay <= 0) {
        setIsVisible(true);
        return;
      }
      timeout = setTimeout(() => {
        setIsVisible(true);
      }, delayInMilliseconds); // Delay in milliseconds
    } else {
      setIsVisible(false);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isHovered, delayInMilliseconds]);
  return (
    <div className={`tooltip ${className ? className : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      /*  onMouseLeave={() => setIsHovered(false)} */
      {...props}
    >
      {icon && <span className={`icon has-text-danger  ${isVisible ? '' : 'is-hidden'}`}><i className={icon}></i></span>}
      <span className={`tooltip-text ${isVisible ? '' : 'is-invisible'} ${up ? 'up' : ''}`}>{text}</span>
      {children}
    </div>);
}