import './fader.scss';

import React from 'react';
import { v4 as uuidv4 } from "uuid";

export const FadeInOut = ({ children, onAnimationEnd }) => {
  return (
    <div key={`fade_${uuidv4()}`} className={`fade-in-out`} onAnimationEnd={onAnimationEnd}>
      {children}
    </div>
  );
};
