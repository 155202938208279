export const template = (departmentOptions, roleOptions, position, t) => {
  if (!departmentOptions || !roleOptions) {
    console.error('Missing required parameters');
    return [];
  }
  return [{
    name: 'Data', fields: [
      { name: "name", label: t("common.name"), value: position?.name || '', required: true },
      { name: 'descrption', label: t("common.description"), type: 'text', value: position?.data?.description || null, required: true },
      { name: 'includedLunch', label: t("manage.positions.lunch_included"), type: 'checkbox', value: position?.data?.includedLunch || false },
      { name: 'durationWeekly', label: t("manage.positions.weekly_working_hours"), type: 'number', value: position?.data?.durationWeekly || 37.5, required: true },
    ]
  }];
}

export const tableHeaders = (t) => [
  { key: 'name', label: t("common.name") },
  { key: 'data.description', label: t("common.description") },
  { key: 'data.includedLunch', label: t("manage.positions.lunch_included") },
  { key: 'data.durationWeekly', label: t("manage.positions.weekly_working_hours") }
]

