import React, { useState } from 'react';
import { useStore } from '@providers';
import { Block, Columns, Column, TextToggle, H2, Switch, Dimension } from '@components';

export const PlotContainer = ({ title, data, height, toolbox, element: ChildElement, ...props }) => {
  const { palette } = useStore().colors;
  const [switches, setOptions] = useState({
    showOptions: false,
    grids: false,
    showLegend: true,
    showTooltip: true,
    showYAxis: true,
  });

  const toggle = (switchId) => {
    setOptions((prevSwitches) => ({
      ...prevSwitches,
      [switchId]: !prevSwitches[switchId],
    }));
  };

  if (!data || data.length === 0) return null;

  return (
    <>
      <Block className="is-fullwidth">
        <Columns>
          <Column>
            <H2 size="4">{title}</H2>
          </Column>
          {toolbox &&
            <Column className="is-narrow">
              <TextToggle
                id="showOptions"
                initialText="Show options"
                toggleText="Hide options"
                onToggle={toggle}
              />
            </Column>}
        </Columns>
        {toolbox &&
          <Columns className={`${switches.showOptions ? '' : 'is-hidden'}`}>
            <Column className="is-three-quarters"></Column>
            <Column className="is-one-quarters">
              <Columns className="is-narrow">
                <Column>
                  Show Grid
                </Column>
                <Column className="is-narrow">
                  <Switch
                    switchId="grids"
                    checked={switches.grids}
                    onToggle={() => toggle('grids')}
                  />
                </Column>
              </Columns>
              {/* Add more toggles as needed */}
              <Columns className="is-narrow">
                <Column>Show Legend</Column>
                <Column className="is-narrow">
                  <Switch
                    switchId="showLegend"
                    checked={switches.showLegend}
                    onToggle={() => toggle('showLegend')}
                  />
                </Column>
              </Columns>
              {/* Add more toggles as needed */}
            </Column>
          </Columns>
        }
        <Dimension height={height || 35}>
          <ChildElement palette={palette} switches={switches} data={data} {...props} />
        </Dimension>
      </Block>
    </>
  );
};
