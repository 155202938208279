const baseUrl = 'http://localhost:3001/api';

export const get = (url, header) => new Promise(async (resolve, reject) => fetch(`${baseUrl}${url}`, {
  method: 'GET',
  headers: await header(),
})
  .then(response => response.json())
  //.then(data => { console.log(url, data); return data; })
  .then(data => resolve(data.results))
  .catch(error => {
    console.error(error);
    reject(error)
  }))

export const post = (url, header, data) => new Promise(async (resolve, reject) => fetch(`${baseUrl}${url}`, {
  method: 'POST',
  headers: await header(),
  body: JSON.stringify(data),
})
  .then(response => {
    if (!response.ok) {
      reject(new Error(response.status));
    }
    return response.json();
  })
  //.then(data => { console.log(url, data); return data; })
  .then(data => resolve(data.results))
  .catch(error => reject(error)))

export const patch = (url, header, data) => new Promise(async (resolve, reject) => fetch(`${baseUrl}${url}`, {
  method: 'PATCH',
  headers: await header(),
  body: JSON.stringify(data),
})
  .then(response => resolve(data))
  .catch(error => reject(error)))

export const put = (url, header, data) => new Promise(async (resolve, reject) => fetch(`${baseUrl}${url}`, {
  method: 'PUT',
  headers: await header(),
  body: JSON.stringify(data),
})
  .then(response => {
    if (!response.ok) {
      reject(new Error(response.status));
    }
    return response.json();
  })
  .then(data => resolve(data.results))
  .catch(error => reject(error)))

export const del = (url, header, data) => new Promise(async (resolve, reject) => fetch(`${baseUrl}${url}`, {
  method: 'DELETE',
  headers: await header(),
  body: JSON.stringify(data),
})
  .then(response => {
    if (!response.ok) {
      reject(new Error(response.status));
    }
    return response.json();
  })
  .then(data => { console.log(url, data); return data; })
  .then(data => resolve(data))
  .catch(error => reject(error)))