import { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import io from 'socket.io-client';
import config from "../config";

const useSocket = (organizationId) => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [socket, setSocket] = useState(null);
    const [isConnected, setIsConnected] = useState(false);
    const [attempts, setAttempts] = useState(0);

    useEffect(() => {
        if(!isAuthenticated){
            return;
        }
        // Connect to the WebSocket server
        const newSocket = io(config.apiHost, {
            reconnection: true,
            // reconnectionAttempts: 5, // Infinite
            reconnectionDelay: 4000, // 4 second    
            reconnectionDelayMax: 6000, // 6 seconds
            query: {
                organizationId: organizationId
            },
            auth: {
                token: getAccessTokenSilently()
            },
            extraHeaders: {
                //'Access-Control-Allow-Origin': 'http://localhost:3001',
                // Add other headers if necessary
            },
        });

        // Handle connection events
        newSocket.on('connect', () => {
            console.log('Connected to WebSocket server');
            setIsConnected(true)
        });

        newSocket.on('disconnect', () => {
            console.log('Disconnected from WebSocket server');
            setIsConnected(false)
        });
        newSocket.io.on('reconnect', () => {
            setIsConnected(true)
            setAttempts(0);
          });
      
          newSocket.io.on("reconnect_attempt", (attempt) => {
            setIsConnected(false)
            setAttempts(attempt);
          });

        // Set the socket in the state
        setSocket(newSocket);

        // Clean up the socket connection on component unmount
        return () => {
            newSocket.disconnect();
        };
    }, [isAuthenticated]);

    return {
        socket,
        isConnected,
        attempts
    }
}

export default useSocket;