import { useJikanokeAPI } from '@services';

export const useData = () => {
  const { activities, projects, timeentries } = useJikanokeAPI();
  const { data: projectsData, isLoading: isProjectDataLoading, isError: projectsError } = projects.useList(true);
  const { data: activitiesData, isLoading: isActivitiesLoading, isError: activitiesError } = activities.useList(true);
  const { data: timeentriesData, isLoading: isTimeentriesLoading, isError: timeentriesError } = timeentries.useList(true);

  const projectSelection = projectsData && activitiesData ? projectsData.map(project => ({
    value: project.id,
    name: project.name,
    activities: project.data.activities?.map(activityId => {
      var activity = activitiesData?.find(activity => activity.id === activityId);
      return { value: activity.id, name: activity.data.name }
    })
  }))
    : [];

  return {
    isLoading: isProjectDataLoading || isActivitiesLoading || isTimeentriesLoading,
    isError: projectsError || activitiesError || timeentriesError,
    activitiesData,
    timeentriesData,
    projectsData,
    projectSelection
  }
}