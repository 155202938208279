

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '@providers';
import { useKeyPress, useMouseTracker } from '@hooks';
import { Slide } from './Slide';
import { useSlides } from './SlidesProvider';
import { Controls } from './Controls';

import '@components/layouts/float.scss';
import './slides.scss';

export const Slides = () => {
  const { isFullscreen } = useStore().fullscreen;
  const { presentationId, presentation, page, pageId, slideId, modes, status, edit, goto } = useSlides();

  const ref = React.useRef(null);
  const goTo = React.useRef(null);
  const isMouseIdle = useMouseTracker(2500);
  const keys = useKeyPress(['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Enter', 'Escape']);

  useEffect(() => {
    if (keys['ArrowDown']) {
      if (pageId + 1 < presentation.pages.length) {
        if (modes.animation) {
          ref.current.classList.add('animate__animated', presentation.page.animationOut);
          goTo.current = { page: pageId + 1, slide: 0 };
        } else {
          goto(pageId + 1, 0);
        }
      }
    } else if (keys['ArrowUp']) {
      if (pageId - 1 >= 0) {
        goto(pageId - 1, 0);
      }
    }
    else if (keys['ArrowRight']) {
      if (slideId + 1 >= page.slides.length) {
        // Go to next page
        const index = (pageId + 1 + presentation.pages.length) % presentation.pages.length;
        if (modes.animation) {
          ref.current.classList.add('animate__animated', presentation.page.animationOut);
          goTo.current = { page: index, slide: 0 };
        } else {
          goto(index, 0);
        }
      } else {
        // Next slide
        goto(pageId, slideId + 1);
      }
    }
    else if (keys['ArrowLeft']) {
      // If first slide, go to previous page
      if (slideId - 1 < 0) {
        const index = (pageId - 1 + presentation.pages.length) % presentation.pages.length;
        goto(index, presentation.pages[index].slides.length - 1);
      } else {
        // Previous slide
        goto(pageId, slideId - 1);
      }
    }
  }, [keys]);

  const onAnimationEnd = () => {
    ref.current.classList.remove('animate__shakeX', 'animate__pulse', presentation.page.animationIn);
    if (goTo.current) {
      goto(goTo.current.page, goTo.current.slide);
      goTo.current = null;
    }
  }

  const goToSlide = (slide) => () => {
    goto(pageId, slide);
  };

  useEffect(() => {
    if (!modes.animation) return;

    const element = document.querySelector('.' + presentation.page.animationOut);
    if (!element) {
      ref.current.classList.add('animate__animated', 'animate__pulse');
    } else {
      element.classList.remove('animate__animated', presentation.page.animationOut);
    }
  }, [slideId, modes.animation, presentation.page.animationOut]);

  useEffect(() => {
    if (!modes.animation) return;

    if (ref.current) {
      const element = document.querySelector('.' + presentation.page.animationOut);
      if (element) {
        element.classList.remove('animate__animated', presentation.page.animationOut);
      }
      ref.current.classList.add('animate__animated', presentation.page.animationIn);
    }
  }, [pageId, modes.animation, presentation.page.animationOut, presentation.page.animationIn]);

  useEffect(() => {
    if (modes.animation) return;
    document.querySelectorAll('.animate__animated').forEach((element) => element.classList.remove('animate__animated', 'animate__pulse', presentation.page.animationOut));

  }, [modes.animation]);

  return (
    <section className="section" style={{ cursor: isMouseIdle && !modes.edit ? 'none' : 'auto' }}>
      <div className="container-fluid">
        <div className="float is-centered is-flex is-flex-direction-column is-justify-content-center">
          {modes.menu &&

            <>{presentation.pages.map(({ icon, label }, index) =>
            (<Link className={`menu-item ${pageId === index ? 'is-active' : ''}`} key={`pagenav_${index}`} to={`/presents/${presentationId}/${index}/${0}`} >
              <span className="icon is-small"><i className={`fas fa-${icon}`} aria-hidden="true"></i></span>
              <span className={`${modes.menuHeadings ? '' : 'label'} ml-2`}>{label}</span>
            </Link>))}</>

          }
          {modes.edit &&
            <div className='menu-item' key='pagenav-new' onClick={edit.addPage} >
              <span className="icon is-small"><i className={`fa-solid fa-plus`} aria-hidden="true"></i></span>
              <span className={`ml-2`}>Add page</span>
            </div>}
        </div>
        <div>
          <div className={`${isMouseIdle && !modes.edit ? 'is-hidden' : ''} slides-status ${isFullscreen ? 'fullscreen' : ''}`}>
            <span className='has-text-primary'>{status.currentSlide}</span> / <span className='has-text-primary'>{status.totalSlides}</span>
          </div>
          <div ref={ref} onAnimationEnd={onAnimationEnd}>
            <Slide />
          </div>
          <div className='downmenu is-flex is-align-content-center is-justify-content-center'>
            {page.slides.length > 1 && Array.from({ length: page.slides.length }).map((_, index) => <span key={`slidenav_${index}`} onClick={goToSlide(index)} className="icon is-small mr-2"><i className={`${slideId === index ? 'fa-solid fa-circle' : 'fa-regular fa-circle'} has-text-primary`} aria-hidden="true"></i></span>)}
            {modes.edit && <div className="icon is-small auto" onClick={edit.addSlide}><i className={`fa-solid fa-plus has-text-primary`} aria-hidden="true"></i> <span className="ml-2">Add slide</span></div>}
          </div>
          {(!isMouseIdle || modes.edit) && <div style={{ position: 'absolute', bottom: '2.2rem', right: '1rem' }}><Controls /></div>}
        </div>
      </div>
    </section>
  );
};


