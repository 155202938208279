import React from 'react';
import { Levels } from '@components';
import { useTranslation } from "react-i18next";
import { getWeekNumber } from './aux';
import { BubbleBoxesSelection, useBubbleBoxSelection } from './aux/Calendar';
import { useCalendar } from './provider';

export const CalendarHeader = () => {
  const { t } = useTranslation();
  const { selection, event, position, open, close } = useBubbleBoxSelection();
  const { TimeentriesForm, Selection, Statistics } = useCalendar();

  const levels = [
    { heading: t("common.prev"), titleIcon: 'arrow-left', onClick: () => Selection.auto.move(-1) },
    { heading: `${t("time.go_to_today")}`, titleIcon: 'calendar-day', onClick: () => Selection.auto.apply(new Date()) },
    { heading: t("time.month"), title: t('common.months_short.' + Selection.from.getMonth()), onClick: (event) => open(event, 'month') },
    { heading: t("time.year"), title: Selection.from.getFullYear(), onClick: (event) => open(event, 'year') },
    { heading: t("time.logged"), title: Statistics.selection.logged, tooltip: `${t("time.you_have_logged")} ${Statistics.selection.logged} ${t("time.you_have_logged_" + Selection.view)}` },
    { heading: t("time.available"), title: Statistics.selection.available, tooltip: t("time.regular_available_" + Selection.view) },
    { heading: t("common.next"), titleIcon: 'arrow-right', onClick: () => Selection.auto.move(1) },
  ]

  if (Selection.view === 'day') {
    levels.splice(1, 0, { heading: t("time.day"), title: Selection.from.getDate(), onClick: (event) => open(event, 'day') });
  }
  if (Selection.view === 'week' || Selection.view === 'day') {
    levels.splice(2, 0, { heading: t("time.week"), title: getWeekNumber(Selection.from), onClick: (event) => open(event, 'week') });
  }


  if (TimeentriesForm.currentItem === null) return null;

  return (
    <>
      <BubbleBoxesSelection event={event} id={selection} position={position} close={close} />
      <Levels levels={levels} />
    </>
  );
};
