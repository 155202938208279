import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Stack, H1 } from '@components';

const Switch = ({ switchId, checked, onToggle }) => {
    const handleToggle = () => {
        onToggle(switchId);
    };

    return (
        <div className="control">
            <div className="switch">
                <input
                    id={switchId}
                    type="checkbox"
                    checked={checked}
                    onChange={handleToggle}
                />
                <label htmlFor={switchId}>{switchId}</label>
            </div>
        </div>
    );
};

export const Notifications = () => {
    const { t } = useTranslation();
    const [switches, setSwitches] = useState({
        switch1: false,
        switch2: true,
        switch3: false,
        // Add more switches as needed
    });

    const handleSwitchToggle = (switchId) => {
        setSwitches((prevSwitches) => ({
            ...prevSwitches,
            [switchId]: !prevSwitches[switchId],
        }));
    };
    return (
        <Stack.Begin>
            <Stack.Bar>
                <H1> {`${t("account.left_menu.notifications")}`}</H1>
            </Stack.Bar>
            <Stack.Content>
                <div className='content'>
                    <blockquote>{`${t("account.notifications.info_part1")}`}&nbsp;<Link to="/account/connections" className='has-text-link'>{`${t("common.connections")}`}</Link>{`${t("account.notifications.info_part2")}`}</blockquote>
                    <div className="columns">
                        <div className="column">
                            {`${t("account.notifications.not_logged")}`}
                        </div>
                        <div className="column is-narrow">
                            <Switch
                                switchId="switch1"
                                checked={switches.switch1}
                                onToggle={handleSwitchToggle}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column">
                            {`${t("account.notifications.send_weekly")}`}
                        </div>
                        <div className="column is-narrow">
                            <Switch
                                switchId="switch2"
                                checked={switches.switch2}
                                onToggle={handleSwitchToggle}
                            />
                        </div>
                    </div>

                    <div className="columns">
                        <div className="column">
                            {`${t("account.notifications.project_changes")}`}
                        </div>
                        <div className="column is-narrow">
                            <Switch
                                switchId="switch3"
                                checked={switches.switc3}
                                onToggle={handleSwitchToggle}
                            />
                        </div>
                    </div>
                </div>
            </Stack.Content>
        </Stack.Begin>

    )
}
/*
Account Page: An account page typically focuses on managing the user's account settings and preferences. It provides functionality related to authentication, security, and account management. Some common features you might find on an account page include:

Username and password management
Email or contact information update
Privacy settings
Notification preferences
Two-factor authentication settings
Account deletion or deactivation options
Connected social media accounts
The account page is primarily concerned with the technical and administrative aspects of the user's account.
*/
