import React, { useState } from 'react';

import { useAuth0 } from "@auth0/auth0-react";
import { useStore, LogoutAnchor } from '@providers';
import { Stack, H1, Switch } from '@components';
import { useTranslation } from "react-i18next";

import EngSvg from '@assets/flags/eng.svg';
import NoSvg from '@assets/flags/norway.svg';

export const User = () => {
    const { user } = useAuth0();
    const { theme, toggleTheme } = useStore().colors;
    const { i18n, t } = useTranslation();

    const [isdark, setIsDark] = useState(theme === 'dark');
    const onToggle = (id, event) => {
        setIsDark(value => !value);
        setTimeout(toggleTheme, 500);
    }

    const languageToggle = (id, lang) => {
        i18n.changeLanguage(lang);
    }

    return (
        <Stack.Begin>
            <Stack.Bar>
                <H1> {`${t("account.left_menu.your_profile")}`}</H1>
            </Stack.Bar>
            <Stack.Content>
                <div className="columns is-justify-content-center">
                    <div className="column is-narrow">
                        <figure className="image is-rounded is-128x128">
                            <img className="is-rounded" src={user.picture} alt="User" referrerPolicy="no-referrer" />
                        </figure>
                    </div>
                </div>
                <div className="columns is-justify-content-center">
                    <div className="column">
                        <div className="control has-icons-left has-icons-right">
                            <input className="input" type="text" placeholder="Name" value={user.name} readOnly />
                            <span className="icon is-small is-left">
                                <i className="fas fa-user"></i>
                            </span>
                            <span className="icon is-small is-right">
                                <i className="fas fa-check"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="columns is-justify-content-center">
                    <div className="column">
                        <div className="control has-icons-left has-icons-right">
                            <input className="input" type="email" placeholder="Email" value={user.email} readOnly />
                            <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                            <span className="icon is-small is-right">
                                <i className="fas fa-check"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="columns ">
                    <div className="column has-text-centered">
                        {t("account.profile.dark_mode")}
                    </div>
                    <div className="column is-narrow">
                        <Switch switchId="theme" checked={!isdark} onToggle={onToggle} />
                    </div>
                    <div className="column has-text-centered">
                        {t("account.profile.light_mode")}
                    </div>
                </div>
                <div className="columns">
                    <div className="column has-text-centered">
                        <button className={`button ${i18n.language === 'en' ? 'is-active' : ''}`} style={{ width: '6rem' }} onClick={() => languageToggle(null, 'en')}><img src={EngSvg} alt="English language" /></button>
                        <button className={`button ml-4 ${i18n.language === 'no' ? 'is-active' : ''}`} style={{ width: '6.5rem' }} onClick={() => languageToggle(null, 'no')}><img src={NoSvg} alt="Norsk språk" /></button>
                    </div>
                </div>
                <div className="has-text-centered">
                    <LogoutAnchor />
                </div>
            </Stack.Content>
        </Stack.Begin>
    )
}

/*
Profile Page: A profile page, on the other hand, is more focused on presenting information about a user to others. It is often publicly accessible and displays details about the user's identity, interests, and activities. Some common features you might find on a profile page include:

User's name and profile picture
Bio or about section
Contact information (optional)
Social media links
Personal website or blog
List of followers or friends
Recent activity or posts
Interests, hobbies, or skills
Achievements or badges
The profile page serves as a way for users to showcase themselves or their work to others within a community or platform.
*/
