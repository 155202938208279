import { useState } from 'react';

//TODO: Hook up to backend
export const useCalendarItems = () => {
  const [items, setItems] = useState(new Map([
    ['2023-05-06', [{ icon: 'cake-candles', text: 'Happy Birthday, Erik! Sorry, but you still have to work!', color: 'success' }]],
    ['2023-05-09', [{ icon: 'lightbulb', text: 'Betalt fagdag! Bli med da vel, se rainmakers.no for mer', color: 'primary' }, { icon: 'champagne-glasses', text: 'Fest etterpå! Se rainmakers.no for mer', color: 'success' }]],
    // Add more entries as needed
  ]));
  return {
    items,
  }
}