import React, { useState } from 'react';

export const useFlags = (initFlags) => {
  const [flags, setFlags] = useState(initFlags);

  const toggleFlag = (flag) => {
    setFlags(flags => {
      return {
        ...flags,
        [flag]: !flags[flag]
      }
    });
  }

  const flagOn = (flag) => {
    setFlags(flags => {
      return {
        ...flags,
        [flag]: true
      }
    });
  }

  const flagOff = (flag) => {
    setFlags(flags => {
      return {
        ...flags,
        [flag]: false
      }
    });
  }

  //All other flags off, flagX on
  const flagXOn = (flag) => {
    setFlags(flags => {
      return {
        ...Object.keys(flags).reduce((acc, cur) => {
          acc[cur] = false;
          return acc;
        }, {}),
        [flag]: true
      };
    });
  }

  return { flags, toggleFlag, flagOn, flagOff, flagXOn };
}