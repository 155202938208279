import React from 'react';

import { LogoutAnchor } from '../providers'
import { TabForm, Stack, H2 } from "@components";
import { useJikanokeAPI } from '../services';
import { useTranslation } from "react-i18next";

import { ReactComponent as TimSvg } from '@assets/tim.svg';

const data = (t) => [{
  name: t("onboarding.organization"), fields:
    [
      { name: 'name', label: t("onboarding.org_name"), required: true },
      { name: 'orgNumber', label: t("onboarding.org_number"), validator: 'orgnr', required: true },
      { name: 'legalName', label: t("onboarding.org_legal_name"), required: true }
    ]
},
{
  name: t("onboarding.contact"), fields:
    [
      { name: 'email', label: "Email", validator: t("common.email") },
      { name: 'phone', label: "Phone", validator: t("common.phone_no") },
      { name: 'website', label: "Website", validator: t("common.website") }
    ]
},
{
  name: t("onboarding.address"), fields:
    [
      { name: 'address.street', label: t("common.street") },
      { name: 'address.city', label: t("common.city") },
      { name: 'address.postalcode', label: t("common.postal_code") },
      { name: 'address.postalplace', label: t("common.postal_place") },
      { name: 'address.country', label: t("common.country"), options: [t("common.countries.no"), t("common.countries.se"), t("common.countries.dk"), t("common.countries.fi")] }
    ]
}]

const OnboardScreen = ({ user }) => {
  const { t } = useTranslation();
  const jikanokeApi = useJikanokeAPI();
  const create = jikanokeApi.organizations.useCreate();
  const handleSubmit = (formData) => {
    console.log(formData);
    create.mutate(formData);
  };
  return (
    <Stack.Begin>
      <Stack.Bar className="has-primary-bg has-text-centered">
        <TimSvg style={{
          width: '150px'
        }} />
      </Stack.Bar>
      <Stack.Bar className="has-text-centered">
        <H2>
          {`${t("onboarding.welcome")}, ${user.given_name}! ${t("onboarding.please")}`}
        </H2>
        <hr className="is-divider" />
      </Stack.Bar>
      <Stack.Content>
        <TabForm data={data(t)} onSubmit={handleSubmit} saveBtnText={t("common.save_continue")} />
      </Stack.Content>
      <Stack.Bar className="has-text-centered">
        <LogoutAnchor />
      </Stack.Bar>
    </Stack.Begin>
  );
};

export default OnboardScreen;
