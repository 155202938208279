import React, { useEffect, useTransition } from 'react';
import { Link, useLocation, Route, Routes, useNavigate } from 'react-router-dom';
import { useStore } from '@providers';
import { Container } from '../bulma';
import * as Stack from './Stack';
import { useKeyPress, useParam, useMouseTracker } from '@hooks';

import './float.scss';

export const FloatLayout = ({ routes, useAnimation }) => {
  const { enterFullscreen } = useStore().fullscreen;
  const ref = React.useRef(null);
  const goTo = React.useRef(null);
  const location = useLocation();
  const slide = useParam('slide', 0);
  const isMouseIdle = useMouseTracker(1000);
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();

  const isActiveTab = (path) => {
    const currentPaths = location.pathname.split('/');
    return currentPaths.pop() === path || (path === '' && currentPaths.length === 1);
  }

  const goto = (path) => {
    startTransition(() => {
      navigate(path);
    });
  }

  const keys = useKeyPress(['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Enter', 'Escape']);
  const subroutes = routes.find((route) => isActiveTab(route.link)).subroutes || 0;
  useEffect(() => {
    if (keys['ArrowDown']) {
      const next = routes.findIndex((route) => isActiveTab(route.link)) + 1;

      if (next < routes.length) {
        if (useAnimation) {
          ref.current.classList.add('animate__animated', 'animate__backOutDown');
          goTo.current = routes[next].link;
        } else {
          navigate(routes[next].link);
        }
      }
    } else if (keys['ArrowUp']) {
      const next = routes.findIndex((route) => isActiveTab(route.link)) - 1;
      if (next >= 0) {
        goto(routes[next].link);
      }
    }
    else if (keys['ArrowRight']) {
      let next = (parseInt(slide) + 1);
      console.log(next, subroutes);
      if (next >= subroutes) {
        next = routes.findIndex((route) => isActiveTab(route.link)) + 1;
        console.log(next, routes);
        next = next < routes.length ? next : 0;
        console.log(next, routes);
        if (useAnimation) {
          ref.current.classList.add('animate__animated', 'animate__backOutDown');
          goTo.current = routes[next].link;
        } else {
          goto(routes[next].link);
        }
      } else {
        goto(`/${isNaN(next) ? Math.random() : next}`);
      }
    }
    else if (keys['ArrowLeft']) {
      const index = (parseInt(slide) - 1 + subroutes) % subroutes;
      goto(`/${isNaN(index) ? Math.random() : index}`);
    }
  }, [keys]);

  const onAnimationEnd = () => {
    ref.current.classList.remove('animate__shakeX', 'animate__pulse', 'animate__backInDown');
    if (goTo.current) {
      goto(goTo.current);
      goTo.current = null;
    }
  }

  const onNextIdQueryParam = (index) => () => {
    goto(`/${index}`);
  };

  useEffect(() => {
    if (!useAnimation) return;

    const element = document.querySelector('.animate__backOutDown');
    if (!element) {
      if (isNaN(slide) || (subroutes === 0 && slide > 0)) {
        ref.current.classList.add('animate__animated', 'animate__shakeX');
      } else if (slide > 0) {
        ref.current.classList.add('animate__animated', 'animate__pulse');
      }
    } else {
      element.classList.remove('animate__animated', 'animate__backOutDown');
    }
  }, [slide, useAnimation]);

  useEffect(() => {
    if (ref.current && useAnimation) {
      const element = document.querySelector('.animate__backOutDown');
      if (element) {
        element.classList.remove('animate__animated', 'animate__backOutDown');
      }
      ref.current.classList.add('animate__animated', 'animate__backInDown');
    }
  }, [location.pathname, useAnimation]);

  return (
    <>
      {/* Floating left menu */}
      <menu className="float is-centered is-flex is-flex-direction-column is-justify-content-center">
        {routes.map(({ icon, link, label, fullscreen }) =>
        (<Link className={`menu-item ${isActiveTab(link) ? 'is-active' : ''}`} key={`link_${link}`} to={link} onClick={fullscreen ? enterFullscreen : () => { }}>
          <span className="icon is-small"><i className={`fas fa-${icon}`} aria-hidden="true"></i></span>
          <span className='label ml-2'>{label}</span>
        </Link>))}
      </menu>
      {/* Scrollable content, single page with scrolling */}
      <Container className="float-layout is-fluid" style={{ cursor: isMouseIdle ? 'none' : 'auto' }}>
        <div className='is-max'>
          <Stack.Begin>
            <Stack.Content>
              <Container className='is-max-desktop' ref={ref} onAnimationEnd={onAnimationEnd}>
                <Routes>
                  {routes.map(({ link, element }) => (<Route key={link} path={link} element={element} />))}
                </Routes>
              </Container>
            </Stack.Content>
          </Stack.Begin>
        </div>
        {/* Floating bottom submenu if applicable*/}
        {subroutes > 1 &&
          <div className='downmenu is-flex is-align-content-center is-justify-content-center'>
            {Array.from({ length: subroutes }).map((_, index) => <span key={`id_${index}`} onClick={onNextIdQueryParam(index)} className="icon is-small mr-2"><i className={`${parseInt(slide) === index ? 'fa-solid fa-circle' : 'fa-regular fa-circle'} has-text-primary`} aria-hidden="true"></i></span>)}
          </div>
        }
      </Container>
    </>
  );
};
