import React from 'react';
import { useLocation, Route, Routes } from 'react-router-dom';
import { Levels, Container } from '../bulma';
import { Footer } from '../footer';

export const StackLayout = ({ routes, className, levels, narrow, children }) => {
  const location = useLocation();

  const isActiveTab = (path) => {
    const currentPaths = location.pathname.split('/');
    if (path.includes('/')) {
      return location.pathname.includes(path);
    }
    return currentPaths.pop() === path || (path === '' && currentPaths.length === 1);
  }

  const getActiveTabFromRoutesOrSubroutes = () => {
    const currentPaths = location.pathname.split('/');
    return routes.find(route => route.link.includes(currentPaths[currentPaths.length - 1])) || routes.find(route => route.subroutes && route.subroutes.find(subroute => subroute.link.includes(currentPaths[currentPaths.length - 1])));
  }

  const getActiveLevelFromRoutesOrSubroutes = () => {
    const currentPaths = location.pathname.split('/');
    return levels.find(level => level.link.includes(currentPaths[currentPaths.length - 1])) || levels.find(level => level.subroutes && level.subroutes.find(subroute => subroute.link.includes(currentPaths[currentPaths.length - 1])));
  }

  const activeRoute = getActiveTabFromRoutesOrSubroutes();
  const activeLevel = getActiveLevelFromRoutesOrSubroutes();
  const renderRoutes = activeRoute.subroutes ? [activeRoute, ...activeRoute.subroutes] : routes;
  const renderLevels = activeLevel.subroutes ? [{ heading: "Tilbake", titleIcon: "arrow-left", onClick: levels[0].onClick, link: levels[0].link }, activeLevel, ...activeLevel.subroutes] : levels;

  return (
    <Container className={`stack-layout ${className || `${narrow ? 'is-fluid' : 'is-fullhd'}`}`}>
      <div className={`${narrow ? 'is-max-70' : 'is-max-100'}`}>
        <Routes>
          {renderRoutes.map(({ link, element }) => (<Route key={link} path={link} element={element} />))}
        </Routes>
      </div>
      <div className={`${narrow ? '' : 'is-hidden'} has-border-left is-max-30`}>
        {/*  ml-6 mr-2 matches navbar and content margins for now */}
        <div className='ml-6 mr-2 is-overlay'>
          {children}
        </div>
      </div>
      <Footer>
        <Levels className="dark-bg" levels={renderLevels.map(level => ({ ...level, isActive: isActiveTab(level.link) }))} />
      </Footer>
    </Container>
  );
};
