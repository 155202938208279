import { useLocation } from 'react-router-dom';

export const useParam = (key, defaultValue) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const value = params.get(key) || defaultValue;

  const parsedValue = parseFloat(value);
  const isNumber = !isNaN(parsedValue);


  return isNumber ? parseInt(value, 10) : value;
};