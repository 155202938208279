
import React from 'react';

import { LogoutAnchor } from '@providers'
import { Block, TextFader, H1, H2, Stack } from '@components';
import { useTranslation } from "react-i18next";

import money from '@assets/money-with-wings.gif';

export const MobileNotSupportedScreen = () => {
    const { t } = useTranslation();
    return (
        <Stack.Begin center>
            <Stack.Bar className='has-text-centered'>
                <H1>{t("screens.whoa")}</H1>
                <H2>{t("screens.not_support_mobile")}</H2>
            </Stack.Bar>
            <Stack.Bar>
                <img src={money} alt={t("screens.alt_money")} style={{ height: '30vh' }} />
            </Stack.Bar>
            <TextFader textList={[t("screens.mobile_message_1"), t("screens.mobile_message_2"), t("screens.mobile_message_3"), t("screens.mobile_message_4")]} />
            <Block />
            <Stack.Bar>
                <LogoutAnchor></LogoutAnchor>
            </Stack.Bar>
        </Stack.Begin>
    );
}
