import React from 'react';
import { Table } from '@components';
import { useTranslation } from "react-i18next";
import { useManage, ManageHeader } from '../ManageProvider';
import { template, tableHeaders } from './template';

import { Stack } from '@components';

export const Roles = () => {
    const { flags, reset, item: role, edit, rolesData, roles, isLoading, isError, searchQuery, setSearchQuery } = useManage();
    const { t } = useTranslation();
    const create = roles.useCreate();
    const update = roles.useUpdate();

    if (isLoading || isError) return null;

    const data = template(role, t);

    const onAdd = (data) => {
        const createData = { ...data, data: data };
        create.mutate(createData, { onSuccess: reset });
    }

    const onEdit = (data) => {
        const updateData = { ...data, data: data, id: role.id };
        update.mutate(updateData, { onSuccess: reset });
    }

    return (
        <Stack.Begin>
            <Stack.Bar>
                <ManageHeader plural={t("manage.roles.roles")} singular={t("manage.roles.role")} new_text={t("common.new")} />
            </Stack.Bar>
            <Stack.Content>
                {flags.add && <blockquote>
                    {t("manage.roles.not_supported_message_new")}
                </blockquote>}
                {flags.edit && <blockquote>
                    {t("manage.roles.not_supported_message_edit")}
                </blockquote>}
                {flags.view && <Table list={rolesData} keys={tableHeaders(t)} searchPlaceholder={t("manage.roles.search_for")} onItemClick={edit} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
            </Stack.Content>
            {/* TODO; Need to move pagnation from table and buttons away from the form and into the layout. This is a workaround */}
            <Stack.Bar />
        </Stack.Begin>
    );
};
