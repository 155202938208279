export const notEmpty = (value) => {
  if (Array.isArray(value) || typeof value === 'string') return value.length > 0;
  else if (typeof value === 'object') return Object.keys(value).length > 0;
  else if (typeof value === 'number') return value > 0;
  return false;
}

export const validators = (t) => ({
  "email": {
    test: (email) => /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(email),
    description: t("validation.email")
  },
  "phone": {
    test: (phone) => { return /^(\+(?:46|47|45))?(\d{7,9})$/.test(phone) },
    description: t("validation.phone")
  },
  "website": {
    test: (website) => /^((http|https):\/\/)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})(\/[^\s]*)?$/.test(website),
    description: t("validation.website")
  },
  "orgnr": {
    test: (orgnr) => /^\d{9}$/.test(orgnr),
    description: t("validation.orgnr")
  }
});
