import React from 'react';

import { TIM, Stack } from '@components';
import { useAuth0 } from "@auth0/auth0-react";

export const LoginScreen = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Stack.Begin center className="has-primary-bg">
      <Stack.Bar>
        <div style={{ width: '50vw', maxWidth: '235px' }} className='animate__animated animate__pulse animate__infinite animate__slower is-link' onClick={() => loginWithRedirect()}>
          <TIM />
        </div>
      </Stack.Bar>
    </Stack.Begin>
  );
}
