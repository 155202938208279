import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { useStore } from '@providers';
import { AccountMenu, OrganizationTabs, DashboardTabs, TimeMenu, ManageMenu, InfoMenu, CalendarProvider, Slides, SlidesProvider } from '@views';
import { NavBar } from '@components';

function RegularView({ user }) {
  const { fullscreen } = useStore();

  if (fullscreen.isFullscreen) {
    document.documentElement.style.overflow = 'hidden';
    document.body.style.overflow = 'hidden';
  } else {
    document.documentElement.style.overflow = '';
    document.body.style.overflow = '';
  }

  const isAdmin = user?.organizations[0]?.role === 1;

  return (
    <div className="App">
      <CalendarProvider>
        {/*  TODO: Obviously single tenant code right here! Fix when user can select between organizations! */}
        {!fullscreen.isFullscreen && <NavBar organization={user?.organizations[0]?.name} role={user?.organizations[0]?.role} />}
        <Routes>
          <Route path="/dashboard/presenter-mode" element={<Navigate to="/presents/0/0/0" replace />} />
          {/*  Organization dashboard for those who should have access to that */}
          <Route path="/organization-dashboard/*" element={<OrganizationTabs />} />
          {/*  User dashboard */}
          <Route path="/dashboard/*" element={<DashboardTabs />} />
          <Route path="/account/*" element={<AccountMenu />} />
          <Route path="/presents/:presentation/:page/:slide/*" element={<SlidesProvider><Slides /></SlidesProvider>} />
          <Route path="/time/*" element={<TimeMenu />} />
          {/*   This is for the managers as well. Should take the presentations out from manage, as they are for all users */}
          <Route path="/manage/*" element={<ManageMenu />} />
          {/*   Deprecated */}
          <Route path="/info/*" element={<InfoMenu />} />
          {/*   Default route */}
          <Route path="/" element={<Navigate to={`${isAdmin ? 'organization-dashboard' : 'dashboard'}`} replace />} />
        </Routes>
      </CalendarProvider>
    </div>
  );
}

export default RegularView;
