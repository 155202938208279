/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { useDebounce } from '@hooks';
import { useTranslation } from "react-i18next";
import { Stack } from '@components';

/* TODO: Create a util folder with Objects.js and move cool stuff there. Do same with Dates and what the fuck not */
function getValueFromNestedKey(obj, key, func) {
  const keys = key.split('.'); // Split the key string by dot
  let value = obj;

  for (const k of keys) {
    if (value && value[k] !== undefined) {
      value = value[k];
    } else {
      value = undefined;
      break;
    }
  }
  if (typeof value == "boolean") {
    value = value ? "Yes" : "No";
  }

  if (func) return func(value);

  return value;
}


export const Table = ({ list, keys, selectedId, searchQuery, onItemClick = () => { }, filterableKeys = [], perPage = 60 }) => {

  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState(list);
  const [filterValues, setFilterValues] = useState({});
  const searchQueryDebounced = useDebounce(searchQuery || '', 250);

  useEffect(() => {
    const filtered = Array.isArray(list) && list.filter((item) => {
      const searchTerm = searchQueryDebounced.toLowerCase();

      return Object.values(item).some((value) => {
        try {
          if (value && typeof value === 'object') {
            return Object.values(value).some((v) => v && v.toString().toLowerCase().includes(searchTerm));
          }
          return (value && value.toString().toLowerCase().includes(searchTerm)) || false;
        } catch (e) {
          console.log(e);
          return false;
        }
      });
    });
    setFilteredData(filtered);
    setCurrentPage(1);
  }, [searchQueryDebounced, list]);

  const handleFilterChange = (event, key) => {
    const { value } = event.target;
    setFilterValues((prevFilterValues) => ({
      ...prevFilterValues,
      [key]: value,
    }));
  };

  const applyFilters = () => {
    let filtered = list;
    for (const key in filterValues) {
      const value = filterValues[key];
      if (value) {
        filtered = filtered.filter((item) => item[key] === value);
      }
    }
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilterValues({});
    setFilteredData(list);
    setCurrentPage(1);
  };

  // Calculate pagination
  const totalPages = Math.ceil(filteredData.length / perPage);
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;
  const currentData = Array.isArray(filteredData) && filteredData.slice(startIndex, endIndex) || [];
  const paginationBundle = 6;

  console.log(totalPages, currentPage);

  // Pagination click handlers
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  // Get distinct values for filterable keys
  const distinctValues = keys.filter(key => filterableKeys.includes(key.key)).reduce((acc, key) => {
    const values = Array.from(new Set(list.map((item) => item[key.key])));
    return { ...acc, [key.key]: values };
  }, {});

  const onClickItem = (data) => (event) => {
    if (onItemClick) {
      onItemClick(event, data)
    }
  }

  if (list.length === 0) {
    return (<div className="my-4 p-4 has-text-centered">{t("common.search_empty")}</div>);
  }

  return (
    <Stack.Begin>
      {/*  TODO: Disable this for now */}
      {/*  {filterableKeys.length > 0 &&
        <L.Bar>
          <div className="field has-addons is-pulled-right">
            {keys.filter(key => filterableKeys.includes(key.key)).map((key) => (
              <div key={`control_${key.key}_${uuidv4()}`} className="control">
                <div className="select">
                  <select
                    value={filterValues[key.key] || ""}
                    onChange={(event) => handleFilterChange(event, key.key)}
                  >
                    <option value="">{`${t("common.all")}`} {key.label}</option>
                    {distinctValues[key.key].map((value) => (
                      <option key={value} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            ))}
            <div className="buttons ml-2">
              <button className="button is-link" onClick={applyFilters}>
                {`${t("common.apply_filter")}`}
              </button>
              <button className="button is-light" onClick={clearFilters}>
                {`${t("common.clear_filter")}`}
              </button>
            </div>
          </div>

        </L.Bar>
      } */}

      <Stack.Content>
        <table className="table is-striped is-hoverable is-fullwidth">
          <thead >
            <tr>
              {keys.map((key) => (
                <th key={`th_${key.key}`}>{key.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((data) => (
              <tr key={`tr_${uuidv4()}`} className={`${onItemClick ? 'is-clickable' : ''} ${selectedId === data.id ? 'is-selected' : ''}`}>
                {keys.map((key) => (
                  <td className="cap-first" key={key.key} onClick={onClickItem(data)}>{getValueFromNestedKey(data, key.key, key.func)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Stack.Content>
      {totalPages > 1 && <Stack.Bar>
        <nav className="pagination is-centered" role="navigation" aria-label="pagination">
          <a
            className="pagination-previous"
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            {`${t("common.prev")}`}
          </a>
          <a
            className="pagination-next"
            onClick={goToNextPage}
            disabled={currentPage === totalPages}
          >
            {`${t("common.next")}`}
          </a>
          <ul className="pagination-list">
            {/* Show ellipsis if needed */}
            {totalPages > paginationBundle && currentPage > Math.ceil(paginationBundle / 2) + 1 && (<>
              {/* Show first page */}
              <li>
                <a
                  className={`pagination-link ${currentPage === 1 ? "is-current" : ""}`}
                  onClick={() => goToPage(1)}
                >
                  1
                </a>
              </li>
              <li>
                <span className="pagination-ellipsis">&hellip;</span>
              </li>
            </>)}
            {/* Calculate start and end of the window */}
            {Array.from({ length: paginationBundle }, (_, i) => currentPage - Math.floor(paginationBundle / 2) + i).filter(page => page >= 1 && page <= totalPages).map((page) => (
              <li key={`li_${page}`}>
                <a
                  className={`pagination-link ${currentPage === page ? "is-current" : ""}`}
                  onClick={() => goToPage(page)}
                >
                  {page}
                </a>
              </li>
            ))}
            {/* Show ellipsis if needed */}
            {currentPage < paginationBundle - 1 && totalPages > paginationBundle && (
              <li>
                <span className="pagination-ellipsis">&hellip;</span>
              </li>
            )}
            {/* Show last page */}
            {totalPages > paginationBundle && currentPage < totalPages - 2 && (
              <li>
                <a
                  className={`pagination-link ${currentPage === totalPages ? "is-current" : ""}`}
                  onClick={() => goToPage(totalPages)}
                >
                  {totalPages}
                </a>
              </li>
            )}
          </ul>
        </nav>
      </Stack.Bar>
      }
    </Stack.Begin>
  );
};