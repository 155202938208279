import React from 'react';
import { Link, useLocation, Route, Routes } from 'react-router-dom';
import Breadcrumbs from '../bulma/Breadcrumbs';

import './menu.scss';

const renderRoutes = (routes) => {
  return routes.map(({ link, element, subroutes }, index) => (
    <React.Fragment key={`${link}-${index}`}>
      <Route key={link} path={link} element={element} />
      {subroutes && subroutes.length > 0 && renderRoutes(subroutes)}
    </React.Fragment>
  ));
};

const MenuRoutes = ({ routes }) => {
  return (
    <Routes>
      {routes.map(({ link, element, subroutes }, index) => (
        <React.Fragment key={`${link}-${index}`}>
          <Route path={link} element={element} />
          {subroutes && subroutes.length > 0 && (
            <React.Fragment>{renderRoutes(subroutes)}</React.Fragment>
          )}
        </React.Fragment>
      ))}
    </Routes>
  );
};

const MenuList = ({ title, icon, link, label, subroutes, noCollapse = false }) => {
  const location = useLocation();
  const isActiveTab = (path) => {
    const currentPaths = location.pathname.split('/');
    if (path.includes('/')) {
      return location.pathname.includes(path);
    }
    return currentPaths.pop() === path || (path === '' && currentPaths.length === 1);
  }
  const isActiveChildTab = (subroutes) => {
    const currentPaths = location.pathname.split('/');
    return subroutes.some(route => route.link.includes(currentPaths[currentPaths.length - 2]));
  }
  return (
    <>
      {title &&
        <p className="menu-label">
          {title}
        </p>
      }
      <li key={link} >
        <Link key={link} to={link} className={isActiveTab(link) ? 'is-active' : ''}>
          {icon && <span className="icon is-small mr-4"><i className={`fas fa-${icon}`} aria-hidden="true"></i></span>}
          <span>{label}</span>
        </Link>
        {subroutes && subroutes.length > 0 && (
          <ul className={isActiveTab(link) || isActiveChildTab(subroutes) ? '' : !noCollapse ? 'is-sr-only' : ''}>
            {subroutes.map(({ icon, link, label, subroutes }) => (<MenuList key={link} icon={icon} link={link} label={label} subroutes={subroutes} />))}
          </ul>
        )}
      </li>
    </>
  )
}
export const LeftSideMenu = ({ title, routes, breadcrumbs }) => {

  return (

    <div className="columns">
      <div className="column is-narrow has-border-right">
        <aside className="menu">
          <p className="menu-label">{title ?? ''}</p>
          <ul className="menu-list">
            {routes.map(({ icon, link, newTitle, label, subroutes, noCollapse }) => (<MenuList key={link} title={newTitle} icon={icon} link={link} label={label} subroutes={subroutes} noCollapse={noCollapse} />))}
          </ul>
        </aside>
      </div>
      <div className="column">
        <div className="p-4">
          {breadcrumbs && <Breadcrumbs />}
          <MenuRoutes routes={routes} />
        </div>
      </div>
    </div>

  );
};

//TODO: Narrow equals "show right sidepanel"
export const MenuContentContent = ({ title, routes, narrow, breadcrumbs, children }) => {

  return (

    <div className="columns">
      <div className={`column is-narrow `}> {/* ${narrow ? 'is-narrow' : 'is-one-fifth'} */}
        <aside className="menu is-sticky mt-6">
          <p className="menu-label">{title ?? ''}</p>
          <ul className="menu-list">
            {routes.map(({ icon, link, newTitle, label, subroutes, noCollapse }) => (<MenuList key={link} title={newTitle} icon={icon} link={link} label={label} subroutes={subroutes} noCollapse={noCollapse} />))}
          </ul>
        </aside>
      </div>
      <div className="column auto">
        <div className="mt-6">
          {breadcrumbs && <Breadcrumbs />}
          <MenuRoutes routes={routes} />
        </div>
      </div>
      <div className={`${narrow ? '' : 'is-hidden'} column is-narrow has-border-left min-width-27`}>
        {/*  TODO: The is-sticy should be modify, as height of screen  has impact on the scrollability  of the content */}
        <div className="ml-4 is-sticky">
          {children}
        </div>
      </div>

    </div>

  );
};