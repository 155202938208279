import React, { useState } from 'react';
import { Block, Switch } from '@components';
import poweroffice from '@assets/po-logo.svg';
import { useTranslation } from "react-i18next";

export const PowerOffice = () => {
    const [switches, setSwitches] = useState({
        activated: false,
        readData: false,
        writeData: false,
        syncProjects: false,
        syncCustomers: false,
        syncEmployees: false,
        syncHours: false,
        hasKey: false
    });
    const handleSwitchToggle = (switchId) => {
        setSwitches((prevSwitches) => ({
            ...prevSwitches,
            [switchId]: !prevSwitches[switchId],
        }));
    };

    const { t } = useTranslation();

    return (
        <div className="content">
            <div className="card">
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-48x48">
                                <img src={poweroffice} alt="PowerOffice logo" />
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="title is-4"> <a href="https://poweroffice.no">PowerOffice Go</a></p>
                            <p className="subtitle is-6">{t("manage.integrations.po.best")}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Block />
            <blockquote>

                {!switches.hasKey &&
                    <p>
                        {t("manage.integrations.po.info")}
                    </p>
                }
            </blockquote>
            <Block>

                {!switches.hasKey &&
                    <Block>
                        <input className="input" type="text" placeholder={t("manage.integrations.api_key")} />
                    </Block>
                }
                <div className="columns">
                    <div className="column">
                        <i className="fa-solid fa-power-off"></i><span className="ml-3">{switches.activated ? t("manage.integrations.deactivate_integration") : t("manage.integrations.activate_integration")}</span>
                    </div>
                    <div className="column is-narrow">
                        <Switch
                            switchId="activated"
                            checked={switches.activated}
                            onToggle={handleSwitchToggle}
                        />
                    </div>
                </div>
                {!switches.activated &&
                    <div className="notification is-warning">
                        {t("manage.integrations.not_activated")}
                    </div>}
                {switches.activated &&
                    <div className="notification is-success">
                        {t("manage.integrations.activated")}
                    </div>}
                <Block >
                    <div className="subtitle is-5">Permissions</div>
                </Block>
                <div className="columns">
                    <div className="column">
                        <i className="fa-solid fa-book-open"></i> <span className="ml-3">{`${t("manage.integrations.allow_read")} Poweroffice Go`}</span>
                    </div>
                    <div className="column is-narrow">
                        <Switch
                            disabled={!switches.activated}
                            switchId="readData"
                            checked={switches.readData}
                            onToggle={handleSwitchToggle}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <i className="fa-solid fa-pen"></i> <span className="ml-3">{`${t("manage.integrations.allow_write")} Poweroffice Go`}</span>
                    </div>
                    <div className="column is-narrow">
                        <Switch
                            disabled={!switches.activated}
                            switchId="writeData"
                            checked={switches.writeData}
                            onToggle={handleSwitchToggle}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <i className="fa-solid fa-rotate"></i> <span className="ml-3">{t("manage.integrations.allow_customer")}</span>
                    </div>
                    <div className="column is-narrow">
                        <Switch
                            disabled={!switches.activated}
                            switchId="syncCustomers"
                            checked={switches.syncCustomers}
                            onToggle={handleSwitchToggle}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <i className="fa-solid fa-rotate"></i> <span className="ml-3">{t("manage.integrations.allow_projects")}</span>
                    </div>
                    <div className="column is-narrow">
                        <Switch
                            disabled={!switches.activated}
                            switchId="syncProjects"
                            checked={switches.syncProjects}
                            onToggle={handleSwitchToggle}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <i className="fa-solid fa-rotate"></i> <span className="ml-3">{t("manage.integrations.allow_employees")}</span>
                    </div>
                    <div className="column is-narrow">
                        <Switch
                            disabled={!switches.activated}
                            switchId="syncEmployees"
                            checked={switches.syncEmployees}
                            onToggle={handleSwitchToggle}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <i className="fa-solid fa-rotate"></i> <span className="ml-3">{t("manage.integrations.allow_hours")}</span>
                    </div>
                    <div className="column is-narrow">
                        <Switch
                            disabled={!switches.activated}
                            switchId="syncHours"
                            checked={switches.syncHours}
                            onToggle={handleSwitchToggle}
                        />
                    </div>
                </div>
            </Block>
        </div >
    );
}
