import React from 'react';
import { GraphCanvas } from 'reagraph';
import { useStore } from '@providers';

import { POTs, Admins, Consultants } from './data';


const nodes = [
  {
    id: 'Rainfall',
    label: 'Rainfall',
    size: Admins.length + POTs.length + Consultants.length,
  },
  {
    id: 'Admin',
    label: 'Administrasjon',
    size: Admins.length,
  },
  {
    id: 'POT',
    label: 'POT',
    size: POTs.length,
  },
  {
    id: 'Konsulent',
    label: 'Konsulent',
    size: Consultants.length,
  }
];

const potNodes = POTs.map((name, index) => ({ id: `POT-${index}`, label: name }));
const adminNodes = Admins.map((name, index) => ({ id: `Admin-${index}`, label: name }));
const konsulentNodes = Consultants.map((name, index) => ({ id: `Konsulent-${index}`, label: name }));

const allNodes = [...nodes, ...potNodes, ...adminNodes, ...konsulentNodes];


const edges = [
  {
    id: 'Rainfall-POT',
    source: 'Rainfall',
    target: 'POT'
  },
  {
    id: 'Rainfall-Konsulent',
    source: 'Rainfall',
    target: 'Konsulent'
  },
  {
    id: 'Rainfall-Admin',
    source: 'Rainfall',
    target: 'Admin'
  }
];

const potEdges = potNodes.map((node) => ({ id: `POT-${node.id}`, source: 'POT', target: node.id }));
const adminEdges = adminNodes.map((node) => ({ id: `Admin-${node.id}`, source: 'Admin', target: node.id }));
const konsulentEdges = konsulentNodes.map((node) => ({ id: `Konsulent-${node.id}`, source: 'Konsulent', target: node.id }));
const allEdges = [...edges, ...potEdges, ...adminEdges, ...konsulentEdges];


const theme = {
  canvas: {
    background: '#f8f8f8',
  },
  node: {
    fill: '#7A8C9E',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.2,
    label: {
      stroke: '#1E2026',
      color: '#ACBAC7',
      activeColor: '#1DE9AC'
    },
    subLabel: {
      stroke: '#1E2026',
      color: '#ACBAC7',
      activeColor: '#1DE9AC'
    }
  },
  lasso: {
    border: '1px solid #55aaff',
    background: 'rgba(75, 160, 255, 0.1)'
  },
  ring: {
    fill: '#54616D',
    activeFill: '#1DE9AC'
  },
  edge: {
    fill: '#474B56',
    activeFill: '#1DE9AC',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#1E2026',
      color: '#ACBAC7',
      activeColor: '#1DE9AC',
      fontSize: 6
    }
  },
  arrow: {
    fill: '#474B56',
    activeFill: '#1DE9AC'
  },
  cluster: {
    stroke: '#474B56',
    opacity: 1,
    selectedOpacity: 1,
    inactiveOpacity: 0.1,
    label: {
      stroke: '#1E2026',
      color: '#ACBAC7'
    }
  }
};

export const Graph = () => {
  const { palette } = useStore().colors;

  return (

    <GraphCanvas
      nodes={allNodes}
      edges={allEdges}
      theme={{ ...theme, canvas: { background: palette.backgroundColor } }}
      edgeLabelPosition="above"
      labelType="all"
    />
  )
};