import React from 'react';
import { useTranslation } from "react-i18next";
import { Table, TabForm } from '@components';
import { useManage, ManageHeader } from '../ManageProvider';
import { template, tableHeaders } from './template';

import { Stack } from '@components';

export const Activities = () => {
    const { flags, reset, item: activity, edit, isLoading, isError, activities, activitiesData, searchQuery, setSearchQuery } = useManage();
    const create = activities.useCreate();
    const update = activities.useUpdate();
    const { t } = useTranslation();
    if (isLoading || isError) return null;

    const data = template(activity, t);

    const onAdd = (data) => {
        const createData = { ...data, data: data };
        create.mutate(createData, { onSuccess: reset });
    }

    const onEdit = (data) => {
        const updateData = { ...data, data: data, id: activity.id, version: activity.version };
        update.mutate(updateData, { onSuccess: reset });
    }

    return (
        <Stack.Begin>
            <Stack.Bar>
                <ManageHeader plural={t("manage.activities.activities")} singular={t("manage.activities.activity")} new_text={t("common.new")} />
            </Stack.Bar>
            <Stack.Content>
                {flags.add && <TabForm data={data} onSubmit={onAdd} cancelBtn={reset} reverse saveBtnText={t("common.save")} width="is-max-desktop" />}
                {flags.edit && <TabForm data={data} onSubmit={onEdit} cancelBtn={reset} reverse saveBtnText={t("common.save")} width="is-max-desktop" />}
                {flags.view && <Table list={activitiesData} keys={tableHeaders(t)} searchPlaceholder={t("manage.activities.search_for")} onItemClick={edit} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
            </Stack.Content>
            {/* TODO; Need to move pagination away from the table and into the layout. This is a workaround */}
            <Stack.Bar />
        </Stack.Begin>
    );
};
