import React from 'react';
import { useClickOutside } from '@hooks';
import { v4 as uuid } from 'uuid';
import './bubbleBox.scss';

export const BubbleBox = ({ position, close, children }) => {
  const handleOutsdeBoxClick = () => {
    console.log('Clicked outside the component');
    close();
  }
  const handleBoxClick = () => {
    console.log('Clicked inside the component');
  };
  const { ref } = useClickOutside(handleOutsdeBoxClick);
  return (
    <div className={`modal-layer`}>
      <div className={`box panel-menu`}
        style={{
          top: position?.y || 0,
          left: position?.x || 0,
        }}
        key={uuid()}
        ref={ref}
        onClick={handleBoxClick}>
        {children}
      </div>
    </div>
  );
}