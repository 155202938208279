import React from 'react';
/* eslint-disable jsx-a11y/anchor-is-valid */

export const TabMenu = ({ tabs, activeTab, setActiveTab, hasTabError }) => {
  return (
    <aside className="menu">
      <ul className="menu-list">
        {tabs.map((tab, index) => (
          <li className="has-icons-right" key={index} onClick={() => setActiveTab(tab.name)}>
            <a className={`${tab.name === activeTab ? 'is-active' : ''} is-flex is-justify-content-space-between`}>
              <span>{tab.name}</span>
              {tab.badge && <span className="tag is-info">{tab.badge.icon && <i className={`${tab.badge.icon} mr-2`}></i>}{tab.badge.text}</span>}
              {hasTabError[tab.name] && <span className="icon is-small ml-auto is-danger"><i className="fas fa-exclamation-triangle"></i></span>}
            </a>
          </li>
        ))}
      </ul>
    </aside>
  );
}