import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { QueryCache, QueryClient, QueryClientProvider, MutationCache } from '@tanstack/react-query'
import { AuthProvider, EventsProvider, StoreProvider } from './providers';
import { ToastContainer } from 'react-toastify';
import { toast } from "react-toastify";
import App from './App';
import reportWebVitals from './analytics/reportWebVitals.js';
import welcome from './analytics/welcome.js';
import './i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 12, // 12 hours
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: true, // Refetch data when the window regains focus? Default is true. Should be false in production?!
    }
  },
  queryCache: new QueryCache({
    onError: (error) => {
      console.error('QueryCache error', error);
      toast.error(`Something went wrong with a query!`);
    },
    onSuccess: (data) => {
      //console.log('QueryCache success', data);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      console.error('MutationCache error', error.message);
      toast.error(`Something went wrong updating data! ${error.message === '400' ? 'Please check your input!' : ''}`);
    },
  }),
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <EventsProvider>
        <BrowserRouter>
          <StoreProvider>
            <App />
            <ToastContainer position="bottom-left" />
          </StoreProvider>
        </BrowserRouter>
      </EventsProvider>
    </QueryClientProvider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// Send welcome message to console, in case there will be any console snoopers!
welcome();
