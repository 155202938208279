import React from 'react';
import { FloatLayout } from '@components';
import { Dashboard, Reports, TBA } from '@views/dashboard';
import { useTranslation } from "react-i18next";

const routes = (t) => [
  { icon: "chart-line", link: "", label: t("analytics.menu.overview"), element: <Dashboard /> },
  { icon: "clock", link: "hours", label: t("analytics.menu.hours"), element: <TBA label={t("analytics.menu.hours")} quote="tbaquote" /> },
  { icon: "sitemap", link: "projects", label: t("analytics.menu.projects"), element: <TBA label={t("analytics.menu.projects")} quote="tbaquote3" /> },
  { icon: "dollar-sign", link: "revenue", label: t("analytics.menu.revenue"), element: <TBA label={t("analytics.menu.revenue")} quote="tbaquote2" /> },
  { icon: "magnifying-glass-chart", link: "insights", label: t("analytics.menu.insights"), element: <TBA label={t("analytics.menu.insights")} quote="tbaquote4" /> },
  { icon: "chart-simple", link: "my-reports", label: t("analytics.menu.my_reports"), element: <Reports label={t("analytics.menu.my_reports")} quote="tbaquote5" /> },
];

export const DashboardTabs = () => {
  const { t } = useTranslation();
  return (
    <>
      <FloatLayout routes={routes(t)} />
    </>
  );
};
