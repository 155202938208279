import { max } from "date-fns";
import { getDurationMinutesInHoursMinutes, formatDateString } from "../../time/aux/tools";

export const template = (timeEntry, t) => {

  return [{
    name: t("manage.employees.details"), fields:
      [
        { name: 'name', label: t("common.full_name"), value: timeEntry?.data?.name || null, required: true },
      ]
  }];
}

export const tableHeaders = (t) => (
  [
    { key: 'name', label: t("common.name") },
    { key: 'date', label: t("common.date"), func: formatDateString },
    { key: 'durationMinutes', label: t("manage.time.duration"), func: getDurationMinutesInHoursMinutes },
    { key: 'projectName', label: t("manage.time.project") },
    { key: 'activityName', label: t("time.activity") },
    { key: 'commentPublic', label: t("time.public_comment"), func: (value) => value && value.length > 20 ? value.substring(0, 20) + '...' : value },
    { key: 'commentInternal', label: t("time.private_comment"), func: (value) => value && value.length > 20 ? value.substring(0, 20) + '...' : value },
  ]
);

