import React, { useEffect } from 'react';
import { useCalendar } from './provider';
import { useTranslation } from "react-i18next";
import { CalendarHeader } from './CalendarHeader';
import { CalendarBody } from './CalendarBody';
import { Stack, H1, Columns, Column } from '@components';

export const CalendarToday = () => {

  useEffect(() => {
    Selection.day.apply(Selection.selectedDate);
  }, []);

  const { Selection } = useCalendar();

  const { t } = useTranslation();

  return (
    <Stack.Begin>
      <Stack.Bar>
        <Columns>
          <Column>
            <H1>{t('common.hour_registration')}</H1>
          </Column>
          <Column className="is-3">
          </Column>
        </Columns>
      </Stack.Bar>
      <Stack.Bar>
        <CalendarHeader />
      </Stack.Bar>
      <Stack.Content>
        <CalendarBody dates={[Selection.from]} />
      </Stack.Content>
    </Stack.Begin>
  );
};