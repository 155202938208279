export const template = (customer, t) => {
  return [{
    name: 'Data', fields: [
      { name: "name", label: t("common.name"), value: customer?.data?.name, required: true },
      { name: "organizationNumber", label: t("manage.customers.org_no"), value: customer?.data?.organizationNumber, validator: 'orgnr' },
      { name: "address", label: t("common.address"), value: customer?.data?.address },
      { name: "email", label: t("common.email"), value: customer?.data?.email, validator: 'email' },
      { name: "phoneNumber", label: t("common.phone_no"), value: customer?.data?.phoneNumber, validator: 'phone' },
      { name: 'website', label: t("common.website"), validator: 'website', value: customer?.data?.website || '' },
    ]
  }]
}

export const tableHeaders = (t) => [
  { key: 'data.name', label: t("common.name") },
  { key: 'data.organizationNumber', label: t("manage.customers.org_no") },
  { key: 'data.address', label: t("common.address") },
  { key: 'data.email', label: t("common.email") },
  { key: 'data.phoneNumber', label: t("common.phone_no") }
];

