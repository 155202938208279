import React from 'react';
import { useTranslation } from "react-i18next";
export const PrivacyPolicy = () => {
    const { t } = useTranslation();

    return (
        <div className="content">
            <p>
                {`${t("info.privacy.info_text")}`}
            </p>

            <h2 className="subtitle">{`${t("info.privacy.header_1")}`}</h2>
            <h2 className="subtitle is-4">{`${t("info.privacy.header_11")}`}</h2><p>{`${t("info.privacy.text_11")}`}</p>
            <h2 className="subtitle is-4">{`${t("info.privacy.header_12")}`}</h2><p>{`${t("info.privacy.text_12")}`}</p>
            <h2 className="subtitle">{`${t("info.privacy.header_2")}`}</h2>
            <h2 className="subtitle is-4">{`${t("info.privacy.header_21")}`}</h2><p>{`${t("info.privacy.text_21")}`}</p>
            <h2 className="subtitle is-4">{`${t("info.privacy.header_22")}`}</h2><p>{`${t("info.privacy.text_22")}`}</p>
            <h2 className="subtitle is-4">{`${t("info.privacy.header_23")}`}</h2><p>{`${t("info.privacy.text_23")}`}</p>
            <h2 className="subtitle">{`${t("info.privacy.header_3")}`}</h2>
            <h2 className="subtitle is-4">{`${t("info.privacy.header_31")}`}</h2><p>{`${t("info.privacy.text_31")}`}</p>
            <h2 className="subtitle is-4">{`${t("info.privacy.header_32")}`}</h2><p>{`${t("info.privacy.text_32")}`}</p>
            <h2 className="subtitle is-4">{`${t("info.privacy.header_33")}`}</h2><p>{`${t("info.privacy.text_33")}`}</p>
            <h2 className="subtitle">{`${t("info.privacy.header_4")}`}</h2><p>{`${t("info.privacy.text_4")}`}</p>
            <h2 className="subtitle">{`${t("info.privacy.header_5")}`}</h2><p>{`${t("info.privacy.text_5")}`}</p>
            <h2 className="subtitle">{`${t("info.privacy.header_6")}`}</h2><p>{`${t("info.privacy.text_6")}`}</p>
            <h2 className="subtitle">{`${t("info.privacy.header_7")}`}</h2><p>{`${t("info.privacy.text_7")}`}</p>
            <h2 className="subtitle">{`${t("info.privacy.header_8")}`}</h2><p>{`${t("info.privacy.text_8")}`}</p>
        </div>
    );
}
