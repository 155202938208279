import { useEffect, useRef } from 'react';

//TODO: Necessary evil because of the bubble box. Need to find a better way to handle this, later!
export const useClickOutside = (callback) => {
  const ref = useRef(null);
  const isInitialMount = useRef(true); // Skip callback on initial mount. Need this if an event triggered ths hook on initial mount!!

  useEffect(() => {
    const handleClickOutside = (event) => {
      /*  console.log('useClickOutside: handleClickOutside', event, ref.current); */
      if (!isInitialMount.current && ref.current && event && !ref.current.contains(event.target)) {
        callback();
      }
      if (isInitialMount.current) {
        isInitialMount.current = false;
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [callback, ref]);

  return { ref };
};
