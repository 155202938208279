
import { Rectangle } from "recharts";
export const findWidth = (data, key) => {
  const max = Math.max(...data.map(item => item[key].toString().length));
  const width = max * 8 + 20; // 8px per digit, plus some padding
  return width;
}

export const adjustColor = (color, amount) => {
  return '#' + color.replace(/^#/, '').replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
}

//TODO: This is for dark mode! Need to have logic for light and dark theme for all charts
export const renderColorfulLegendText = (value, entry) => {
  /* 
    const { color } = entry;
   */
  return <span className="legend-color">{value}</span>;
};

export const CustomCursor = props => {
  const { x, y, fill, width, height, stroke } = props;
  return <Rectangle className='recharts-tooltip-cursor' fill={fill} stroke={stroke} x={x + (width / 2)} y={y} width={1} height={height} />;
};
