import './fader.scss';

import React from 'react';
import { v4 as uuidv4 } from "uuid";

export const FadeIn = ({ children, onAnimationEnd = () => { } }) => {
  return (
    <div key={`fade_${uuidv4()}`} className={`fade-in is-relative`} onAnimationEnd={onAnimationEnd}>
      {children}
    </div>
  );
};
