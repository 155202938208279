import React, { useEffect, useState, useCallback } from 'react';
import { validators, notEmpty } from './validators';
import { Switch } from '@components';
import { useTranslation } from "react-i18next";

export const FormFieldInput = ({ label, name, value, onChange, doValidation, hidden = false, description = null, disabled = false, placeholder = '', type = 'text', validator = null, required = false }) => {
  const [error, setError] = useState('');
  const { t } = useTranslation();
  const validation = validators(t);
  const validate = useCallback((element) => {
    let valid = true
    if (validation[validator] && element.target.value && notEmpty(element.target.value)) {
      valid = validation[validator].test(element.target.value);
    }
    const requiredValid = required ? element.target.value && notEmpty(element.target.value) : true;

    setError(valid ? (requiredValid ? '' : `${label} ${t("common.is_required")}`) : validation[validator].description);
  }, [label, required, validator])

  useEffect(() => {
    if (doValidation) {
      validate({ target: { value } });
    }
  }, [doValidation, validate, value]);

  return (
    <div className={`field ${hidden ? 'is-hidden' : ''}`}>
      <label className="label">{label}</label>
      <div className="control has-icons-right">
        {type === 'checkbox' ? (
          <Switch switchId={name} name={name} checked={value ? true : false} help onToggle={(id, event) => { validate(event); onChange(event, name, type); }} />
        ) : (
          <input
            className={`input ${hidden ? 'is-hidden' : ''}`}
            type={type}
            placeholder={placeholder}
            name={name}
            value={value ? value : undefined}
            onChange={(element) => { validate(element); onChange(element); }}
            required={required}
            disabled={disabled}
          />
        )}
        {(!error && value) && <span className="is-success icon is-small is-right"><i className="fas fa-check"></i></span>}
        {error && <span className="is-danger icon is-small is-right"><i className="fas fa-exclamation-triangle"></i></span>}
        {error && <p className="help is-danger">{error}</p>}
        {description && <p className="help is-info is-size-7"><i className="fa-solid fa-circle-info mr-2"></i>{description}</p>}
      </div>

    </div>
  );
};
