const local = {
    apiHost: 'http://localhost:3001',
    domain: 'timas-dev.eu.auth0.com',
    clientId: 'FwrOwjq5iuHFtGgZKLmwJv3FiQAZvWxm',
    audience: 'https://timas-dev.eu.auth0.com/api/v2/'
};

const prod = {
    apiHost: 'https://api.tim.as',
    domain: 'timas.eu.auth0.com',
    clientId: 'id1MR0FRlvP03p6J2TR8JDJTThYz51ci',
    audience: 'https://timas.eu.auth0.com/api/v2/'
};

const hostConfig = {
    'tim.as': prod,
    'localhost': local
};

const hostname = typeof window !== 'undefined' && window.location.hostname;

export default hostConfig[hostname] || {};