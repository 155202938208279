import React from 'react';

/* Simple screens, basic single page standalone screen */
import { LoadingAppScreen, LoginScreen, ErrorScreen, BlankScreen, OnboardScreen, MobileNotSupportedScreen } from '@screens';
/* Views are more complex composite screens, with layout, navbars etc */
import { RegularView } from './views';

import { useStore } from '@providers';

/*
Jikanoke web application -> see 'index.js' to see provider wrapping
Responsible for rendering the correct state. No routing here!
*/
function App() {
  const { states, colors, windowSize } = useStore();
  const {
    States,             //  Enum of all possible states
    currentState,       // Current state of the application
    user,               // 'Auth0 User' and 'Jikanoke User' => Combined User entity
  } = states;

  const { isLoaded } = colors;

  const stateScreens = {
    [States.AUTENTICATING]: <BlankScreen />,                    // Auth verification state, blank is most appropriate screen here 
    [States.NOT_AUTHENTICATED]: <LoginScreen />,                // User is verified as not authenticate
    [States.INITIAL_FETCH]: <LoadingAppScreen />,               // Jikanoke server initial loading state, which provides eye candy for the user
    [States.ONBOARDED]: <RegularView user={user} />,            // Continue to main application flow 
    [States.NOT_ONBOARDED]: <OnboardScreen user={user} />,      // User has no organization set up, whitch is a requirement. Fire up the onboarding wizard!
    [States.ERROR]: <ErrorScreen />,                            // Fatal error state, not able to connect to auth0 and/or Jikanoke backend
  };

  if (!isLoaded) return null; // Wait for css and colors to load!! Very important!!

  // TODO: Move to state
  if (windowSize.width < 1024 && currentState !== States.NOT_AUTHENTICATED) return <MobileNotSupportedScreen />

  return (<>{stateScreens[currentState]}</>);
}

export default App;
