import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Block } from '@components';
import poweroffice from '@assets/po-logo.svg';
import unieconomy from '@assets/unieconomy.svg'
import office from '@assets/247-logo.png'

export const Integrations = () => {
    const { t } = useTranslation();
    return (
        <div className="content">
            <blockquote>
                {t("manage.integrations.info")}
            </blockquote>
            <Block>
                {t("manage.integrations.available_integrations")}
            </Block>
            <div className="card">
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-48x48">
                                <img src={poweroffice} alt="PowerOffice logo" />
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="title is-4"> <Link to="/manage/integrations/poweroffice">{t("manage.integrations.activate_here")}</Link></p>
                            <p className="subtitle is-6"><a href="https://poweroffice.no">{`PowerOffice Go ${t("common.website")}`}</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <Block />
            <Block>
                {t("manage.integrations.request")}
            </Block>
            <div className="card is-narrow">
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-48x48">
                                <img src={unieconomy} alt="Softrig logo" />
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="title is-4">{t("manage.integrations.request")}</p>
                            <p className="subtitle is-6"><a href="https://softrig.com">{`UniMicro ${t("common.website")}`}</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <Block />
            <div className="card is-narrow">
                <div className="card-content">
                    <div className="media">
                        <div className="media-left">
                            <figure className="image is-48x48">
                                <img src={office} alt="247 Offiice logo" />
                            </figure>
                        </div>
                        <div className="media-content">
                            <p className="title is-4">{t("manage.integrations.request")}</p>
                            <p className="subtitle is-6"><a href="https://24sevenoffice.com">{`24Seven Office ${t("common.website")}`}</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
