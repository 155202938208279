import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { getFirstAndLastWeekdays, getWorkingDaysBetweenDatesFormat } from '@views/time/aux/tools';
import { useStore } from '@providers';

//TODO: Refactor needed

const activities = (timeentries, activityIds) => timeentries.filter(({ activityId }) => activityId && activityIds.includes(activityId)).reduce((acc, item) => acc + item.durationMinutes, 0) / 60;
const loggedTime = (timeentries) => timeentries.reduce((acc, item) => acc + item.durationMinutes, 0);
const availabletime = (from, to) => getWorkingDaysBetweenDatesFormat(from, to).length * 7.5;

const getStatistics = (t, timeentries, illnessDaysActivitiesIds, vacationDaysActivitiesIds, from, to, holidays) => {
  const sickdays = activities(timeentries, illnessDaysActivitiesIds) / 7.5;
  const vacation = activities(timeentries, vacationDaysActivitiesIds) / 7.5;
  const logged = loggedTime(timeentries) / 60;
  const available = availabletime(from, to);
  const billableRate = timeentries.filter(({ isBillable }) => isBillable).reduce((acc, item) => acc + item.durationMinutes, 0) / 60;

  return {
    sickdays,
    vacation,
    logged,
    available,
    billableRate,
    remaining: available - logged,
    vacationRemaining: Math.round(holidays - vacation),
    name: t('common.months.' + from.getMonth()),
    billingRateCompany: 75 + (Math.random() * 25),
  };
}

export const useStatistics = (Data, Selection, Timeentries) => {

  const { user } = useStore().states;
  //TODO: Refactor needed, super single tenant code
  const holidays = user.organizations[0].orgdata.holidays;
  const { t } = useTranslation();
  const [isReady, setIsReady] = useState(false); // False on hard reload and page navigation using url
  const [financialYear, setFinancialYear] = useState({
    sickdays: 0,
    vacation: 0,
    logged: 0,
    available: 0,
    billableRate: 0,
    months: []
  });
  const [soFar, setSoFar] = useState({
    sickdays: 0,
    vacation: 0,
    logged: 0,
    available: 0,
    billableRate: 0,
    months: []
  });
  const [selection, setSelection] = useState({
    sickdays: 0,
    vacation: 0,
    logged: 0,
    available: 0,
    billableRate: 0,
    months: []
  });


  const init = useCallback(() => {

    const vacationDaysActivitiesIds = Data.activitiesData.filter(activity => activity.data.type === "vacation").map(activity => activity.id);
    const illnessDaysActivitiesIds = Data.activitiesData.filter(activity => activity.data.type === "illness").map(activity => activity.id);

    let statisticsPerMonth = [];
    let currentDateIterator = new Date(new Date(Selection.selectedDate.getFullYear(), 0, 1));
    let i = 0;
    while (i <= 12) {
      const { firstDate, lastDate } = getFirstAndLastWeekdays(new Date(currentDateIterator));
      const days = Timeentries.getDateItemsBetween(firstDate, lastDate, true);
      const statistics = getStatistics(t, days, illnessDaysActivitiesIds, vacationDaysActivitiesIds, firstDate, lastDate, holidays);
      statisticsPerMonth.push(statistics);
      currentDateIterator = new Date(currentDateIterator.getFullYear(), currentDateIterator.getMonth() + 1, 1);
      i++;
    }

    setFinancialYear({
      sickdays: statisticsPerMonth.reduce((acc, item) => acc + item.sickdays, 0),
      vacation: statisticsPerMonth.reduce((acc, item) => acc + item.vacation, 0),
      vacationRemaining: Math.round(holidays - statisticsPerMonth.reduce((acc, item) => acc + item.vacation, 0)),
      logged: statisticsPerMonth.reduce((acc, item) => acc + item.logged, 0),
      available: statisticsPerMonth.reduce((acc, item) => acc + item.available, 0),
      billableRate: statisticsPerMonth.reduce((acc, item) => acc + item.billableRate, 0),
      months: statisticsPerMonth
    });
    const currentDate = new Date();
    const statistics = getStatistics(t, Timeentries.getDateItemsBetween(Selection.from, currentDate, true), illnessDaysActivitiesIds, vacationDaysActivitiesIds, Selection.from, currentDate, holidays);

    setSoFar({ ...statistics, hourBalance: statistics.logged - statistics.available });

    const d = getStatistics(t, Timeentries.getDateItemsBetween(Selection.from, Selection.to, true), illnessDaysActivitiesIds, vacationDaysActivitiesIds, Selection.from, Selection.to, holidays);
    setSelection({ ...d, hourBalance: selection.logged - selection.available, from: Selection.from, to: Selection.to });
    setIsReady(true);
  }, [Data.activitiesData, Timeentries.getDateItemsBetween, Selection.from, Selection.to, t]);

  useEffect(() => {
    setIsReady(false);
    if (Timeentries.isReady && !Data.isLoading && Data.activitiesData) {
      init();
    }
  }, [Timeentries.dateItems, Data.activitiesData, Data.isLoading, Timeentries.isReady, Selection.from, Selection.to]);

  return {
    hasData: Timeentries.dateItems.size > 0,
    isReady: isReady && Timeentries.dateItems.size > 0,
    financialYear,
    soFar,
    selection,
    rules: {
      vacationAllowed: holidays,
    }
  }
}