import React, { useEffect, useState, createContext, useContext } from 'react';
import { toast } from 'react-toastify';
import { useSocket } from '@hooks';
import { useTranslation } from "react-i18next";

export const EventsContext = createContext();

export const EventsProvider = ({ children }) => {
  const { socket, isConnected, attempts } = useSocket("00000000-0000-0000-0000-000000000000");
  const { t } = useTranslation();

  const [event, setEvent] = useState(null);

  useEffect(() => {
    if (!socket) {
      return;
    }

    // Listen to events channel
    socket.on('events', (data) => {
      // Handle received events
      console.log(data);
      setEvent(data);

    });

    socket.on('welcome', (data) => {
      // Handle received events
      console.log(data);
      setEvent(data);
    });

    // Clean up the socket connection on component unmount
    return () => {
      socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    if (!isConnected && attempts === 1) {
      toast.dismiss();
      toast.warn(t("screens.offline"));
    }
    else if (attempts > 1) {
      toast.dismiss();
      toast.warn(`${t("screens.reconnect")} ${attempts}`);
    }
    else if (isConnected && attempts === 0) {
      toast.dismiss();
      toast.success(t("screens.online"));

    }
  }, [isConnected, socket, attempts]);

  useEffect(() => {
    if (event) {
      toast[event?.level || 'success'](event.message);
      if (event?.type === 'TOKEN_UPDATED') {
        clearAuth0Tokens();
      }
    }
  }, [event]);

  const clearAuth0Tokens = () => {
    console.log("Clearing auth0 tokens");
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.includes('auth0')) {
        localStorage.removeItem(key);
      }
    }
  }

  return (
    <EventsContext.Provider value={{ socket, event, setEvent }}>
      {children}
    </EventsContext.Provider>
  );
};

export const useEventsContex = () => useContext(EventsContext);
