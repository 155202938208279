import React from 'react';
import { FadeIn } from '../animations/FadeIn'

export const ConfirmButton = ({ text, onConfirm, onCancel }) => {
  return (
    <FadeIn>
      <div className="columns is-vcentered">
        <div className='column auto has-text-danger'>
          {text}
        </div>
        <div className='column is-narrow'>
          <div className="buttons">
            <button className="button" onClick={onConfirm}>
              <span className="icon is-small has-text-success">
                <i className="fas fa-check"></i>
              </span>
            </button>
            <button className="button" onClick={onCancel}>
              <span className="icon is-small ">
                <i className="fa-solid fa-xmark"></i>
              </span>
            </button>
          </div>

        </div>
      </div>
    </FadeIn>
  )
}
