export const template = (role, t) => {

  return [{
    name: 'Data', fields: [
      { name: "name", label: t("common.name"), value: role?.name || '', required: true },
      { name: 'access', label: t("manage.roles.access"), type: 'text', value: role?.data?.access || null, required: true },
      { name: 'permissions', label: t("manage.roles.permissions"), type: 'checkbox', value: role?.data?.permissions || false },
    ]
  }];
}

export const tableHeaders = (t) => [
  { key: 'name', label: t("common.name") },
  { key: 'data.access', label: t("manage.roles.access_level") },
  { key: 'data.permissions', label: t("manage.roles.permission_level") }
]

