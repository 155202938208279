import React from 'react';
export const Level = ({ heading, title, titleSize, headingSize, titleIcon, headingIcon, onClick, tooltip, isActive, className, sublevels }) => {
  return (
    <div className={`tooltip ${className || ''}`}>
      <div className={`level-item has-text-centered`} >
        <div onClick={onClick} className={`${onClick ? 'is-link' : ''} ${isActive ? 'is-active' : ''}`}>
          <p className={`heading ${headingSize || ''}`}>
            {heading}
            {headingIcon && <i className={`icon fas fa-${headingIcon}`}></i>}
          </p>
          <div >
            <p className={`title ${titleSize ? titleSize : ''}`}>{title}
              {titleIcon && <i className={`icon fas fa-${titleIcon}`}></i>}
              {sublevels && <div className="sublevels"><i className={`icon fas fa-ellipsis`}></i></div>}
            </p>
          </div>
        </div>
      </div>
      {tooltip && <span className='tooltip-text'>{tooltip}</span>}
    </div>
  )
}

export const Levels = ({ levels, className }) => {
  return (
    <nav className={`level ${className ? className : ''}`}>
      {levels.map((props, index) => (<Level key={`l_${index}`} {...props} />))}
    </nav>
  )
}