import React, { useState, useRef } from 'react';
import { Canvas, Node, Edge, Port, MarkerArrow, Label } from 'reaflow';
import { useStore } from '@providers';
import { POTs, Admins, Consultants } from './data';
import './flow.scss';


const nodes = [
  {
    id: 'Rainfall',
    text: 'Rainfall',
    data: {
      employees: []
    },
    width: 200,
  },
  {
    id: 'Admin',
    text: 'Administrasjon',
    data: {
      employees: Admins,
    },
    width: 200,
  },
  {
    id: 'POT',
    text: 'POT',
    data: {
      employees: POTs,
    },
    width: 200,
  },
  {
    id: 'Konsulent',
    text: 'Konsulent',
    data: {
      employees: Consultants,
    },
    width: 200,
  }
];

const potNodes = POTs.map((name, index) => ({ id: `POT-${index}`, text: name }));
const adminNodes = Admins.map((name, index) => ({ id: `Admin-${index}`, text: name }));
const konsulentNodes = Consultants.map((name, index) => ({ id: `Konsulent-${index}`, text: name }));

const allNodes = [...nodes];


const edges = [
  {
    id: 'Rainfall-POT',
    from: 'Rainfall',
    to: 'POT'
  },
  {
    id: 'Rainfall-Konsulent',
    from: 'Rainfall',
    to: 'Konsulent'
  },
  {
    id: 'Rainfall-Admin',
    from: 'Rainfall',
    to: 'Admin'
  }
];

const potEdges = potNodes.map((node) => ({ id: `POT-${node.id}`, from: 'POT', to: node.id }));
const adminEdges = adminNodes.map((node) => ({ id: `Admin-${node.id}`, from: 'Admin', to: node.id }));
const konsulentEdges = konsulentNodes.map((node) => ({ id: `Konsulent-${node.id}`, from: 'Konsulent', to: node.id }));
const allEdges = [...edges];

export const Flow = () => {
  const { palette } = useStore().colors;
  const [zoom, setZoom] = useState(0.7);
  const ref = useRef(null);
  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
        <Canvas
          maxZoom={0.2} minZoom={-0.9} zoom={zoom} ref={ref}
          maxWidth={1200} // Use small canvas dimensions to make sure the nodes appear on screen immediately
          maxHeight={2000}
          fit={true}
          nodes={allNodes}
          edges={allEdges}
          pannable={true}
          node={(node) => <Node
            {...node}
            className='node'
            onClick={() => console.log(node.properties.data)}
            style={{ stroke: '#1a192b', fill: 'white', strokeWidth: 1, }}
            label={<Label style={{ fill: 'black' }} />}
            port={<Port style={{ fill: 'blue', stroke: 'white' }} rx={10} ry={10} />}
          >
            <foreignObject id='node-foreignObject-2ca8efe0-75ed-11eb-8896-7bd259f8797e' class='information-node-container node-container css-1ncbppe-BaseNode' width='200' height='100'>
              <div className='node-content'>
                <div className='is-flex is-flex-direction-column is-justify-content-space-between'>
                  {node.properties.data.employees.map((employee, index) => <span key={index}>{employee}</span>)}
                </div>
              </div>
            </foreignObject>

          </Node>
          }
          arrow={<MarkerArrow style={{ fill: '#b1b1b7' }} />}
          edge={<Edge className="edge" />}
        />
      </div>
    </div>

  )
};