/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import config from '../config';

export const AuthProvider = ({ children }) => {
    return (
        <Auth0Provider
            domain={config.domain}
            clientId={config.clientId}
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: `${window.location.origin}`,
                audience: `${config.audience}`,
                scope: "openid profile email"
            }} >
            {children}
        </Auth0Provider>
    )
}

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <a onClick={() => loginWithRedirect()} href="#"><span className='curly'>{'{'}</span> enter <span className='curly'>{'}'}</span></a>;
};

export const LogoutAnchor = () => {
    const { t } = useTranslation();
    const { logout } = useAuth0();

    const logoutWithRedirect = () => {

        localStorage.removeItem("organization");
        logout({ logoutParams: { returnTo: window.location.origin } })
    }

    return (
        <a className='has-text-link' onClick={logoutWithRedirect} href="#">
            {`${t("common.logout")}`}
        </a>
    );
};
