import React from 'react';
import { useNavigate } from 'react-router';

import { StackLayout } from '@components';
import { Notifications, User } from '@views/account';
import { Connections } from './Connections';
import { Privacy } from './Privacy';
import { AccountSettings } from './Account';
import { TermsOfService } from './TermsOfService';
import { useTranslation } from "react-i18next";

export const AccountMenu = () => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const routes = [
    { icon: "user", link: "profile", label: `${t("account.left_menu.your_profile")}`, element: <User /> },
    { icon: "bell", link: "notifications", label: `${t("account.left_menu.notifications")}`, element: <Notifications /> },
    { icon: "cloud", link: "connections", label: `${t("account.left_menu.connected_accounts")}`, element: <Connections /> },

    { icon: "key", link: "options", label: `${t("common.account")}`, element: <AccountSettings /> },
    /*   { icon: "eject", link: "logout", label: `${t("common.logout")}`, element: <LogoutPanel /> }, */
    {
      icon: "handshake", newTitle: `${t("common.terms_and_policies")}`, link: "terms-of-service", label: `${t("common.terms-of-service")}`, element: <TermsOfService />
    },
    { icon: "shield", link: "privacy", label: `${t("account.left_menu.privacy")}`, element: <Privacy /> }
  ];
  const levels = routes.map(({ icon, link, label }) => ({ heading: label, titleIcon: icon, onClick: () => navigate(link), link }));
  return (
    <StackLayout className="is-max-desktop" title={`${t("account.account_settings")}`} routes={routes} levels={levels} />
  );
};

/*
Account Page: An account page typically focuses on managing the user's account settings and preferences. It provides functionality related to authentication, security, and account management. Some common features you might find on an account page include:
In a web application, the account settings area typically provides users with the ability to manage and customize their personal account information and preferences. Here are some common features and options found in account settings:

Profile Information: Users can view and edit their profile details such as name, email address, profile picture, and other relevant personal information.

Password Management: Users can update their password, change their current password, or set up a new password if they've forgotten it. This section may also include options for enabling two-factor authentication or password recovery methods.

Notification Preferences: Users can customize their notification settings, such as choosing the types of notifications they want to receive (e.g., email notifications, push notifications), setting the frequency of notifications, or managing specific preferences for different types of notifications.

Privacy and Security: Users can control their privacy settings, choosing what information is visible to others and what is kept private. They may also have options to manage their account security settings, such as managing connected devices, reviewing login history, or revoking access to third-party integrations.

Email Preferences: Users can manage their email preferences, including opting in or out of marketing communications, newsletters, or other promotional emails.

Communication Settings: Users can manage their preferences for communication within the web application, such as selecting preferred language, timezone, or any other customization options related to the user interface.

Account Deactivation or Closure: Users may have the option to deactivate or close their account if they no longer wish to use the application. This section may include information on the consequences of closing the account and any necessary steps to be taken.

Connected Accounts: Users can manage their connected accounts or third-party integrations, such as social media accounts or other platforms linked to their web application account.

Billing and Subscriptions: If the web application offers paid features or subscriptions, users can manage their billing information, view invoices, update payment methods, or cancel their subscription.

Help and Support: Users may have access to support resources, frequently asked questions (FAQs), or a help center where they can find assistance or contact customer support.
*/
