import { useEffect, useState } from 'react';

export const useMouseTracker = (delay) => {
  const [isMouseIdle, setIsMouseIdle] = useState(false);

  useEffect(() => {
    let mouseMoveTimer;

    const handleMouseMove = () => {
      setIsMouseIdle(false);

      // Reset the timer
      clearTimeout(mouseMoveTimer);

      // Set a new timer to hide the mouse pointer after 3 seconds
      mouseMoveTimer = setTimeout(() => {
        setIsMouseIdle(true);
      }, delay);
    };

    // Add event listener for mouse movements
    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      // Clean up event listener on hook cleanup
      window.removeEventListener('mousemove', handleMouseMove);
      clearTimeout(mouseMoveTimer);
    };
  }, [delay]);

  return isMouseIdle;
};
