import React, { useEffect, useState } from 'react';

export const useKeyPress = (targetKeys, delay = 70) => {
  const [keyStates, setKeyStates] = useState({});

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  const downHandler = debounce(({ key }) => {
    if (targetKeys.includes(key)) {
      setKeyStates((prevState) => ({ ...prevState, [key]: true }));
    }
  }, delay);

  const upHandler = debounce(({ key }) => {
    if (targetKeys.includes(key)) {
      setKeyStates((prevState) => ({ ...prevState, [key]: false }));
    }
  }, delay);

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [downHandler, upHandler]);

  return keyStates;
};
