import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StackLayout, TimeRegister } from '@components';
import { Integrations } from './Integrations';
import { PowerOffice } from './PowerOffice';
import { Projects } from './projects';
import { Customers } from './customers';
import { Reports } from './Reports';
import { Employees } from './employees';
import { Teams } from './Teams';
import { Organization } from './organizations';
import { Activities } from './activities';
import { Departments } from './departments';
import { Users } from './users';
import { Roles } from './roles';
import { Presentations } from './Presentations';
import { Calendar } from './Calendar';
import { Rates } from './Rates';
import { Positions } from './positions';
import { ManageProvider, useManage } from './ManageProvider';
import { useTranslation } from "react-i18next";
import { TimeEntries } from "./time";

import { useLocation } from 'react-router-dom';

export const Routes = () => {
  const { t } = useTranslation();

  return [
    {
      icon: "sitemap", link: "projects", label: `${t("common.projects")}`, element: <Projects />
    },
    {
      icon: "user-tie", link: "customers", label: `${t("manage.menu.customers")}`, element: <Customers />, width: 'is-max-desktop'
    },
    {
      icon: "users", link: "employees", label: `${t("manage.menu.employees")}`, element: <Employees />
    },
    {
      icon: "people-group", link: "teams", label: `${t("manage.menu.teams")}`, element: <Teams />,
    },
    {
      icon: "chart-simple", link: "reports", label: `${t("manage.menu.reports")}`, element: <Reports />,
    },
    {
      icon: "rocket", link: "presentations", label: `${t("manage.menu.presentations")}`, element: <Presentations />
    },
    {
      icon: "calendar", link: "calendar", label: `${t("manage.menu.calendar")}`, element: <Calendar />,
    },
    {
      icon: "clock", link: "entries", label: `${t("common.time")}`, element: <TimeEntries />,
    },
    {
      icon: "building", newTitle: `${t("manage.menu.your_org")}`, noCollapse: true, link: "organization", label: `${t("manage.menu.org")}`, element: <Organization />,
      subroutes: [
        { icon: "building-user", link: "organization/departments", label: `${t("manage.menu.departments")}`, element: <Departments /> },
        { icon: "hat-wizard", link: "organization/roles", label: `${t("manage.menu.roles")}`, element: <Roles /> },
        { icon: "users", link: "organization/users", label: `${t("manage.menu.users_and_access")}`, element: <Users /> },
        { icon: "briefcase", link: "organization/activities", label: `${t("manage.menu.activities")}`, element: <Activities /> },
        { icon: "sack-dollar", link: "organization/rates", label: `${t("manage.menu.rates")}`, element: <Rates /> },
        { icon: "percent", link: "organization/positions", label: `${t("manage.menu.positions")}`, element: <Positions /> }
      ]
    },
    {
      icon: "plug", newTitle: "Data sync", link: "integrations", label: `${t("manage.menu.integrations")}`, element: <Integrations />,
      subroutes: [{ icon: "plug-circle-plus", link: "integrations/poweroffice", label: "PowerOffice Go", element: <PowerOffice /> }]
    },
  ];
}

export const ManageTabs = () => {
  return (
    <>
      <StackLayout routes={Routes()} />
    </>
  );
};

const ManageMenuContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { TimeEntryManage } = useManage();
  const location = useLocation();
  if (TimeEntryManage.TimeentriesForm.isFormOpen && !location.pathname.includes('entries')) {
    TimeEntryManage.TimeentriesForm.closeForm();
  }
  const mapper = ({ icon, link, label, subroutes }) => {
    if (subroutes) {
      const routes = subroutes.map(mapper).map(({ ...rest }) => ({ titleSize: "is-5", ...rest }));
      return { heading: label, titleIcon: icon, onClick: () => navigate(link), link, subroutes: routes, sublevels: true }
    }
    return { heading: label, titleIcon: icon, onClick: () => navigate(link), link }
  };
  const levels = Routes().map(mapper);
  return (
    <StackLayout title={`${t("manage.menu.manage")}`} routes={Routes()} narrow={TimeEntryManage.TimeentriesForm.isFormOpen} breadcrumbs={false} levels={levels}>
      <TimeRegister className={`${TimeEntryManage.TimeentriesForm.isFormOpen ? '' : 'is-hidden'}`} selectedDate={TimeEntryManage.Selection.selectedDate} Timeentries={TimeEntryManage.Timeentries} TimeentriesForm={TimeEntryManage.TimeentriesForm} Data={TimeEntryManage.Data} />
    </StackLayout>
  );
}

export const ManageMenu = () => {

  return (
    <ManageProvider>
      <ManageMenuContent />
    </ManageProvider>
  );
};
