import React from 'react'
/* import { default as ReactSelect } from 'react-select'; */

export const Select = ({ id, items, selected, onItemClick }) => {
  return (
    <>
      <div className="control">
        <div className="select is-fullwidth">
          <select value={selected} onChange={(event) => onItemClick(id, event.target.value)}>
            {items.map(item => (
              <option key={`select_${item.value}`} value={item.value}>
                {item.name}
              </option>
            ))}
          </select>
          {/*  <ReactSelect options={items} /> */}
        </div>
      </div>
    </>
  )
};