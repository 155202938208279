
import React, { createContext, useContext } from 'react';
import { useColors, useAppStates, useWindowResize, useFullscreen } from '@hooks';
const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const colors = useColors();
  const states = useAppStates();
  const windowSize = useWindowResize();
  const fullscreen = useFullscreen();

  return (
    <StoreContext.Provider value={{ colors, states, windowSize, fullscreen }}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
