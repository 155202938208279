import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "@components";
import { useStore } from "@providers";
import { useSlides } from "./SlidesProvider";
import { useTranslation } from "react-i18next";

export const Controls = () => {
  const { isFullscreen, toggleFullscreen } = useStore().fullscreen;
  const { toggleTheme } = useStore().colors;
  const { logout } = useAuth0();
  const { modes, updateMode } = useSlides();
  const { t } = useTranslation();
  const toggleMode = (mode) => () => {
    if (mode === 'edit' && modes.edit === false) {
      return updateMode({ 'edit': true, 'menu': true, 'menuHeadings': true });
    }
    updateMode({ [mode]: !modes[mode] });
  }

  return (
    <div className="container">
      <Tooltip text={isFullscreen ? t("analytics.presentation.exit_fullscreen") : t("analytics.presentation.enter_fullscreen")} up delay={0}><button className="button is-ghost is-icon  is-selected" onClick={toggleFullscreen}><span className='icon'><i className={`fas fa-${isFullscreen ? 'minimize' : 'expand'}`}></i></span></button></Tooltip>
      <Tooltip text={modes.edit ? t("analytics.presentation.exit_edit") : t("analytics.presentation.edit_mode")} up delay={0}><button className="button is-ghost is-icon  is-selected mr-2" onClick={toggleMode('edit')}><span className='icon' ><i className="fa-solid fa-pen-to-square"></i></span></button></Tooltip>
      <Tooltip text={t("analytics.presentation.toggle_menu_title")} up delay={0}><button className="button is-ghost is-icon is-selected mr-2" onClick={toggleMode('menuHeadings')}><span className='icon' ><i className="fa-solid fa-heading"></i></span></button></Tooltip>
      <Tooltip text={modes.menu ? t("analytics.presentation.hide_menu") : t("analytics.presentation.show_menu")} up delay={0}><button className="button is-ghost is-icon is-selected mr-2" onClick={toggleMode('menu')}><span className='icon' >{modes.menu ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}</span></button></Tooltip>
      <Tooltip text={modes.animation ? t("analytics.presentation.disable_animation") : t("analytics.presentation.enable_animation")} up delay={0}><button className="button is-ghost  is-icon is-selected mr-2" onClick={toggleMode('animation')}><span className='icon' ><i className="fa-solid fa-bolt"></i></span></button></Tooltip>
      <Tooltip text={t("analytics.presentation.trigger_confetti")} up delay={0}><button className={`button is-ghost is-icon is-selected mr-2 ${modes.confetti ? 'is-loading' : ''}`} onClick={toggleMode('confetti')}><span className='icon' ><i className="fa-solid fa-burst"></i></span></button></Tooltip>
      <Tooltip text={t("analytics.presentation.toggle_mode")} up delay={0}><button className={`button is-ghost is-icon is-selected mr-2`} onClick={toggleTheme}><span className='icon' ><i className="fa-solid fa-circle-half-stroke"></i></span></button></Tooltip>
      <Tooltip text={t("analytics.presentation.logout")} up delay={0}><button className="button is-ghost  is-icon is-selected mr-2" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}><span className='icon' ><i className="fa-solid fa-right-from-bracket"></i></span></button></Tooltip>
    </div>
  )
}
