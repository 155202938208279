import React from 'react';
import { useElementSize } from '@hooks';
import { dayNames, isDateToday, areDatesEqual, formatDate } from './aux';
import { useTranslation } from "react-i18next";
import { useCalendar } from './provider';
import { CalendarContentHeader, CalendarContentItems, } from './aux'

export const CalendarBody = ({ dates }) => {

  const { size, elementRef } = useElementSize();
  const { t } = useTranslation();
  const dayNamesShort = dayNames(t);

  const { TimeentriesForm, Timeentries, Selection } = useCalendar();

  const days = Timeentries.getDateItemsBetween(Selection.from, Selection.to);

  const getItemsForDate = (date) => days.has(date) ? days.get(date) : [];

  const addItemToDay = (day) => (event) => {
    if (day instanceof Date) {
      Selection.setSelectedDate(day);
      TimeentriesForm.openForm();
    }
  };

  const edit = day => (event, item) => {
    Selection.setSelectedDate(day);
    setTimeout(() => TimeentriesForm.editItem(item), 100);
  }

  const itemsPerColumn = 7;
  const columns = Math.ceil(dates.length / itemsPerColumn);
  return (
    <>
      <div className="columns m-0 has-text-centered mt-4">
        {dayNamesShort.map((name, index) => (
          <div ref={elementRef} key={`c_${index}`} className={`column is-gapless has-border-top has-border-left has-border-bottom ${index === 6 ? 'has-border-right' : ''}`}>
            {name}
          </div>
        ))}
      </div>
      {Array.from({ length: Math.min(columns, 5) }, (_, index) => (
        <div key={`cal_${index}`} className="columns m-0 has-border-left has-border-bottom calendar-cell">
          {/* Loop through the items in the current column */}
          {dates.slice(index * itemsPerColumn, (index + 1) * itemsPerColumn).map((item, itemIndex) => {
            const items = item ? getItemsForDate(formatDate(item)) : {};
            return (
              <div key={`cal_${index}-${itemIndex}`}
                id={`cal_${index}-${itemIndex}`}
                className={`column m-0 p-0 is-gapless has-border-right ${item ? 'hour-register' : ''} ${areDatesEqual(item, Selection.selectedDate) && TimeentriesForm.isFormOpen ? 'is-active' : ''} ${item && isDateToday(item) ? 'border-full' : ''}`} onClick={addItemToDay(item)}>
                {item &&
                  <>
                    <CalendarContentHeader date={item} total={items.reduce((acc, it) => acc + it.durationMinutes, 0) / 60} />
                    <CalendarContentItems date={item} items={items} onClick={edit(item)} width={size.width} max={Selection.view === 'month' ? 2 : undefined} />
                  </>
                }
              </div>
            )
          })}
        </div>
      ))
      }
    </>
  );
};
