import logo from '@assets/paw_white_600dpi.png';
import { ReactComponent as Jikanoke } from '@assets/jikanoke.svg';
import { TIM } from '@components/animations';

export const demoData = (username) => ({
  id: 1,
  title: 'Presentation',
  showTitles: false,
  page: {
    animationIn: "animate__backInDown",
    animationOut: "animate__backOutDown",
  },
  pages: [
    {
      title: 'Tim',
      label: 'Tim',
      icon: "rocket",
      slides: [
        { svg: <div style={{ width: '30%' }}><TIM /></div> },
        { content: "Tim er et verktøy, utviklet av en timefører", plot: true },
        { content: "For å holde rede på tid, prosjekter og kollegaer", plot: true },
        { content: "I et lekent, finurlig, og sexy system", particles: true },
      ]
    },
    {
      title: 'Funksjoner',
      label: 'Funksjoner',
      icon: "chart-simple",
      slides: [
        { content: "Enkel oversikt over avspasering, ferie, sykefrefravær", icon: { animation: { type: 'animate__pulse' }, type: "fa-solid fa-gauge" } },
        { content: "Få varslinger og  morsomheter tilsendt til din favoritt-kanal", icon: { animation: { type: 'animate__pulse' }, type: "fa-solid fa-paper-plane" } },
        { content: "Lag dine egne rapporter og presentasjoner med data fra Tim", icon: { animation: { type: 'animate__pulse' }, type: "fa-solid fa-fire" } },
        { content: "Enkel administrering av kunder, ansatte, prosjekter, aktiviteter, priser og timeføring", icon: { animation: { type: 'animate__jello' }, type: "fa-solid fa-puzzle-piece" } },
      ]
    },
    {
      title: 'Timeføring',
      label: 'Timeføring',
      icon: "calendar-days",
      slides: [
        { content: "Håper verktøyet kan bidra til en sterk timeføringsvane!", icon: { animation: { type: 'animate__flip', speed: 'animate__slow' }, type: "fa-regular fa-face-smile-wink" } },
      ]
    },
    {
      title: 'Velkommen',
      label: 'Velkommen',
      icon: "gift",
      slides: [
        { content: "Velkommen skal du være, " + username, confetti: true, icon: { animation: { type: 'animate__shakeY', speed: 'animate__slow' }, type: "fa-solid fa-handshake" } },
        /*  {
           levels: [
             { heading: `Abonnement Mnd`, title: `${subsriptionMonth} kr`, tooltip: `` },
             { heading: `Per bruker Mnd`, title: `${perUser} kr`, tooltip: `` },
             { heading: `Bedrifter`, title: companies, tooltip: `` },
             { heading: `Gj. Ansatte`, title: usersAvg, tooltip: `` },
             { heading: `Omsetning Mnd`, title: `${perMonth}`, tooltip: `` },
             { heading: `Omsetning År`, title: `${perMonth * 12}`, tooltip: `` },
           ]
         } */
      ]
    },
  ]
});