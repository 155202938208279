import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Breadcrumbs = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const paths = location.pathname.split('/').filter(path => path !== '');

  const getTranslation = (path) => {
    return t("common." + path);
  }

  return (
    <nav className="breadcrumb has-border-bottom pb-2" aria-label="breadcrumbs">
      <ul>
        <li>
          <Link to="/">{t("common.analytics")}</Link>
        </li>
        {paths.map((path, index) => (
          <li key={index}>
            <Link to={`/${paths.slice(0, index + 1).join('/')}`} className="is-capitalized">{getTranslation(path)}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
