import React from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, Rectangle } from 'recharts';
import { findWidth, renderColorfulLegendText } from './tools';

const CustomCursor = props => {
  const { x, y, fill, width, height, stroke } = props;
  return <Rectangle className='recharts-tooltip-cursor' fill={fill} stroke={stroke} x={x + (width / 2)} y={y} width={1} height={height} />;
};

export const BarPlot = ({ data, dataKeys, palette, switches, vertical, yx }) => {
  const width = findWidth(data, 'name');

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart layout={`${vertical ? 'vertical' : 'horizontal'}`} data={data} margin={{
        top: 5,
        right: 0,
        left: 0,
        bottom: 5,
      }}>
        {vertical &&
          <>
            {(switches.yx || yx) && <YAxis width={width} dataKey="name" type="category" />}
            <XAxis type="number" />
          </>}
        {!vertical &&
          <>
            {(switches.yx || yx) && <YAxis />}
            <XAxis dataKey="name" type="category" />
          </>}

        <Tooltip cursor={<CustomCursor />} />
        <Legend iconSize={20} formatter={renderColorfulLegendText} />
        {dataKeys.map(({ key, name }, index) => (<Bar key={`${key}_${name}`} name={name} dataKey={key} fill={Object.values(palette)[index]} />))}
      </BarChart>
    </ResponsiveContainer>
  );
};
