import React from 'react';
import { TabForm } from '@components';
import { useTranslation } from "react-i18next";
import { useManage } from '../ManageProvider';
import { template } from './template';
import { Stack } from '@components';

export const Organization = () => {
    const { flags, reset, organizations, organizationData, countryOptions, isLoading, isError } = useManage();
    const { t } = useTranslation();
    const update = organizations.useUpdate();

    if (isLoading || isError) return null;

    const data = template(countryOptions, organizationData, t);

    const onEdit = (data) => {
        const updateData = { ...data, data: data.data, id: organizationData.id, version: organizationData.version };
        update.mutate(updateData, { onSuccess: reset });
    }

    return (
        <Stack.Begin>
            <Stack.Bar>
                <h1 className="title">{t("manage.menu.org")}</h1>
            </Stack.Bar>
            <Stack.Content>
                {flags.view && <TabForm data={data} onSubmit={onEdit} reverse saveBtnText={t("common.save")} width="is-max-desktop" />}
            </Stack.Content>
            {/* TODO; Need to move buttons away from the form and into the layout. This is a workaround */}
            <Stack.Bar />
        </Stack.Begin>
    );
};
