import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTranslation } from "react-i18next";
import { holidays } from './holidays';
import { Tooltip, BubbleBox } from '@components';
import { formatDate, formatCustomDate, getMonthName, getWeekNumber, getDateForWeekStart, getMonthDates, areDatesEqual } from './tools';
import './calendar.scss';
import { useCalendar } from '../provider';

export const formattedNumber = number => Number.isInteger(number) ? number.toFixed(0) : number.toFixed(1);


const findPosition = (event) => {
  if (!event) return null;
  const closestDiv = event.target.closest('div');
  if (closestDiv) {
    const rect = closestDiv.getBoundingClientRect();
    const x = rect.left;
    const y = rect.top;
    const height = rect.height;
    const bottomY = y + height;
    const width = 0;

    return { x: x + width, y: bottomY };
  }
  return null;
}

export const useBubbleBoxSelection = () => {
  const [selection, setSelection] = React.useState(null);
  const [event, setEvent] = React.useState(null);
  const [position, setPosition] = React.useState(null);

  const open = (event, id) => {
    setEvent(event);
    setSelection(id);
    setPosition(findPosition(event));
  }
  const close = () => {
    setEvent(null);
    setSelection(null);
    setPosition(null);
  }
  useEffect(() => { }, [selection, event, position]);
  return { selection, event, position, open, close };
}

export const BubbleBoxesSelection = ({ event, id, position, close }) => {

  return (
    (position && id && event) && (
      <BubbleBox key={uuid()} position={position} close={close}>
        {id === 'year' && <YearSelection close={close} />}
        {id === 'week' && <WeekSelection close={close} />}
        {id === 'month' && <MonthSelection close={close} />}
        {id === 'day' && <DaySelection close={close} />}
      </BubbleBox>
    )
  );
};

export const YearSelection = ({ close }) => {
  const { Selection } = useCalendar();
  const currentYear = Selection.from.getFullYear();
  const onYearChange = (year) => {
    Selection.auto.apply(new Date(year, Selection.from.getMonth(), 1));
    close()
  }
  return (
    <div className="buttons has-addons">
      {Array.from({ length: 20 }, (v, k) => k + currentYear - 10).map((year, index) => (
        <button key={`year_${index}`} className={`button is-flex-grow-1 ${year === currentYear ? 'is-active' : ''}`} onClick={() => onYearChange(year)}>{year}</button>))}
    </div>
  )
}

export const MonthSelection = ({ close }) => {
  const { Selection } = useCalendar();
  const { t } = useTranslation();
  const currentMonth = Selection.from;
  const onMonthChange = (month) => {

    Selection.auto.apply(new Date(currentMonth.getFullYear(), month, 1));
    close()
  }
  return (
    <div className="buttons has-addons">
      {Array.from({ length: 12 }, (v, k) => k).map((month, index) => (
        <button key={`month_${index}`} className={`button is-flex-grow-1 ${month === currentMonth.getMonth() ? 'is-active' : ''}`} onClick={() => onMonthChange(month)}>{getMonthName(month, t)}</button>))}
    </div>
  )
}

export const WeekSelection = ({ close }) => {
  const { Selection } = useCalendar();
  const currentWeek = getWeekNumber(Selection.from);
  const onWeekChange = (week) => {
    const change = getDateForWeekStart(Selection.from.getFullYear(), week - 1);

    Selection.auto.apply(change);
    close();
  }
  return (
    <div className="buttons has-addons">
      {Array.from({ length: 52 }, (v, k) => k + 1).map((week, index) => (
        <button key={`week_${index}`} className={`button is-flex-grow-1 ${week === currentWeek ? 'is-active' : ''}`} onClick={() => onWeekChange(week + 1)}>{week}</button>
      ))}
    </div>
  )
}

export const DaySelection = ({ close }) => {
  const { Selection } = useCalendar();

  const dates = getMonthDates(Selection.selectedDate);
  const onDateChange = (date) => {
    Selection.day.apply(date);
    close();
  }
  return (
    <div className="buttons has-addons">
      {dates.map((date, index) => (
        <button key={`day_${index}`} className={`button is-flex-grow-1 ${areDatesEqual(date, Selection.selectedDate) ? 'is-active' : ''}`} onClick={() => onDateChange(date)}>{date.getDate()}</button>
      ))}
    </div>
  )
}

export const CalendarContentHeader = ({ date, total }) => {
  const { t } = useTranslation();
  const { setSelectedDate, CalendarItems } = useCalendar();
  const navigate = useNavigate();
  const goToDay = () => {
    setSelectedDate(date);
    navigate(`/time/log/day`);
  };

  return (
    <div className="columns is-gapless has-text-right m-0 is-vcentered">
      <div className="column is-narrow">
        <HoursIcon hours={total} date={date} t={t} />
      </div>
      <div className="column auto">
        <IsCustomIcon calendarItems={CalendarItems.items} date={date} />
        <IsHolidayIcon date={date} />
      </div>
      <div className="column is-narrow circle-hover" onClick={goToDay}>
        <span className={`mr-1 ${date.getDay() === 0 ? 'has-text-danger has-text-weight-medium' : ''}`}>{date.getDate()}</span>
      </div>
    </div>
  )
}

export const CalendarContentItems = ({ date, items, onClick, active, width, max }) => {
  const slicedItems = max && items.length <= max ? items : items.slice(0, max);
  const { setSelectedDate } = useCalendar();
  const navigate = useNavigate();
  const goToDay = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedDate(date);
    navigate(`/time/log/day`);
  };
  return (
    <div className="columns is-gapless is-multiline m-0 p-0 mt-2">
      {slicedItems.map((item, index) => (
        <CalendarDayLineSimple key={`col-cont-${index}`} item={item} onClick={onClick} active={active} width={width} />

      ))}
      {(max && items.length > max) && (
        <div className={`calendar-item column is-full is-link`} onClick={goToDay}>
          <div className='is-size-7'>
            ... {items.length - max} more items
          </div>
        </div>
      )
      }
    </div>
  )
}

export const CalendarDayLine = ({ color, item, className, ...props }) => {
  const { t } = useTranslation();
  return (
    <div className={`calendar-item column is-full is-clickable p-1 ${className ? className : ''}`} {...props}>
      <div className="columns is-size-7 has-text-left is-vcentered">
        <div className='column is-2 has-text-centered'>
          <Tooltip text={t("time.duration")}>
            <span className="icon"><i className="fa-regular fa-clock"></i></span> {item?.durationMinutes / 60}
          </Tooltip>
        </div>
        <div className='column is-2'>
          <Tooltip text={t("time.project")}>
            {item?.projectName}
          </Tooltip>
        </div>
        <div className='column is-2'>
          <Tooltip text={t("time.activity")}>
            {item?.activityName}
          </Tooltip>
        </div>
        <div className='column'>
          <Tooltip text={t("time.can_be_read_by_others")}>
            <div className='columns is-vcentered'>
              <div className='column is-narrow p-0'>

                <span className="icon"><i className="fa-solid fa-lock-open"></i></span>

              </div>
              <div className='column auto'>
                {item?.comment?.external}
              </div>
            </div>
          </Tooltip>
        </div>
        <div className='column'>
          <Tooltip text={t("time.can_be_read_by_you")}>
            <div className='columns is-vcentered'>
              <div className='column is-narrow p-0'>

                <span className="icon"><i className="fa-solid fa-lock"></i></span>

              </div>
              <div className='column auto'>
                {item?.comment?.internal}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

const CalendarDayLineSimple = ({ item, onClick, active, width }) => {
  const { TimeentriesForm } = useCalendar();
  const styles = {
    width: `${width - 5}px`,
    height: '20px',
    lineHeight: '20px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  };
  return (
    <div className={`calendar-item column is-full ${item.id === TimeentriesForm.currentItem?.id ? 'is-active' : ''}`} onClick={event => onClick(event, item)}>
      <div className='is-size-7' style={styles}>
        {`${item.durationMinutes / 60} ${item.projectName}`}
      </div>
    </div>
  );
}

export const IsHolidayIcon = ({ date }) => {
  const { t } = useTranslation();
  return (holidays.includes(formatDate(date))) && (
    <div className='tooltip'>
      <span className='icon has-text-danger'><i className="fas fa-info-circle"></i></span>
      <span className='tooltip-text'>{t("time.public_holiday")}</span>
    </div>
  );
}

export const IsCustomIcon = ({ calendarItems, date }) => {
  const customs = calendarItems.get(formatDate(date)); // Get array of custom items for date from Map, map then all to icons for each item
  return (
    customs && customs.map((custom, index) => (
      <div key={`custom_${index}`} className='tooltip mr-1'>
        <span className={`icon has-text-${custom.color}`}><i className={`fas fa-${custom.icon}`}></i></span>
        <span className='tooltip-text'>{custom.text}</span>
      </div>
    ))
  );
}

export const HoursIcon = ({ date, hours, t }) => (
  <div className='tooltip'>
    <div className="is-size-10 ml-1"> {hours}</div>
    <span className='tooltip-text'>{hours} {`${t("time.hours_logged")}`} {formatCustomDate(date, "dd.MM yyyy")}</span>
  </div>
);
