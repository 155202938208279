import { useEffect, useState, useRef } from 'react';

export const useElementSize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const elementRef = useRef(null);

  const updateSize = () => {
    if (elementRef.current) {
      const { offsetWidth, offsetHeight } = elementRef.current;
      setSize({ width: offsetWidth, height: offsetHeight });
    }
  };

  useEffect(() => {
    // Initial size measurement
    updateSize();

    // Event listener for window resize and element resize
    const handleResize = () => {
      updateSize();
    };

    window.addEventListener('resize', handleResize);

    // If you also want to handle element resize, you can use a ResizeObserver
    const resizeObserver = new ResizeObserver(handleResize);
    if (elementRef.current) {
      resizeObserver.observe(elementRef.current);
    }

    // Cleanup: remove event listeners on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }
    };
  }, []); // Empty dependency array to run the effect only once during component mount

  return { size, elementRef };
};
