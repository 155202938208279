import { useState } from 'react';
import { getWeekDates } from '../aux/tools';

export const View = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
  YEAR: 'year',
  CUSTOM: 'custom'
};

//TODO: Consider to move this into StoreProvider, and use loacalstore to keep the state for this one!
export const useSelection = (onDateChangeEvent) => {
  //Basically, this is the USER clicked on a day in calendar, and we need to track this. I need to do some more refactoring here in the other views.
  const [selectedDate, changeToDate] = useState(new Date());

  //THIS however, is the date range for the VIEW we are operating on.
  const [view, setView] = useState(View.MONTH); // 'day', 'week', 'month', 'quarter', 'year', 'custom'
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  const setSelectedDate = (date) => {
    //TODO: Put in a toolkit for common checks?
    if (onDateChangeEvent && typeof onDateChangeEvent === 'function') {
      onDateChangeEvent();
    }
    changeToDate(date);
  }

  const day = {
    apply: (newDate) => {
      const date = newDate || selectedDate;
      setFrom(date);
      setTo(date);
      setView(View.DAY);
      setSelectedDate(date);
    },
    move: (way) => {
      const newDate = new Date(from);
      newDate.setDate(from.getDate() + way);
      setFrom(newDate);
      setTo(newDate);
      setSelectedDate(newDate);
    }
  }

  const week = {
    apply: (newDate) => {
      const date = newDate || from;
      const ff = getWeekDates(date);
      setFrom(ff[0]);
      setTo(ff[ff.length - 1]);
      setView(View.WEEK);
    },
    move: (way) => {
      const currentWeek = new Date(from);
      const newWeek = new Date(currentWeek);
      newWeek.setDate(currentWeek.getDate() + (7 * way));
      setFrom(newWeek);
      setTo(new Date(newWeek.getFullYear(), newWeek.getMonth(), newWeek.getDate() + 6));
    }
  }

  const month = {
    apply: (newDate) => {
      const date = newDate || from;
      const ff = new Date(date.getFullYear(), date.getMonth(), 1);
      setFrom(ff);
      setTo(new Date(date.getFullYear(), date.getMonth() + 1, 0));
      setView(View.MONTH);
    },
    move: (way) => {
      const newMonth = new Date(from.getFullYear(), from.getMonth() + (1 * way), 1);
      if (way > 0) {
        setFrom(newMonth);
        setTo(new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 0));
      } else {
        setTo(new Date(newMonth.getFullYear(), newMonth.getMonth() + 1, 0));
        setFrom(new Date(newMonth.getFullYear(), newMonth.getMonth(), 1));
      }
    }
  }

  const year = {
    apply: (newDate) => {
      const date = newDate || from;
      setFrom(new Date(date.getFullYear(), 0, 1));
      setTo(new Date(date.getFullYear(), 11, 31));
      setView(View.YEAR);
    },
    move: (way) => {
      const newYear = new Date(from.getFullYear() + (1 * way), 0, 1);
      if (way > 0) {
        setFrom(newYear);
        setTo(new Date(newYear.getFullYear(), 11, 31));
      } else {
        setTo(new Date(newYear.getFullYear(), 11, 31));
        setFrom(new Date(newYear.getFullYear(), 0, 1));
      }
    }
  }

  const api = {
    day,
    week,
    month,
    year
  }

  const auto = {
    apply: (date, newView) => api[newView || view].apply(date),
    move: (way) => api[view].move(way)
  }

  //TODO: Figure out the prettiest way to do this.
  return {
    selectedDate,
    setSelectedDate,
    from,
    setFrom,
    to,
    setTo,
    day,
    week,
    month,
    year,
    auto,
    view
  }
}