export const holidays = [
  "2023-01-01",
  "2023-04-02",
  "2023-04-06",
  "2023-04-07",
  "2023-04-09",
  "2023-04-10",
  "2023-05-01",
  "2023-05-17",
  "2023-05-18",
  "2023-05-28",
  "2023-05-29",
  "2023-12-25",
  "2023-12-26",
  "2024-01-01",
  "2024-03-24",
  "2024-03-28",
  "2024-03-29",
  "2024-03-31",
  "2024-04-01",
  "2024-05-01",
  "2024-05-09",
  "2024-05-17",
  "2024-05-19",
  "2024-05-20",
  "2024-12-25",
  "2024-12-26",
  "2025-01-01",
  "2025-04-13",
  "2025-04-17",
  "2025-04-18",
  "2025-04-20",
  "2025-04-21",
  "2025-05-01",
  "2025-05-17",
  "2025-05-29",
  "2025-06-08",
  "2025-06-09",
  "2025-12-25",
  "2025-12-26",
  "2026-01-01",
  "2026-03-29",
  "2026-04-02",
  "2026-04-03",
  "2026-04-05",
  "2026-04-06",
  "2026-05-01",
  "2026-05-14",
  "2026-05-17",
  "2026-05-24",
  "2026-05-25",
  "2026-12-25",
  "2026-12-26",
  "2027-01-01",
  "2027-03-21",
  "2027-03-25",
  "2027-03-26",
  "2027-03-28",
  "2027-03-29",
  "2027-05-01",
  "2027-05-06",
  "2027-05-16",
  "2027-05-17",
  "2027-12-25",
  "2027-12-26"
]