import React from 'react';
import { Block } from '@components';
import { useTranslation } from "react-i18next";

export const About = () => {
    const { t } = useTranslation();

    return (
        <div className="content">
            <div className="has-text-centered">
                <h1 className="title">{`${t("info.about.header")}`}</h1>
                <h2 className="subtitle has-text-weight-semibold is-5">
                    {`${t("info.about.subheader")}`}
                </h2>

            </div>
            <Block />
            <blockquote>
                {`${t("info.about.info_box")}`}
            </blockquote>
            <Block />
            <p>
                <span className="has-text-weight-bold">TIM</span> {`${t("info.about.info_text_1")}`}
            </p>
            <p>
                {`${t("info.about.info_text_2")}`}
            </p>
            <p>
                {`${t("info.about.info_text_3")}`}
            </p>
            <p>
                {`${t("info.about.info_text_4")}`}
            </p>

        </div>
    );
}
