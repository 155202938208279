/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { getFirstAndLastWeekdays, getAllDates } from './aux';
import { useCalendar } from './provider';
import { CalendarHeader } from './CalendarHeader';
import { CalendarBody } from './CalendarBody';
import { Stack, H1, Columns, Column } from '@components';
import { useTranslation } from "react-i18next";
export const CalendarMonth = () => {
  const { t } = useTranslation();
  useEffect(() => {
    Selection.month.apply(Selection.selectedDate);
  }, []);

  const { Selection } = useCalendar();
  const { firstWeekday, lastWeekday } = getFirstAndLastWeekdays(Selection.from);

  const [monthDates, setMonthDates] = useState([]);

  useEffect(() => {
    // Need this to create equal amount of columns for each month
    let dates = getAllDates(Selection.from, Selection.to);

    const diffStart = firstWeekday - 1 > 0 ? Array(firstWeekday - 1).fill(null) : firstWeekday === 0 ? Array(6).fill(null) : [];
    const diffEnd = 7 - lastWeekday > 0 ? Array(7 - lastWeekday).fill(null) : [];
    setMonthDates([...diffStart, ...dates, ...diffEnd]);
  }, [Selection.from]);

  return (

    <Stack.Begin>
      <Stack.Bar>
        <Columns>
          <Column>
            <H1>{t('common.hour_registration')}</H1>
          </Column>
          <Column className="is-3">
          </Column>
        </Columns>
      </Stack.Bar>
      <Stack.Bar compact>
        <CalendarHeader />
      </Stack.Bar>
      <Stack.Content>
        <CalendarBody dates={monthDates} />
      </Stack.Content>
    </Stack.Begin>
  );
};