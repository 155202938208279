import { useEffect, useState } from 'react';

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    // Subscribe to the "resize" event
    window.addEventListener('resize', handleResize);

    // Initial cleanup function
    return () => {
      // Unsubscribe from the "resize" event when the component is unmounted
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once, similar to componentDidMount

  return windowSize;
};
