import React from 'react';
import { useNavigate } from 'react-router';
import { Tooltip, Levels, MenuContentContent, Section, Block, Box, GradientBarChart, PlotContainer, AreaPlot, TimeRegister, Container, StackLayout } from '@components';

import { useCalendar, CalendarProvider } from './provider';
import { CalendarToday } from './CalendarToday';
import { CalendarWeek } from './CalendarWeek';
import { CalendarMonth } from './CalendarMonth';
import { List } from './List';
import { useTranslation } from "react-i18next";

const routes = (t) => [
  { icon: "calendar-day", link: "log/day", label: t("time.day"), element: <CalendarToday /> },
  { icon: "calendar-week", link: "log/week", label: t("time.week"), element: <CalendarWeek /> },
  { icon: "calendar", link: "log/month", label: t("time.month"), element: <CalendarMonth /> },
  { icon: "table-list", newTitle: t("time.view_and_edit"), link: "view/month", label: t("time.month"), element: <List view="month" /> },
  { icon: "table-list", link: "view/week", label: t("time.week"), element: <List view="week" /> },
  { icon: "table-list", link: "year", label: t("time.year"), element: <List view="year" /> },
];

const TimeMenuContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { Selection, Data, Timeentries, TimeentriesForm } = useCalendar();
  /*   
      Placeholder for demo 
      const items = [
      { name: 'Last', income: 37 },
      { name: 'Last', income: 49 },
      { name: 'Last', income: 32 },
      { name: 'Last', income: 40 },
  
    ]; */
  // { heading: t("common.next"), titleIcon: 'caret-right', onClick: () => Selection.auto.move(1) }
  const levels = routes(t).map(({ icon, link, label }) => ({ heading: label, titleIcon: icon, onClick: () => navigate(link), link }));
  return (
    <StackLayout title={t("time.register_time")} routes={routes(t)} narrow={TimeentriesForm.isFormOpen} levels={levels}>
      <TimeRegister className={`${TimeentriesForm.isFormOpen ? '' : 'is-hidden'}`} selectedDate={Selection.selectedDate} Timeentries={Timeentries} TimeentriesForm={TimeentriesForm} Data={Data} />

      {/*

    TODO: Fix a sexy display of the business/shop number for current window time

    <PlotContainer data={[
      { name: 'Category A', value: 50 },
      { name: 'Category B', value: 80 },
      { name: 'Category C', value: 30 },
      // Add more data as needed
    ]} dataKeys={[]} title={t("time.income")} color="yellow" toolbox={false} element={GradientBarChart} />
    <Levels levels={[{ heading: t("time.month"), title: '140K' }, { heading: t("time.week"), title: '37K' },]} />
    <PlotContainer data={items} dataKey="income" height={14} color="yellow" toolbox={false} element={AreaPlot} />
    <div className="buttons">
      <Tooltip text="Trigger confetti" up delay={0}><button className={`button is-ghost is-icon is-selected mr-2`} onClick={() => { }}><span className='icon' ><i class="fa-solid fa-chart-simple"></i></span><span className="ml-1">{t("time.numbers")}</span></button></Tooltip>
      <Tooltip text="Trigger confetti" up delay={0}><button className={`button is-ghost is-icon is-selected mr-2`} onClick={() => { }}><span className='icon' ><i class="fa-solid fa-clock"></i></span><span className="ml-1">{t("time.register_time")}</span></button></Tooltip>
    </div> 
    */}

    </StackLayout>
  );
};

export const TimeMenu = () => {
  return (
    /*  <CalendarProvider> */
    <TimeMenuContent />
    /*  </CalendarProvider> */
  );
};
