import React from "react";

import { icons } from '../data';
import './IconPicker.scss';

export const IconPicker = ({ current, onChange }) => {
  return (
    <div className="icon-picker">
      {icons.map((icon, index) => (
        <span key={`icon_${icon}`} className={`icon-picker__item m-2 is-clickable ${icon === current ? 'has-text-primary' : ''}`} onClick={(event) => onChange(event, icon)}>
          <i className={icon}></i>
        </span>
      ))}
    </div>
  )
}