/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from "react-i18next";
import { Stack, H1 } from '@components';

export const Teams = () => {
    const { t } = useTranslation();
    return (
        <Stack.Begin>
            <Stack.Bar>
                <H1>{t("manage.menu.teams")}</H1>
            </Stack.Bar>
            <Stack.Content>
                <div className='content'>
                    <blockquote>
                        {t("manage.groups.info")}
                    </blockquote>
                </div>
            </Stack.Content>
        </Stack.Begin>
    );
};
