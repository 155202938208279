import React from 'react';

import { Table, TabForm, Stack, Loading } from '@components';
import { useTranslation } from "react-i18next";
import { useManage, ManageHeader } from '../ManageProvider';
import { template, tableHeaders } from './template';


export const Projects = () => {
    const { t } = useTranslation();
    const { flags, reset, item: project, edit, isLoading, isError, projects, projectsData, departmentOptions, activitiesOptions, usersOptions, customersOptions, searchQuery, setSearchQuery } = useManage();
    const create = projects.useCreate();
    const update = projects.useUpdate();

    if (isLoading || isError) return <Loading />;

    const data = template(departmentOptions, activitiesOptions, usersOptions, customersOptions, project, t);

    const onProjectAdd = (data) => {
        const createData = { ...data, data: data };
        create.mutate(createData, { onSuccess: reset });
    }

    const onProjectEdit = (data) => {
        const updateData = { ...data, data: data, id: project.id, version: project.version };
        update.mutate(updateData, { onSuccess: reset });
    }

    return (
        <Stack.Begin>
            <Stack.Bar>
                <ManageHeader plural={`${t("common.projects")}`} singular={`${t("common.project")}`} new_text={`${t("common.newt")}`} />
            </Stack.Bar>
            <Stack.Content>
                {flags.add && <TabForm data={data} onSubmit={onProjectAdd} cancelBtn={reset} reverse saveBtnText={`${t("common.save")}`} width="is-max-desktop" />}
                {flags.edit && <TabForm data={data} onSubmit={onProjectEdit} cancelBtn={reset} reverse saveBtnText={`${t("common.save")}`} width="is-max-desktop" />}
                {flags.view && <Table list={projectsData} keys={tableHeaders(t)} searchPlaceholder={`${t("manage.projects.search_for")}`} onItemClick={edit} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
            </Stack.Content>
            {/* TODO; Need to move pagination away from the table and into the layout. This is a workaround */}
            <Stack.Bar />
        </Stack.Begin>
    );
}
