import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ComposedChart, Bar } from 'recharts';
import { useStore } from '@providers';

const CustomYAxisTick = (value) => {
    let formattedValue = value;

    if (formattedValue >= 1000) {
        formattedValue = formattedValue % 1000 === 0 ? `${formattedValue / 1000}K` : `${(formattedValue / 1000).toFixed(1)}K`;

    }
    return formattedValue;
};


export const LinePlot = ({ data, dataKeys, palette }) => {
    if (!data) return null;

    return (

        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 10,
                    left: 10,
                    bottom: 5,
                }}
                fill="transparent"
            >
                <CartesianGrid strokeDasharray="3 3" stroke="transparent" />
                <XAxis dataKey="name" />
                <YAxis domain={['auto', 'auto']} tickFormatter={CustomYAxisTick} />
                <Tooltip />
                <Legend fill="#fff" />
                {dataKeys.map(({ key, name, color }, index) => (<Line key={`${key}_${name}`} name={name} dataKey={key} stroke={color ? palette[color] : Object.values(palette)[index]} type="monotone" connectNulls={false} activeDot={{ r: 8 }} dot={false} />))}
                {/*  
                <Line name="Income" type="monotone" connectNulls={false} dataKey="income" stroke={palette.yellow} strokeWidth={2} activeDot={{ r: 8 }} dot={false} />
                <Line name="Last year" type="monotone" connectNulls={false} dataKey="lastYear" stroke={palette.purple} strokeWidth={1} dot={false} /> 
                */}
            </LineChart>
        </ResponsiveContainer>
    )
};


const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
];

// Generate monthly statistics data for a year
const monthlyStatisticsData = months.map(month => ({
    month,
    hoursWorked: getRandomNumber(140, 180), // Example: Generate random number between 140 and 180
    hoursAvailable: 176,
    income: getRandomNumber(4000, 6000), // Example: Generate random number between 4000 and 6000
    expenses: getRandomNumber(1500, 2500), // Example: Generate random number between 1500 and 2500
    sickDays: getRandomNumber(0, 5), // Example: Generate random number between 0 and 5
    holidays: getRandomNumber(0, 3) // Example: Generate random number between 0 and 3
}));

export const MonthlyStatisticsChart = ({ data }) => {
    data = monthlyStatisticsData;
    const { palette, isLoading } = useStore().colors;
    if (isLoading) return null;

    return (
        <ResponsiveContainer width="100%" height={400}>
            <ComposedChart data={data} margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                <CartesianGrid strokeDasharray="3 3" stroke="transparent" />
                <XAxis dataKey="month" />
                <YAxis yAxisId="hours" label={{ value: 'Hours', angle: -90, position: 'insideLeft' }} />
                <YAxis yAxisId="amount" orientation="right" label={{ value: 'Amount', angle: 90, position: 'insideRight' }} />
                <Tooltip />
                <Legend verticalAlign="top" align="center" />
                <Bar yAxisId="hours" dataKey="hoursWorked" name="Hours Worked" fill={palette.positive} />
                <Bar yAxisId="hours" dataKey="hoursAvailable" name="Hours Available" fill={palette.dark} />
                <Bar yAxisId="amount" dataKey="income" name="Income" fill={palette.positive} />
                <Bar yAxisId="amount" dataKey="expenses" name="Expenses" fill={palette.negative} />
                <Line yAxisId="hours" dataKey="sickDays" name="Sick Days" stroke={palette.negative} />
                <Line yAxisId="hours" dataKey="holidays" name="Holidays" stroke={palette.dark} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};