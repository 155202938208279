import React from 'react';
import { Table, TabForm } from '@components';
import { useTranslation } from "react-i18next";
import { useManage, ManageHeader } from '../ManageProvider';
import { template, tableHeaders } from './template';

import { Stack } from '@components';

export const Users = () => {
    const { flags, reset, item: user, edit, usersData, users, departmentOptions, roleOptions, isLoading, isError, searchQuery, setSearchQuery } = useManage();
    const { t } = useTranslation();
    const create = users.useCreate();
    const update = users.useUpdate();

    if (isLoading || isError || !departmentOptions) return null;

    const data = template(departmentOptions, roleOptions, user, t);

    const onUserAdd = (data) => {
        const createData = { ...data, data: data };
        create.mutate(createData, { onSuccess: reset });
    }

    const onUserEdit = (data) => {
        console.log(user, data);
        const updateData = { ...data, data: data, role: parseInt(data.role), id: user.id, version: user.version };
        update.mutate(updateData, { onSuccess: reset });
    }

    return (
        <Stack.Begin>
            <Stack.Bar>
                <ManageHeader plural={t("manage.users.users")} singular={t("manage.users.user")} new_text={t("common.new")} />
            </Stack.Bar>
            <Stack.Content>
                {flags.add && <TabForm data={data} onSubmit={onUserAdd} cancelBtn={reset} reverse saveBtnText={t("manage.users.invite")} width="is-max-desktop" />}
                {flags.edit && <TabForm data={data} onSubmit={onUserEdit} cancelBtn={reset} reverse saveBtnText={t("common.save")} width="is-max-desktop" />}
                {flags.view && <Table list={usersData} keys={tableHeaders(t)} searchPlaceholder={t("manage.users.search_for")} onItemClick={edit} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
            </Stack.Content>
            {/* TODO; Need to move pagnation from table and buttons away from the form and into the layout. This is a workaround */}
            <Stack.Bar />
        </Stack.Begin>
    );
};
