import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { get, post, put, del } from './utils';
import { toast } from "react-toastify";

let getAccessToken = () => Promise.resolve('');
let ORGANIZATION = localStorage.getItem('organization');

let header = async () => {
  const token = await getAccessToken();
  return {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'organizationid': ORGANIZATION || ''
  }
}

export const useJikanokeAPI = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  getAccessToken = getAccessTokenSilently;
  ORGANIZATION = localStorage.getItem('organization');

  return {
    organizations: {
      useList: (enabled) => useQuery({ queryKey: ['organizations'], queryFn: () => get('/organizations', header), enabled: Boolean(enabled) }),
      useId: (enabled, id) => useQuery({ queryKey: ['organizations', id], queryFn: () => get(`/organizations/${id}`, header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['organizations', 'create'], mutationFn: data => post('/organizations', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          ORGANIZATION = data.id;
          localStorage.setItem('organization', data.id);
          console.log("ORGANIZATION", ORGANIZATION);
          queryClient.invalidateQueries('organizations')
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['organizations', 'update'], mutationFn: (data) => put(`/organizations/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('organizations', 'update');
        },
        onError: (error) => {
          console.error('Organization update error', error);
        },
      }),
    },
    departments: {
      useList: (enabled) => useQuery({ queryKey: ['departments'], queryFn: () => get('/departments', header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['departments', 'create'], mutationFn: data => post('/departments', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('departments')
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['departments', 'update'], mutationFn: (data) => put(`/departments/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('departments', 'update');
        },
        onError: (error) => {
          console.error('Departments update error', error);
        },
      }),
    },
    roles: {
      useList: (enabled) => useQuery({ queryKey: ['roles'], queryFn: () => get('/roles', header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['roles', 'create'], mutationFn: data => post('/roles', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('roles');
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['roles', 'update'], mutationFn: (data) => put(`/roles/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('roles', 'update');
        },
        onError: (error) => {
          console.error('Roles update error', error);
        },
      }),
    },
    positions: {
      useList: (enabled) => useQuery({ queryKey: ['positions'], queryFn: () => get('/positions', header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['positions', 'create'], mutationFn: data => post('/positions', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('positions')
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['user', 'update'], mutationFn: (data) => put(`/positions/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('positions', 'update');
        },
        onError: (error) => {
          console.error('Positions update error', error);
        },
      }),
    },
    projects: {
      useList: (enabled) => useQuery({ queryKey: ['projects'], queryFn: () => get('/projects', header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['projects', 'create'], mutationFn: data => post('/projects', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('projects')
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['projects', 'update'], mutationFn: (data) => put(`/projects/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('projects', 'update');
        },
        onError: (error) => {
          console.error('Projects update error', error);
        },
      }),
    },
    activities: {
      useList: (enabled) => useQuery({ queryKey: ['activities'], queryFn: () => get('/activities', header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['activities', 'create'], mutationFn: data => post('/activities', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('activities');
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['activities', 'update'], mutationFn: (data) => put(`/activities/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('activities', 'update');
        },
        onError: (error) => {
          console.error('Activities update error', error);
        },
      }),
    },
    customers: {
      useList: (enabled) => useQuery({ queryKey: ['customers'], queryFn: () => get('/customers', header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['customers', 'create'], mutationFn: data => post('/customers', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('customers')
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['customers', 'update'], mutationFn: (data) => put(`/customers/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('customers', 'update');
        },
        onError: (error) => {
          console.error('Customers update error', error);
        },
      }),
    },
    users: {
      useList: (enabled) => useQuery({ queryKey: ['users'], queryFn: () => get('/users', header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['users', 'create'], mutationFn: data => post('/users', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          toast.success(`User created: ${data[data.length - 1].ok?.message}`)
          queryClient.invalidateQueries('users');
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['user', 'update'], mutationFn: (data) => put(`/users/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('users', 'update');
        },
        onError: (error) => {
          console.error('User update error', error);
        },
      }),
      useAccepted: () => useMutation({
        queryKey: ['user', 'first'], mutationFn: (data) => put(`/users/${data.id}/accepted`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('users');
        },
        onError: (error) => {
          console.error('User update error', error);
        },
      }),
      usePasswordChange: (enabled) => useQuery({ queryKey: ['user', 'password'], queryFn: () => get('/users/pc', header), enabled: Boolean(enabled) }),
    },
    employees: {
      useList: (enabled) => useQuery({ queryKey: ['employees'], queryFn: () => get('/employees', header), enabled: Boolean(enabled) }),
      useId: (enabled, id) => useQuery({ queryKey: ['employees', id], queryFn: () => get(`/employees/${id}`, header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['employees', 'create'], mutationFn: data => post('/employees', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('employees');
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['employees', 'update'], mutationFn: data => put(`/employees/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('employees')
        }
      }),
    },
    timeentries: {
      organization: {
        useList: (enabled, from, to) => useQuery({ queryKey: ['timeEntries', 'manage', from, to], queryFn: () => get(`/manage/timeentries/${from}/${to}`, header), enabled: Boolean(enabled) }),
        useUpdate: () => useMutation({
          queryKey: ['timeEntries', 'manage', 'update'], mutationFn: data => put(`/manage/timeentries/${data.id}`, header, data),
          onSuccess: data => {
            console.log("onSuccess", data);
            queryClient.invalidateQueries('timeEntries', 'manage');
            queryClient.invalidateQueries('timeEntries', {
              refetchActive: true,
              refetchInactive: true,
            });
          }
        }),
      },
      useList: (enabled) => useQuery({ queryKey: ['timeEntries'], queryFn: () => get('/timeentries', header), enabled: Boolean(enabled) }),
      useId: (enabled, id) => useQuery({ queryKey: ['timeEntries', id], queryFn: () => get(`/timeentries/${id}`, header), enabled: Boolean(enabled) }),
      useCreate: () => useMutation({
        queryKey: ['timeEntries', 'create'], mutationFn: data => post('/timeentries', header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('timeEntries');
        }
      }),
      useUpdate: () => useMutation({
        queryKey: ['timeEntries', 'update'], mutationFn: data => put(`/timeentries/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('timeEntries')
        }
      }),
      useDelete: () => useMutation({
        queryKey: ['timeEntries', 'delete'], mutationFn: data => del(`/timeentries/${data.id}`, header, data),
        onSuccess: data => {
          console.log("onSuccess", data);
          queryClient.invalidateQueries('timeEntries')
        }
      }),
    },
  };
}