import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Stack, H1 } from '@components';

export const Presentations = () => {
    const { t } = useTranslation();
    return (
        <Stack.Begin>
            <Stack.Bar>
                <H1>{t("manage.menu.presentations")}</H1>
            </Stack.Bar>
            <Stack.Content>
                <div className='content'>
                    <blockquote>
                        {t("manage.presentations.info")}<Link to="/analytics/presenter-mode">{` ${t("common.here")}`}</Link>!
                    </blockquote>
                </div>
            </Stack.Content>
        </Stack.Begin>
    );
}
