import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

export const Feedback = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const { t } = useTranslation();
    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // TODO: Handle the form submission
        // You can send the form data to your backend or perform any desired actions
        // For this example, let's simply log the form data
        console.log('Name:', name);
        console.log('Email:', email);
        console.log('Message:', message);
        // Reset the form fields
        setName('');
        setEmail('');
        setMessage('');
    };
    return (
        <div className="content">
            <blockquote>
                {`${t("info.feedback.info_box")}`}
            </blockquote>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">{`${t("common.name")}`}</label>
                    <div className="control">
                        <input
                            className="input"
                            type="text"
                            placeholder={`${t("info.feedback.your_name")}`}
                            value={name}
                            onChange={handleNameChange}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">{`${t("common.email")}`}</label>
                    <div className="control">
                        <input
                            className="input"
                            type="email"
                            placeholder={`${t("info.feedback.your_email")}`}
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label">{`${t("common.message")}`}</label>
                    <div className="control">
                        <textarea
                            className="textarea"
                            placeholder={`${t("info.feedback.your_message")}`}
                            value={message}
                            onChange={handleMessageChange}
                        ></textarea>
                    </div>
                </div>
                <div className="field">
                    <div className="control">
                        <button className="button is-primary" type="submit">
                            {`${t("common.submit")}`}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    );
}
