/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { Block, Select, ConfirmButton, Switch, Stack } from '@components';
import { prettifyDate, convertFromMinutesToHourDecimalString } from '../../views/time/aux/tools';
import { useTranslation } from "react-i18next";

export const TimeRegister = ({ selectedDate, Timeentries, TimeentriesForm, Data }) => {
  const [confirm, setConfirm] = useState(false);
  const { t } = useTranslation();
  const durationSelection = Array.from({ length: 20 }, (v, k) => ({ name: k * 0.5 + 0.5 + "", value: k * 0.5 + 0.5, isActive: false }));

  const onChange = (id, value) => {
    // Split the id by dot to check for nesting
    const idParts = id.split('.');

    if (id === 'projectId') {
      const activities = Data.projectSelection.find(ps => ps.value === value).activities;
      const activityId = activities[0].value;
      TimeentriesForm.setEditItem((prevTimeRegistration) => ({
        ...prevTimeRegistration,
        [id]: value,
        activityId
      }));
      return;
    }

    if (id === 'durationMinutes') {
      console.log(id, value, parseFloat(value) * 60);
      TimeentriesForm.setEditItem((prevTimeRegistration) => ({
        ...prevTimeRegistration,
        [id]: parseFloat(value) * 60,
      }));
      return;
    }
    if (idParts.length === 1) {
      // Non-nested property update
      TimeentriesForm.setEditItem((prevTimeRegistration) => ({
        ...prevTimeRegistration,
        [id]: value,
      }));
    } else if (idParts.length === 2) {
      // Nested property update
      const [nestedKey, nestedProperty] = idParts;
      TimeentriesForm.setEditItem((prevTimeRegistration) => ({
        ...prevTimeRegistration,
        [nestedKey]: {
          ...prevTimeRegistration[nestedKey],
          [nestedProperty]: value,
        },
      }));
    }
  };

  const onSubmit = () => {
    if (TimeentriesForm.currentItem.isNew) {
      Timeentries.addItemForDate(selectedDate, TimeentriesForm.currentItem);
    } else {
      Timeentries.updateItemForDate(selectedDate, TimeentriesForm.currentItem)
    }
  }

  useEffect(() => {
    //setTimeRegistration(currentItem);
    setConfirm(false);
  },
    [TimeentriesForm.currentItem]);

  useEffect(() => {

  },
    [TimeentriesForm.currentItem?.projectId]);

  useEffect(() => {
    if (!TimeentriesForm.isFormOpen) return;
    TimeentriesForm.newItemCurrent();
  }, [selectedDate]);

  if (TimeentriesForm.currentItem === null) return null;

  if (Data.projectSelection.length === 0) return (
    <Stack.Begin>
      <Stack.Bar>
        <button className={`button is-danger is-pulled-right is-outlined ${confirm ? 'is-hidden' : ''}`} onClick={TimeentriesForm.closeForm}>
          <span class="icon is-small">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </Stack.Bar>
      <Stack.Content>
        {t("time.no_projects")}
      </Stack.Content>
    </Stack.Begin>
  )
  if (!TimeentriesForm.currentItem?.activityId) return (
    <Stack.Begin>
      <Stack.Bar>
        <button className={`button is-danger is-pulled-right is-outlined ${confirm ? 'is-hidden' : ''}`} onClick={TimeentriesForm.closeForm}>
          <span class="icon is-small">
            <i class="fas fa-times"></i>
          </span>
        </button>
      </Stack.Bar>
      <Stack.Content>
        {t("time.no_activities")}
      </Stack.Content>
    </Stack.Begin>
  )

  const activities = Data.projectSelection.find(ps => ps.value === TimeentriesForm.currentItem?.projectId).activities;
  const activityId = activities.find(a => a.value === TimeentriesForm.currentItem?.activityId)?.value || activities[0].value;

  return (
    <Stack.Begin>
      <Stack.Bar>
        <Stack.SpaceBetween>
          <div>{`${TimeentriesForm.currentItem?.isNew ? t("time.new_reg_for") : t("time.edit_reg_for")}`} <span className='has-text-weight-semibold'>{prettifyDate(selectedDate, t)}</span></div>
          <button className={`button is-danger is-outlined ${confirm ? 'is-hidden' : ''}`} onClick={TimeentriesForm.closeForm}>
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
        </Stack.SpaceBetween>
      </Stack.Bar>
      <Stack.Content>
        <>
          <div className="columns is-size-7 has-text-left">
            <div className='column narrow'>
              {t("time.duration")}
              <Select id="durationMinutes" items={durationSelection} selected={convertFromMinutesToHourDecimalString(TimeentriesForm.currentItem?.durationMinutes)} onItemClick={onChange} />
            </div>
          </div>
          <div className="columns is-size-7 has-text-left">
            <div className='column narrow'>
              {t("time.project")}
              <Select id="projectId" items={Data.projectSelection} selected={TimeentriesForm.currentItem?.projectId} onItemClick={onChange} />
            </div>
          </div>
          <div className="columns is-size-7 has-text-left">
            <div className='column is-full' >
              {t("time.activity")}
              <Select id="activityId" items={activities} selected={activityId} onItemClick={onChange} />
            </div>
          </div>
          <Block className="has-text-centered is-flex is-justify-content-space-between">
            <span>
              {t("time.is_billable")}
            </span>

            <Switch switchId="isBillable"
              checked={TimeentriesForm.currentItem?.isBillable}
              onToggle={(id, event) => onChange(id, event.target.checked)} />
          </Block>
          <div className="columns">
            <div className='column auto'>
              <div>{t("time.public_comment")}</div>

              <input
                className="input"
                type="text"
                placeholder={t("time.public_comment")}
                value={TimeentriesForm.currentItem?.commentPublic}
                onChange={event => onChange('commentPublic', event.target.value)}
              />
              <div>
                {t("time.private_comment")}
              </div>
              <input
                className="input mt-2"
                type="text"
                placeholder={t("time.private_comment")}
                value={TimeentriesForm.currentItem?.commentInternal}
                onChange={event => onChange('commentInternal', event.target.value)}
              />
            </div>
          </div>
        </>
      </Stack.Content>
      <Stack.Bar>
        <div className="columns" >
          <div className='column'>
            <div className="is-flex is-justify-content-space-between">
              <button className={`button is-success is-outlined mr-2 ${confirm ? 'is-hidden' : ''}`} onClick={onSubmit}>{t("time.save_changes")}</button>
              <button className={`button is-danger is-outlined ${confirm ? 'is-hidden' : ''}`} onClick={TimeentriesForm.closeForm}>
                <span>{`${t("common.cancel")}`}</span>
                <span class="icon is-small">
                  <i class="fas fa-times"></i>
                </span>
              </button>
              {(!TimeentriesForm.currentItem?.isNew && !confirm) && <button className="button ml-2" onClick={() => setConfirm(true)}>
                <span className="icon is-small">
                  <i className="fas fa-trash"></i>
                </span>
              </button>}
              {confirm && <ConfirmButton text={t("time.delete_selected")} onCancel={() => setConfirm(false)} onConfirm={() => {
                Timeentries.removeEntryForDate(selectedDate, TimeentriesForm.currentItem);
                setConfirm(false);
              }} />}
            </div>
          </div>
        </div >
      </Stack.Bar>
    </Stack.Begin>);
};