export const template = (departmentOptions, positionsOptions, countryOptions, employee, t) => {
  if (!departmentOptions || !positionsOptions || !countryOptions) {
    console.error('Missing required parameters');
    return [];
  }

  return [{
    name: t("manage.employees.details"), fields:
      [
        { name: 'name', label: t("common.full_name"), value: employee?.data?.name || null, required: true },
        { name: "birthDate", label: t("manage.employees.birth_date"), type: "date", value: employee?.data?.birthDate || null, required: true },
        { name: "departments", label: t("manage.employees.departments"), options: [...departmentOptions], value: employee?.data?.departments, multiple: true, type: 'select', required: true },
        { name: "position", label: t("manage.employees.position"), multiple: false, options: positionsOptions, value: employee?.data?.position || 1, type: 'select', required: true }
      ]
  },
  {
    name: t("manage.employees.activity"), fields: [
      { name: "startDate", label: t("common.start_date"), type: "date", required: true, value: employee?.data?.startDate || null },
      { name: "endDate", label: t("manage.employees.termination"), type: "date", required: false, value: employee?.data?.endDate || null },
      { name: 'status', label: "Status", value: employee?.data?.status || '' },
    ]
  },
  {
    name: t("common.address"), fields:
      [
        { name: 'address.street', label: t("common.street"), value: employee?.data?.address?.street || null },
        { name: 'address.city', label: t("common.city"), value: employee?.data?.address?.city || null },
        { name: 'address.postalcode', label: t("common.postal_code"), value: employee?.data?.address?.postalcode || null },
        { name: 'address.postalplace', label: t("common.postal_place"), value: employee?.data?.address?.postalplace || null },
        { name: 'address.country', label: t("common.country"), options: countryOptions, value: employee?.data?.address?.country || 'no', type: 'select' },
      ]
  },
  {
    name: t("manage.employees.contact"), fields:
      [
        { name: 'email', label: t("common.email"), validator: 'email', value: employee?.data?.email || null },
        { name: 'phone', label: t("common.phone_no"), validator: 'phone', value: employee?.data?.phone || null },
        { name: 'website', label: t("common.website"), validator: 'website', value: employee?.data?.website || null }
      ]
  },
    // TODO: Create timeline example of history of events for employee
    /* {
      name: "Timeline", fields: [], component: 'This is a comment'
    } */
  ];
}

export const tableHeaders = (t) => [
  { key: 'data.name', label: t("common.name") },
  { key: 'data.email', label: t("common.email") },
  { key: 'data.active', label: t("manage.employees.is_active") },
  { key: 'data.address.street', label: t("common.street") },
  { key: 'data.birthDate', label: t("manage.employees.birth_date") },
];

