// This file is used to display a welcome message in the console
const welcome = () => {
  const generateText = (filler, times, text) => {
    return filler.repeat(times) + text + filler.repeat(times);
  }

  console.log(`%c\n${generateText("*", 27, '')}\n`, 'text-align:center; color: red; font-weight: bold;');
  console.log(`%c\n${generateText(" ", 6, 'Welcome to the Jikanoke console')}\n`, 'text-align:center; color: #373943; font-weight: bold; font-size:14px;');

  console.log(`%c\nIf you find something interesting, let us know at\n`, 'font-size:12px; text-align:center; color: #373943; font-weight: bold;');
  console.log(`%c\n${generateText(" ", 6, 'console.snooping@jikanoke.com')}\n`, 'text-align:center; color: #373943; font-weight: bold; font-size:14px;');
  console.log(`%c\n${generateText("*", 27, '')}\n`, 'text-align:center; color: red; font-weight: bold;');
}

export default welcome;