import i18n from 'i18next';
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'no',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    resources: {
      'no': { translation: require('./i18n/no/translation.json') },
      'en': { translation: require('./i18n/en/translation.json') }
    },
    ns: ['translation'],
    defaultNS: 'translation'
  });

export default i18n;
