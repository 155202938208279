import { useState, useEffect } from 'react';
import { useKeyPress } from '@hooks';

export const useKeyboard = (Selection) => {
  const [enabled, setEnabled] = useState(true)
  const keys = useKeyPress(['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown']);

  useEffect(() => {
    if (!enabled) return;
    if (keys['ArrowRight']) {
      const next = new Date(Selection.selectedDate.getFullYear(), Selection.selectedDate.getMonth(), Selection.selectedDate.getDate() + 1);
      Selection.setSelectedDate(next);
    }
    else if (keys['ArrowLeft']) {
      const next = new Date(Selection.selectedDate.getFullYear(), Selection.selectedDate.getMonth(), Selection.selectedDate.getDate() - 1);
      Selection.setSelectedDate(next);
    }
    else if (keys['ArrowUp']) {
      const next = new Date(Selection.selectedDate.getFullYear(), Selection.selectedDate.getMonth(), Selection.selectedDate.getDate() - 7);
      Selection.setSelectedDate(next);
    }
    else if (keys['ArrowDown']) {
      const next = new Date(Selection.selectedDate.getFullYear(), Selection.selectedDate.getMonth(), Selection.selectedDate.getDate() + 7);
      Selection.setSelectedDate(next);
    }
  }, [keys, enabled]);


  return {
    enabled,
    setEnabled,
  }
}