import React from 'react';
import { Levels, Stack } from '@components';
import { useTranslation } from "react-i18next";

export const TBA = ({ label, quote }) => {
    const { t } = useTranslation();
    const textAuth = t(`analytics.${quote}`).split('-');
    const levels = [
        { heading: t("analytics.tba"), title: label },
    ]
    return (
        <Stack.Fullscreen>
            <Stack.Begin center>
                <Stack.Bar className="has-text-centered" compact>
                    <Levels levels={levels} className="is-justify-content-center" />
                </Stack.Bar>
                <Stack.Bar>
                    <div className="is-italic mt-2" style={{ maxWidth: '250px' }}>{textAuth[0].trim()}</div>
                </Stack.Bar>
                <Stack.Bar className="has-text-centered" compact>
                    <div className="has-text-weight-semibold mt-5">{textAuth[1].trim()}</div>
                </Stack.Bar>
            </Stack.Begin>
        </Stack.Fullscreen>
    );
}
