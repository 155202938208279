import React from 'react';

import { Table, TabForm, Stack } from '@components';
import { useManage, ManageHeader } from '../ManageProvider';
import { template, tableHeaders } from './template';
import { useTranslation } from "react-i18next";

export const Customers = () => {
    const { t } = useTranslation();
    const { flags, reset, item: customer, edit, isLoading, isError, customers, customersData, searchQuery, setSearchQuery } = useManage();
    const create = customers.useCreate();
    const update = customers.useUpdate();

    if (isLoading || isError) return null;

    const data = template(customer, t);

    const onAdd = (data) => {
        const createData = { ...data, data: data };
        create.mutate(createData, { onSuccess: reset });
    }

    const onEdit = (data) => {
        const updateData = { ...data, data: data, id: customer.id, version: customer.version };
        update.mutate(updateData, { onSuccess: reset });
    }

    return (
        <Stack.Begin>
            <Stack.Bar>
                <ManageHeader plural={t("manage.customers.customers")} singular={t("manage.customers.customer")} new_text={t("common.new")} />
            </Stack.Bar>
            <Stack.Content>
                {flags.add && <TabForm data={data} onSubmit={onAdd} cancelBtn={reset} reverse saveBtnText={t("common.save")} width="is-max-desktop" />}
                {flags.edit && <TabForm data={data} onSubmit={onEdit} cancelBtn={reset} reverse saveBtnText={t("common.save")} width="is-max-desktop" />}
                {flags.view && <Table list={customersData} keys={tableHeaders(t)} searchPlaceholder={t("manage.customers.search_for")} onItemClick={edit} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />}
            </Stack.Content>
            {/* TODO; Need to move pagination away from the table and into the layout. This is a workaround */}
            <Stack.Bar />
        </Stack.Begin>
    );
};
