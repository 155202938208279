import { useCallback, useEffect, useRef } from 'react';

export const useDebouncedCallback = (callback, delay) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const debouncedCallback = useCallback((...args) => {
    const timeout = setTimeout(() => {
      callbackRef.current(...args);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  return debouncedCallback;
};
