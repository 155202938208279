import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Block } from '@components';
import slack from '@assets/slack.svg';
import discord from '@assets/discord.svg'
import github from '@assets/github.svg'
import teams from '@assets/msteams.svg'

import { Stack, H1 } from '@components';

export const Connections = () => {
    const { t } = useTranslation();

    return (
        <Stack.Begin>
            <Stack.Bar>
                <H1> {`${t("account.left_menu.connected_accounts")}`}</H1>
            </Stack.Bar>
            <Stack.Content>
                <div className="content">
                    <blockquote>
                        {`${t("account.connections.info")}`}<Link to="/account/notifications">{`${t("common.notifications")}`}</Link>{`${t("account.connections.section")}`}.
                    </blockquote>
                    <Block>
                        {`${t("account.connections.available")}`}
                    </Block>
                    <div className="card">
                        <div className="card-content">
                            <div className="media">
                                <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src={slack} alt="Slack logo" />
                                    </figure>
                                </div>
                                <div className="media-content">
                                    <p className="title is-4"> <Link>{t("analytics.tba")}</Link></p>
                                    <p className="subtitle is-6"><a className='has-text-white' href="https://slack.no">Slack {`${t("common.website")}`}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Block />
                    <div className="card is-narrow">
                        <div className="card-content">
                            <div className="media">
                                <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src={discord} alt="Discord logo" />
                                    </figure>
                                </div>
                                <div className="media-content">
                                    <p className="title is-4"> <Link>{t("analytics.tba")}</Link></p>
                                    <p className="subtitle is-6"><a className='has-text-white' href="https://slack.no">Discord {`${t("common.website")}`}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Block />
                    <div className="card is-narrow">
                        <div className="card-content">
                            <div className="media">
                                <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src={github} alt="Github logo" />
                                    </figure>
                                </div>
                                <div className="media-content">
                                    <p className="title is-4"><Link>{t("analytics.tba")}</Link></p>
                                    <p className="subtitle is-6"><a className='has-text-white' href="https://poweroffice.no">Github {`${t("common.website")}`}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Block />
                    <div className="card is-narrow">
                        <div className="card-content">
                            <div className="media">
                                <div className="media-left">
                                    <figure className="image is-48x48">
                                        <img src={teams} alt="Microsoft Teams logo" />
                                    </figure>
                                </div>
                                <div className="media-content">
                                    <p className="title is-4"><Link>{t("analytics.tba")}</Link></p>
                                    <p className="subtitle is-6"><a className='has-text-white' href="https://poweroffice.no">Microsoft Teams {`${t("common.website")}`}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Stack.Content>
        </Stack.Begin>

    );
}
