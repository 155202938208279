import React from 'react';
import { Block } from '@components';
import { useTranslation } from "react-i18next";
import map from '@assets/map.png';

export const Contact = () => {
    const { t } = useTranslation();

    return (
        <div className="content">
            <blockquote>
                {`${t("info.contact_us.info_box")}`}
            </blockquote>
            <Block />
            <div className="card">
                <div className="card-content">
                    <div className="content">
                        <div className="columns has-text-centered">
                            <div className="column">
                                <strong>{`${t("common.address")}`}</strong>
                                <br />
                                Nye Sandviksveien, Bergen, Norway
                            </div>
                            <div className="column">
                                <p>
                                    <strong>{`${t("common.email")}`}</strong>
                                    <br />
                                    <a href="hei@tim.as">hei@tim.as</a>

                                </p>
                            </div>
                        </div>

                        <div className="columns">
                            <div className="column">
                                <figure className="image">
                                    <img src={map} alt="Map" />
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
