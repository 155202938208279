export const template = (department, t) => {
  return [{
    name: 'Data', fields: [
      { name: "name", label: t("common.name"), value: department?.data?.name || '', required: true },
      { name: 'description', label: t("common.description"), value: department?.data?.description || '' },
    ]
  }]
}

export const tableHeaders = (t) => [
  { key: 'data.name', label: t("common.name") },
  { key: 'data.description', label: t("common.description") }
];

