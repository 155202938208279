import React from 'react';
import './footer.scss';

export const Footer = ({ children }) => {
  return (
    <div className='custom-footer'>
      <div className='is-flex is-justify-content-center is-align-items-center'>
        {children}
      </div>
    </div>
  )
}