import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useKeyPress } from '@hooks';

const defaultItem = { isNew: true, isBillable: true, durationMinutes: 450, commentPublic: "", commentInternal: "" };

export const useTimeentryForm = (Data, Selection, Timeentries) => {
  const keys = useKeyPress(['Enter', 'Escape']);
  const [isFormOpen, setFormOpen] = useState(false);
  const [currentItem, setEditItem] = useState({ id: uuidv4() });


  const toggleNewItem = () => {
    if (isFormOpen) {
      closeForm();
    } else {
      newItem(Selection.selectedDate);
    }
  }

  const openForm = () => {
    setFormOpen(true);
  };

  const closeForm = () => {
    setEditItem({ id: -1 });
    setFormOpen(false);
  };

  const editItem = (item) => {
    openForm();
    setEditItem(item);
  };

  const getDefaultProject = () => {
    return Data.projectSelection.length > 0 ? Data.projectSelection[0] : null;
  }
  const createNewItem = () => {
    const project = getDefaultProject();
    setEditItem({ ...defaultItem, id: uuidv4(), projectId: project?.value, activityId: project?.activities?.length > 0 ? project.activities[0].value : null });
  }

  const newItem = (date) => {
    console.log("newItem", date);
    createNewItem();
    Selection.setSelectedDate(date);
    openForm();
  };

  const newItemCurrent = () => {
    console.log("newItemcurrent");
    createNewItem();
    openForm();
  };

  useEffect(() => {

    if (keys['Enter'] && isFormOpen) {
      if (currentItem.isNew) {
        Timeentries.addItemForDate(Selection.selectedDate, currentItem);
      } else {
        Timeentries.updateItemForDate(Selection.selectedDate, currentItem)
      }
    }
    else if (keys['Escape'] && isFormOpen) {
      closeForm();
    }
    else if (keys['Enter'] && !isFormOpen) {
      openForm();
    }
  }, [keys]);

  return {
    isFormOpen,
    currentItem,
    openForm,
    closeForm,
    editItem,
    newItem,
    newItemCurrent,
    toggleNewItem,
    setEditItem
  }
}