import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '@providers';

export const TIM = () => {
    const groupRef = useRef(null);
    const [rotation, setRotation] = useState(0);
    const { palette } = useStore().colors;
    /*  useEffect(() => {
         const rotateGroup = () => {
             setRotation((prevRotation) => prevRotation - 0.5);
         };
 
         const interval = setInterval(rotateGroup, 60); // Rotate every 100 milliseconds
 
         return () => {
             clearInterval(interval);
         };
     }, []);
 
     useEffect(() => {
         const groupElement = groupRef.current;
         const bbox = groupElement.getBBox();
         const centerX = bbox.x + bbox.width / 2; // - 3.5;
         const centerY = bbox.y + bbox.height / 2;//+ 7.5;
 
         groupElement.setAttribute('transform', `rotate(${rotation} ${centerX} ${centerY})`);
     }, [rotation]); */

    return (
        <svg viewBox="0 20 150 97" className="tim"><g id="SvgjsG1553" transform="matrix(4.711055536016812,0,0,4.711055536016812,-2.8266339955319877,2.3932139299477058)" fill={palette.white}><path d="M6.08 17.36 c0.56 0 0.96 -0.08 1.4 -0.2 l0 2.62 c-0.44 0.2 -1.16 0.34 -2.22 0.34 c-1.74 0 -3.2 -0.58 -3.2 -3.78 l0 -4.14 l-1.46 0 l0 -2.6 l1.46 0 l0 -2.44 l3.16 0 l0 2.44 l2.22 0 l0 2.6 l-2.22 0 l0 4.14 c0 0.46 0.12 1.02 0.86 1.02 z M12.32 5.859999999999999 l0 2.66 l-3.16 0 l0 -2.66 l3.16 0 z M12.32 9.6 l0 10.4 l-3.16 0 l0 -10.4 l3.16 0 z M28.259999999999998 9.44 c2.22 0 4.18 1.44 4.18 4.96 l0 5.6 l-3.16 0 l0 -5.5 c0 -1.32 -0.56 -2.32 -2.02 -2.32 c-1.22 0 -2.04 1.08 -2.28 2.04 l0 5.78 l-3.16 0 l0 -5.5 c0 -1.32 -0.82 -2.32 -2.02 -2.32 c-1.3 0 -2.08 0.88 -2.28 2.04 l0 5.78 l-3.16 0 l0 -10.4 l3.16 0 l0 1.9 c0.04 -0.48 1.06 -2.06 3.1 -2.06 c1.5 0 2.94 0.8 3.8 2.26 c0.24 -0.56 1.44 -2.26 3.84 -2.26 z"></path></g></svg>
    )
};