/* eslint-disable jsx-a11y/anchor-is-valid */
// TODO: Should be refactored
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './navbar.scss';

export const NavBar = ({ organization, role }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleClick = (path) => {
    navigate(path);
    setMenuOpen(false);
  };

  const isActive = (path) => location.pathname.includes(path) ? "is-active" : "";

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar is-fullwidth is-fixed-top has-shadow has-shadow-strong has-primary-bg" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" onClick={() => handleClick('/')}>
          <svg className="nav-bar-logo" viewBox="0 20 150 97">
            <g transform="matrix(4.711055536016812,0,0,4.711055536016812,-2.8266339955319877,2.3932139299477058)" fill="#fff">
              <path d="M6.08 17.36 c0.56 0 0.96 -0.08 1.4 -0.2 l0 2.62 c-0.44 0.2 -1.16 0.34 -2.22 0.34 c-1.74 0 -3.2 -0.58 -3.2 -3.78 l0 -4.14 l-1.46 0 l0 -2.6 l1.46 0 l0 -2.44 l3.16 0 l0 2.44 l2.22 0 l0 2.6 l-2.22 0 l0 4.14 c0 0.46 0.12 1.02 0.86 1.02 z M12.32 5.859999999999999 l0 2.66 l-3.16 0 l0 -2.66 l3.16 0 z M12.32 9.6 l0 10.4 l-3.16 0 l0 -10.4 l3.16 0 z M28.259999999999998 9.44 c2.22 0 4.18 1.44 4.18 4.96 l0 5.6 l-3.16 0 l0 -5.5 c0 -1.32 -0.56 -2.32 -2.02 -2.32 c-1.22 0 -2.04 1.08 -2.28 2.04 l0 5.78 l-3.16 0 l0 -5.5 c0 -1.32 -0.82 -2.32 -2.02 -2.32 c-1.3 0 -2.08 0.88 -2.28 2.04 l0 5.78 l-3.16 0 l0 -10.4 l3.16 0 l0 1.9 c0.04 -0.48 1.06 -2.06 3.1 -2.06 c1.5 0 2.94 0.8 3.8 2.26 c0.24 -0.56 1.44 -2.26 3.84 -2.26 z"></path>
            </g>
          </svg>
          <span className="pl-3 ml-1 navbar-title">
            {organization && organization}
          </span>
        </a>
        <a
          className={`navbar-burger has-text-white burger ${isMenuOpen ? 'is-active' : ''}`}
          role="button"
          aria-label="menu"
          aria-expanded={isMenuOpen ? 'true' : 'false'}
          onClick={toggleMenu}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div className={`navbar-menu ${isMenuOpen ? 'is-active' : ''}`}>
        <div className="navbar-end">
          {role === 1 &&
            <a className={`navbar-item is-outlined ${isActive('/organization-dashboard')}`} onClick={() => handleClick('/organization-dashboard')}>
              <span className="icon"><i className={`fa-solid fa-building`} aria-hidden="true"></i></span><span className="has-text-black  is-hidden-desktop">Organization</span>
            </a>
          }
          <a className={`navbar-item is-outlined ${isActive('/dashboard')}`} onClick={() => handleClick('/dashboard')}>
            <span className="icon"><i className={`fas fa-chart-simple`} aria-hidden="true"></i></span><span className="has-text-black  is-hidden-desktop">My dashboard</span>
          </a>
          <a className={`navbar-item is-outlined ${isActive('/time')}`} onClick={() => handleClick('/time/log/month')}>
            <span className="icon"><i className={`fas fa-clock`} aria-hidden="true"></i></span><span className="has-text-black  is-hidden-desktop">Time</span>
          </a>
          {/* Organization admin only */}
          {role === 1 &&
            <>
              <a className={`navbar-item is-outlined ${isActive('/manage')}`} onClick={() => handleClick('/manage/projects')}>
                <span className="icon"><i className={`fas fa-gear`} aria-hidden="true"></i></span><span className="has-text-black is-hidden-desktop">Manage</span>
              </a>

              {/*  <a className={`navbar-item is-outlined ${isActive('/info')}`} onClick={() => handleClick('/info/about')}>
                <span className="icon"><i className={`fas fa-info`} aria-hidden="true"></i></span><span className="has-text-black is-hidden-desktop">About</span>
              </a> */}
            </>
          }
          <a className={`navbar-item is-outlined ${isActive('/account')}`} onClick={() => handleClick('/account/profile')}>
            <span className="icon"><i className={`fas fa-user`} aria-hidden="true"></i></span><span className="has-text-black is-hidden-desktop">Profile</span>
          </a>
        </div>
      </div>
    </nav>
  );
};
