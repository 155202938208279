import React, { useState } from 'react';

import { useTranslation } from "react-i18next";
import { useJikanokeAPI } from '@services';
import { Stack, H1 } from '@components';

export const AccountSettings = () => {
    const { users } = useJikanokeAPI();
    const { refetch, isSuccess } = users.usePasswordChange(false)
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const passwordChange = async () => {
        try {
            setIsLoading(true);
            await refetch();
        } catch (e) {

        }
        finally {
            setTimeout(() => {
                setEmailSent(isSuccess);
                setIsLoading(false);
            }, 1500);
        }
    }
    console.log(isSuccess);
    return (
        <Stack.Begin>
            <Stack.Bar>
                <H1> {`${t("account.account_settings")}`}</H1>
            </Stack.Bar>
            <Stack.Content>
                <Stack.SpaceBetween>
                    <button className={`${isLoading ? 'is-loading' : ''} button is-link`} onClick={passwordChange}>{`${t("account.options.change_password")}`}</button>
                    <div className="">
                        {`${t("account.options.send_info")}`}
                    </div>
                </Stack.SpaceBetween>
                {emailSent && <div className="notification is-success mt-2">{t("common.check_email")}</div>}
            </Stack.Content>
        </Stack.Begin>
    );
}