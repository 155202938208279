import React, { createContext, useContext, useState } from 'react';

import { useSelection } from './useSelection';
import { useData } from './useData';
import { useCalendarItems } from './useCalendarItems';
import { useTimeentries } from './useTimeentries';
import { useTimeentryForm } from './useTimeentryForm';
import { useStatistics } from './useStatistics';
import { useKeyboard } from './useKeyboard';
import { useJikanokeAPI } from '@services';

const CalendarContext = createContext();

export const CalendarProvider = ({ children }) => {

  // State to force a refresh of the calendar. These are used when an event is added or updated.
  const [refresh, setRefresh] = useState(false);
  const [flashItem, setFlashItem] = useState(null);

  // TODO: Consider a global event bus like in Vue.js. This is a simple solution for now. 
  // This is just for flashy flash, and some nice flow for time entry registrations
  const onDeleteEvent = (date) => {
    // We are deleting through the form, so we need to replace the deleted item in the form, with new one.
    TimeentriesForm.newItem(date);
    setRefresh(prev => !prev);
  }

  const onUpdateEvent = (id) => {
    setFlashItem(prev => !prev);
  }

  const onDateChangeEvent = (id) => {
    setFlashItem(null);
  }

  const { timeentries } = useJikanokeAPI();

  // Divide and conquer the logic, motherfuckers! Now it is easier to optimize each individual piece of code and figure out the DI
  const Selection = useSelection(onDateChangeEvent); // Basically all the read operations from the backend, will depend on the selected date range
  const Data = useData();
  const Timeentries = useTimeentries(Data, onDeleteEvent, onUpdateEvent, timeentries.useUpdate());
  const TimeentriesForm = useTimeentryForm(Data, Selection, Timeentries);
  const CalendarItems = useCalendarItems();
  const Keyboard = useKeyboard(Selection);
  const Statistics = useStatistics(Data, Selection, Timeentries);

  return (
    <CalendarContext.Provider value={{
      //TODO: Refactor next
      selectedDate: Selection.selectedDate,
      setSelectedDate: Selection.setSelectedDate,
      flashItem,
      refresh,
      // This is good!
      Selection,
      Keyboard,
      Data,
      Timeentries,
      TimeentriesForm,
      CalendarItems,
      Statistics
    }}>
      {children}
    </CalendarContext.Provider>
  );
};

// Custom hook to access the context values
export const useCalendar = () => useContext(CalendarContext);
