import React from 'react';
import { useTranslation } from "react-i18next";

export const FAQ = () => {
    const { t } = useTranslation();

    return (
        <div className="content">
            <blockquote>
                {`${t("info.faq.info_box")}`}
            </blockquote>
            <h2 className="subtitle is-6">{`${t("info.faq.question_1")}`}</h2>{`${t("info.faq.answer_1")}`}
            <h2 className="subtitle is-6">{`${t("info.faq.question_2")}`}</h2>{`${t("info.faq.answer_2")}`}
            <h2 className="subtitle is-6">{`${t("info.faq.question_3")}`}</h2>{`${t("info.faq.answer_3")}`}
            <h2 className="subtitle is-6">{`${t("info.faq.question_4")}`}</h2>{`${t("info.faq.answer_4")}`}
            <h2 className="subtitle is-6">{`${t("info.faq.question_5")}`}</h2>{`${t("info.faq.answer_5")}`}
            <h2 className="subtitle is-6">{`${t("info.faq.question_6")}`}</h2>{`${t("info.faq.answer_6")}`}
            <h2 className="subtitle is-6">{`${t("info.faq.question_7")}`}</h2>{`${t("info.faq.answer_7")}`}
            <h2 className="subtitle is-6">{`${t("info.faq.question_8")}`}</h2>{`${t("info.faq.answer_8")}`}
            <h2 className="subtitle is-6">{`${t("info.faq.question_9")}`}</h2>{`${t("info.faq.answer_9")}`}
            <h2 className="subtitle is-6">{`${t("info.faq.question_10")}`}</h2>{`${t("info.faq.answer_10")}`}

        </div>
    );
}
