export const template = (countryOptions, organization, t) => {
  console.log('organization', organization);
  if (!countryOptions) {
    console.error('Missing required parameters');
    return [];
  }
  return [{
    name: t("manage.orgs.details"), fields: [
      { name: "name", label: t("common.name"), value: organization?.name || '', required: true },
      { name: "orgNumber", label: t("manage.orgs.org_no"), value: organization?.org_nr || '', type: 'number', disabled: organization },
    ]
  }, {
    name: t("manage.orgs.contact"), fields:
      [
        { name: 'email', label: t("common.email"), validator: 'email', value: organization?.email || '', required: true },
        { name: 'phone', label: t("common.phone_no"), validator: 'phone', value: organization?.phone || '', required: true },
        { name: 'website', label: t("common.website"), validator: 'website', value: organization?.data?.website || '' },
      ]
  },
  {
    name: t("common.address"), fields:
      [
        { name: 'address.street', label: t("common.street"), value: organization?.data?.address?.street },
        { name: 'address.city', label: t("common.city"), value: organization?.data?.address?.city },
        { name: 'address.postalcode', label: t("common.postal_code"), value: organization?.data?.address?.postalcode },
        { name: 'address.postalplace', label: t("common.postal_place"), value: organization?.data?.address?.postalplace },
        { name: 'address.country', label: t("common.country"), options: countryOptions, value: organization?.data?.address?.country }
      ]
  },
  {
    name: t("manage.orgs.holidays"), fields: [
      { name: 'data.holidays', label: t("manage.orgs.no_of_holidays"), type: 'number', value: organization?.data?.holidays || 25 },
    ]
  },
  {
    name: t("manage.orgs.hours"), fields: [
      { name: 'data.hours.commentRequired', label: t("manage.orgs.unbillable_warning"), type: 'checkbox', value: organization?.data?.hours?.commentRequired || false },
    ]
  }];
}

export const tableHeaders = [
  //TODO: Not needed yet
];

