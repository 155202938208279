import React from 'react';
import { useTranslation } from "react-i18next";
export const TermsOfService = () => {
    const { t } = useTranslation();

    return (
        <div className="content">
            <p>
                {`${t("info.terms.info_text")}`}
            </p>

            <h2 className="subtitle">{`${t("info.terms.header_1")}`}</h2>
            <p>{`${t("info.terms.text_1")}`}</p>

            <h2 className="subtitle">{`${t("info.terms.header_2")}`}</h2>
            <h2 className="subtitle is-4">{`${t("info.terms.header_21")}`}</h2><p>{`${t("info.terms.text_21")}`}</p>
            <h2 className="subtitle is-4">{`${t("info.terms.header_22")}`}</h2><p>{`${t("info.terms.text_22")}`}</p>
            <h2 className="subtitle is-4">{`${t("info.terms.header_23")}`}</h2><p>{`${t("info.terms.text_23")}`}</p>
            <h2 className="subtitle is-4">{`${t("info.terms.header_24")}`}</h2><p>{`${t("info.terms.text_24")}`}</p>
            <h2 className="subtitle">{`${t("info.terms.header_3")}`}</h2><p>{`${t("info.terms.text_3")}`}</p>
            <h2 className="subtitle">{`${t("info.terms.header_4")}`}</h2><p>{`${t("info.terms.text_4")}`}</p>
            <h2 className="subtitle">{`${t("info.terms.header_5")}`}</h2><p>{`${t("info.terms.text_5")}`}</p>
            <h2 className="subtitle">{`${t("info.terms.header_6")}`}</h2><p>{`${t("info.terms.text_6")}`}</p>
            <h2 className="subtitle">{`${t("info.terms.header_7")}`}</h2><p>{`${t("info.terms.text_7")}`}</p>
            <h2 className="subtitle">{`${t("info.terms.header_8")}`}</h2><p>{`${t("info.terms.text_8")}`}</p>
            <h2 className="subtitle">{`${t("info.terms.header_9")}`}</h2><p>{`${t("info.terms.text_9")}`}</p>
        </div>
    );
}
