import React from 'react';
import './switch.scss';
import { useTranslation } from "react-i18next";

export const Switch = ({ switchId, checked, onToggle, disabled, help, transitionend, ...props }) => {

  const { t } = useTranslation();
  const handleToggle = (event) => {
    onToggle(switchId, event);
  };

  return (
    <>
      <div className={`switch ${props.hidden ? 'is-hidden' : ''}`} transitionend={transitionend} >
        <input
          disabled={disabled}
          id={switchId}
          type="checkbox"
          checked={checked}
          onChange={handleToggle}
          {...props}
        />
        <label htmlFor={switchId}>{switchId}</label>

      </div>
      {help && <span className="ml-2">{checked ? t("common.yes") : t("common.no")}</span>}
    </>
  );
};
