import { useState, useEffect } from 'react';
import { useDebouncedCallback } from './useDebouncedCallback';

export const useColors = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [palette, setPalette] = useState([]);
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

  const toggleTheme = () => setTheme(oldTheme => {
    const newTheme = oldTheme === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', newTheme);
    return newTheme;
  });

  const onLoadedDebounced = useDebouncedCallback(() => {
    setIsLoaded(true);
  }, 300);

  useEffect(() => {

    if (isLoaded) {
      document.body.classList.add('fade-out');
      const id = setTimeout(() => window.location.reload(), 1000);
      return () => clearTimeout(id);
    }

    const onLoaded = module => {
      setPalette(module.default);
      onLoadedDebounced();
    }

    if (theme === 'dark') {
      // Dynamically import dark theme styles
      import('@styles/dark/theme.scss').then(onLoaded);
    } else {
      // Dynamically import light theme styles
      import('@styles/light/theme.scss').then(onLoaded);
    }
  }, [theme]);

  return { isLoaded, palette, theme, setTheme, toggleTheme };
}


