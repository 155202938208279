import React, { useState, useEffect } from 'react';
import { getWeekDates } from './aux';
import { useCalendar } from './provider';
import { Stack, H1, Columns, Column } from '@components';
import { CalendarBody } from './CalendarBody';
import { CalendarHeader } from './CalendarHeader';
import { useTranslation } from "react-i18next";

export const CalendarWeek = () => {
  const { t } = useTranslation();
  const { TimeentriesForm, Selection } = useCalendar();

  const [weekDates, setWeekDates] = useState([]);

  useEffect(() => {
    Selection.week.apply(Selection.selectedDate);
  }, []);

  useEffect(() => {
    setWeekDates(getWeekDates(Selection.from));
  }, [Selection.from]);

  if (TimeentriesForm.currentItem === null) return null;

  return (
    <Stack.Begin>
      <Stack.Bar>
        <Columns>
          <Column>
            <H1>{t('common.hour_registration')}</H1>
          </Column>
          <Column className="is-3">
          </Column>
        </Columns>
      </Stack.Bar>
      <Stack.Bar>
        <CalendarHeader />
      </Stack.Bar>
      <Stack.Content>
        <CalendarBody dates={weekDates} />
      </Stack.Content>
    </Stack.Begin>
  );
};
