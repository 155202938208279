import React from 'react';
import { useTranslation } from "react-i18next";
import { LeftSideMenu } from '@components';
import { PrivacyPolicy } from './PrivacyPolicy';
import { TermsOfService } from './TermsOfService';
import { About } from './About';
import { Contact } from './Contact';
import { FAQ } from './Faq';
import { Feedback } from './Feedback';

export const InfoMenu = () => {
    const { t } = useTranslation();

    const routesHelp = [
        { icon: "info", link: "about", label: `${t("info.about.about")}`, element: <About /> },
        { icon: "question", link: "faq", label: "FAQ", element: <FAQ /> },
        { icon: "map", link: "contact", label: `${t("info.about.contact")}`, element: <Contact /> },
        { icon: "comment", link: "feedback", label: `${t("common.feedback")}`, element: <Feedback /> },
        { icon: "handshake", newTitle: `${t("common.terms_and_policies")}`, link: "terms-of-service", label: `${t("common.terms-of-service")}`, element: <TermsOfService /> },
        { icon: "shield-halved", link: "privacy-policy", label: `${t("common.privacy-policy")}`, element: <PrivacyPolicy /> },
    ];

    return (
    <div className="section">
      <div className="container is-max-desktop mt-6">
        <div className="block">
          <div className='box'>
            <LeftSideMenu title={`${t("common.support")}`} routes={routesHelp} />
          </div>
        </div>
      </div>
    </div>
    );
};
